import {useField, useFormikContext} from "formik";
import './TextareaInput.scss';

export default function TextareaInput({label, className, ...props}) {

  const [field, meta] = useField(props);
  const {isSubmitting} = useFormikContext();

  let classNames = 'TextareaInput';
  if (className) {
    classNames += ` ${className}`;
  }
  if (meta.touched && meta.error) {
    classNames += ' has-error';
  }

  return (
    <div className={classNames}>
      <label htmlFor={props.id || props.name}>{label}</label>
      <textarea id={props.id || props.name} {...field} {...props} disabled={isSubmitting}/>
    </div>
  );

}
