import './TrackStation.scss';
import {NavLink} from "react-router-dom";

export default function TrackStation({link, ...props}) {

  return (
    <li className={
      (props.className || '').split(' ').concat(['TrackStation']).concat(props.active ? ['active'] : []).join(' ')
    }>
      {
        props.index &&
        props.lastIndex &&
        <NavLink className="link" to={link ? link : ""} title={props.children}>
          <div className={
            []
              .concat('current-index')
              .concat(props.index === 1 ? 'active-first' : '')
              .concat(props.index === props.lastIndex ? 'active-last' : '')
              .join(' ')
          }>{props.index}</div>
          <div className="last-index">{props.index + '/' + props.lastIndex}</div>
          <p>{props.children}</p>
        </NavLink>
      }
    </li>
  );

}
