import {useField, useFormikContext} from "formik";
import AsyncSelect from "react-select/async";
import {useEffect, useState} from "react";
import useCRUD from "./../hooks/useCRUD";

export default function SelectInput({
  label, id, name, options, getOptionLabel, entity, menuPosition = 'absolute', isClearable = true, isMulti = false,
  expand, ...props
}) {

  const crud = useCRUD();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [field] = useField(name);
  const {isSubmitting, setFieldValue} = useFormikContext();

  const loadOptions = inputValue => {
    let result;
    setIsLoading(true);
    if (entity) {
      result = new Promise((resolve, reject) => {
        crud.data.bulk.read({entity, page_size: 1000})
          .then(result => {
            if (expand && result.items.length > 0) {
              expand(result.items)
                .then(result => resolve(result))
                .catch(errors => reject(errors))
                .finally(() => setIsLoading(false));
            } else {
              setIsLoading(false);
              resolve(result.items);
            }
          })
          .catch(errors => {
            setIsLoading(false);
            reject(errors);
          });
      });
    } else {
      result = Promise.resolve(options.filter(option => option.value.toLowerCase().includes(inputValue.toLowerCase())));
      setIsLoading(false);
    }
    return result;
  };

  useEffect(() => {
    if (field.value && (!Array.isArray(field.value) || field.value.length > 0) && !selectedOption) {
      if (entity) {
        setIsLoading(true);
        const values = isMulti ? field.value : [field.value];
        crud.data.bulk.read({
          entity, page_size: values.length,
          filter: {group: 'or', components: values.map(value => ({property: 'id', operator: 'equals', value}))}
        })
          .then(result => {
            let sortedItems = [];
            for (let id of values) {
              let foundResult = result.items.find(obj => obj.id?.toString() === id?.toString());
              if (foundResult) {
                sortedItems.push(foundResult);
              }
            }
            if (expand && sortedItems.length > 0) {
              expand(isMulti ? sortedItems : sortedItems[0])
                .then(result => setSelectedOption(result))
                .finally(() => setIsLoading(false));
            } else {
              setSelectedOption(isMulti ? sortedItems : sortedItems[0]);
              setIsLoading(false);
            }
          })
          .catch(() => setIsLoading(false));
      } else {
        options.forEach(option => {
          if (option.value === field.value) {
            setSelectedOption(option);
          }
        });
      }
    }
  }, [crud.data, entity, expand, field.value, isMulti, options, selectedOption]);

  return (
    <div className="SelectInput">
      {label && <label htmlFor={id || name}>{label}</label>}
      <AsyncSelect
        value={selectedOption}
        loadOptions={loadOptions}
        defaultOptions
        isLoading={isLoading}
        inputId={id || name}
        isDisabled={isSubmitting}
        placeholder="Auswählen…"
        isMulti={isMulti}
        noOptionsMessage={() => "Nichts gefunden"}
        loadingMessage={() => "Laden…"}
        isClearable={isClearable}
        menuPosition={menuPosition}
        closeMenuOnScroll={menuPosition === 'fixed' ? e => e.target === document : null}
        getOptionLabel={getOptionLabel}
        getOptionValue={(option) => entity ? parseInt(option.id) : option.value}
        onChange={(option) => {
          if (option && entity && Array.isArray(option)) {
            setFieldValue(name, option.map(option => parseInt(option.id)));
          } else {
            setFieldValue(name, option ? (entity ? parseInt(option.id) : option.value) : null);
          }
          setSelectedOption(option);
        }}
        styles={{
          container: (provided) => ({...provided, marginTop: 5}),
          indicatorSeparator: () => ({display: 'none'}),
          clearIndicator: (provided) => ({...provided, paddingRight: 0}),
          loadingIndicator: (provided) => ({...provided, paddingRight: 0})
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: '#7dcbce',
            primary75: "#9ed8da",
            primary50: "#bee5e7",
            primary25: "#dff2f3",
          },
          spacing: {
            ...theme.spacing,
            baseUnit: 8,
            controlHeight: 57,
            menuGutter: 8
          }
        })}
        {...props}
      />
    </div>
  );

}
