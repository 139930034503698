import './Modal.scss';
import {useCallback, useEffect, useRef, useState} from "react";
import {XIcon} from "./Icons";

export default function Modal({title, dismissable = true, ...props}) {

  const modalEl = useRef();
  const [closed, setClosed] = useState(true);

  const dismiss = useCallback(() => {
    if (!closed) {
      if (props.onDismiss) {
        props.onDismiss();
      }
      setClosed(true);
    }
  }, [closed, props]);

  useEffect(() => {
    setClosed(!props.show);
    const dismissOnEsc = (event) => {
      if (event.key === 'Escape') {
        dismissable && dismiss();
      }
    };
    document.addEventListener('keydown', dismissOnEsc, false);
    return () => {
      document.removeEventListener('keydown', dismissOnEsc, false);
    };
  }, [dismissable, dismiss, props.show]);

  const handleClick = (event) => {
    const modalRect = modalEl.current.getBoundingClientRect();
    if (
      event.clientY < modalRect.top || event.clientY > modalRect.bottom ||
      event.clientX < modalRect.left || event.clientX > modalRect.right
    ) {
      dismissable && dismiss();
    }
  };

  let classes = ['Modal'];
  if (props.className) {
    classes.push(props.className);
  }

  return (
    !closed &&
    <div className="ModalWrapper">
      <div className={classes.join(' ')} ref={modalEl} onClick={handleClick}>
        {
          (dismissable || title) &&
          <div className="heading">
            {title && <div className="title">{title}</div>}
            {dismissable && <div className="closer" onClick={dismiss}>{<XIcon/>}</div>}
          </div>
        }
        <div className="body">{props.children}</div>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  show: true
};
