import PublicModal from "../../components/PublicModal";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import FormErrors from "../../components/FormErrors";
import PasswordInput from "../../components/PasswordInput";
import {useState} from "react";
import ButtonBar from "../../components/ButtonBar";
import {useNavigate} from "react-router-dom";
import useBL from "../../hooks/useBL";
import {LoaderIcon} from "../../components/Icons";

export default function Person({registrationData, setRegistrationData}) {

  const navigate = useNavigate();
  const bl = useBL();

  const [remoteErrors, setRemoteErrors] = useState([]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .required('Bitte geben Sie eine E-Mail-Adresse ein')
      .email('Bitte geben Sie eine gültige E-Mail-Adresse ein'),
    password: Yup.string()
      .required('Bitte geben Sie ein Passwort ein'),
    passwordRepetition: Yup.string()
      .required('Bitte wiederholen Sie Ihr Passwort')
      .oneOf([Yup.ref('password'), null], 'Passwörter müssen übereinstimmen')
  });

  const submit = (values, {setSubmitting}) => {
    Promise.allSettled([
      bl.ensure_password_complexity({password: values.password}),
      bl.ensure_available_email({email: values.email})
    ])
      .then(([passwordPromise, emailPromise]) => {
        setSubmitting(false);
        let remoteErrors = [];
        if (passwordPromise.status === 'rejected') {
          remoteErrors = remoteErrors.concat(passwordPromise.reason);
        }
        if (emailPromise.status === 'rejected') {
          remoteErrors = remoteErrors.concat(emailPromise.reason);
        }
        if (remoteErrors.length > 0) {
          setRemoteErrors(remoteErrors);
        } else {
          setRegistrationData({email: values.email, password: values.password});
          setSubmitting(false);
          navigate('/registrieren/unternehmen');
        }
      });
  };

  return (
    <PublicModal>
      <h1>Willkommen!</h1>
      <p className="info-text">Gib eine E-Mail-Adresse und ein Passwort ein, und dann kannst du starten.</p>
      <Formik
        initialValues={{
          email: registrationData.email,
          password: registrationData.password,
          passwordRepetition: registrationData.password
        }}
        validationSchema={validationSchema}
        onSubmit={submit} validate={() => setRemoteErrors([])}
      >
        {({isSubmitting, touched, errors}) => (
          <Form>
            <TextInput name="email" placeholder="E-Mail"/>
            <PasswordInput name="password" placeholder="Passwort"/>
            <PasswordInput name="passwordRepetition" placeholder="Passwort wiederholen"/>
            <ButtonBar>
              <Button
                color="gray" text="Weiter" type="submit" icon={isSubmitting ? <LoaderIcon/> : null}
                disabled={isSubmitting}
              />
            </ButtonBar>
            <FormErrors touched={touched} errors={errors} remoteErrors={remoteErrors}/>
          </Form>
        )}
      </Formik>
    </PublicModal>
  );

}
