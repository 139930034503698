import './DateDisplay.scss';

export default function DateDisplay({date, label, className}) {
  return (
    <div className={'DateDisplay' + (className ? (' ' + className) : '')}>
      <label>{label}</label>
      <div className="date-bar">
        <span>{String(date.getDate()).padStart(2, '0')}</span>
        <span>{String(date.getMonth() + 1).padStart(2, '0')}</span>
        <span>{date.getFullYear()}</span>
      </div>
    </div>
  );
}
