import TitleHeading from "../../components/TitleHeading";
import Title from "../../components/Title";
import React, {useState} from "react";
import Stage from "../../components/Stage";
import {NavLink, useNavigate} from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import {Form, Formik} from "formik";
import DropzoneInput from "../../components/DropzoneInput";
import ButtonBar from "../../components/ButtonBar";
import ButtonLink from "../../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import Button from "../../components/Button";
import FormErrors from "../../components/FormErrors";
import Modal from "../../components/Modal";
import ErrorModal from "../../components/ErrorModal";
import useCRUD from "../../hooks/useCRUD";
import {VideoUploader} from '@api.video/video-uploader';
import * as Yup from "yup";
import useBL from "../../hooks/useBL";
import Progress from "../../components/Progress";

export default function Hochladen() {

  const crud = useCRUD();
  const bl = useBL();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [remoteErrors, setRemoteErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const initialValues = {
    video: null
  };

  const validationSchema = Yup.object({
    video: Yup.mixed().required('Bitte ein Video auswählen')
  });

  const submit = (values, {setSubmitting}) => {
    bl.generate_video_upload_token()
      .then(token => {
        const videoUploader = new VideoUploader({
          uploadToken: token,
          file: values.video,
          videoName: window.location.hostname.split('.')[0] + ': ' + values.video.name
        });
        setProgress(0);
        videoUploader.onProgress(event => {
          setProgress(Math.floor((event.uploadedBytes / event.totalBytes) * 100));
        });
        videoUploader.upload()
          .then(video => {
            crud.data.create({
              entity: 'video',
              item: {
                name: values.video.name,
                api_video_id: video.videoId,
                type: 'video'
              }
            })
              .then(() => setShowSuccess(true))
              .catch(errors => setRemoteErrors(errors))
              .finally(() => setSubmitting(false));
          })
          .catch(error => setRemoteErrors([error]) || setSubmitting(false));
      })
      .catch(errors => setRemoteErrors(errors) || setSubmitting(false));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Video hochladen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/videos-und-memos/">Videos & Memos</NavLink>
          Hochladen
        </Breadcrumb>
      </Title>
      <Stage>
        <Formik
          initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}
          onReset={() => setShowSuccess(false)}
        >
          {({isSubmitting, touched, errors}) => (
            <Form>
              <DropzoneInput name="video" label="Video"/>
              <ButtonBar>
                <ButtonLink
                  to="/videos-und-memos" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                />
                <Button
                  icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                  text="Speichern" type="submit" disabled={isSubmitting}
                />
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal
                title="Das Video wurde erfolgreich hochgeladen" show={showSuccess}
                onDismiss={() => navigate('/videos-und-memos')}
              >
                <ButtonBar>
                  <Button type="reset" color="gray" text="Weiteres Video hochladen"/>
                  <ButtonLink to="/videos-und-memos" text="Zurück zur Übersicht"/>
                </ButtonBar>
              </Modal>
              {
                remoteErrors.length === 0 && isSubmitting && progress > 0 &&
                <Modal dismissable={false}>
                  <div className="text-center">
                    <Progress value={progress} className="orange"/>
                    <div className="mt-1">Dein Video wird hochgeladen</div>
                  </div>
                </Modal>
              }
            </Form>
          )}
        </Formik>
        <ErrorModal errors={remoteErrors} onDismiss={() => setRemoteErrors([])}/>
      </Stage>
    </main>
  );

}
