import React, {useEffect, useState} from "react";
import useBL from "../hooks/useBL";
import ErrorModal from "../components/ErrorModal";

export const TenantContext = React.createContext({tenant: 'root'});

export function TenantContextProvider({children}) {

  const [tenant, setTenant] = useState('root');
  const [errors, setErrors] = useState([]);
  const bl = useBL();

  useEffect(() => {
    bl.tenant_from_hostname({hostname: window.location.hostname})
      .then(tenant => setTenant(tenant))
      .catch(errors => setErrors(errors));
  }, [bl]);

  return (
    <TenantContext.Provider value={{tenant}}>
      {tenant !== 'root' && children}
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </TenantContext.Provider>
  );

}
