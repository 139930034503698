export {AudioOnly, UseWebcam};

function AudioOnly() {
  return (
    <svg width="272" height="175" xmlns="http://www.w3.org/2000/svg">
      <path fill="#7f63f4" d="M1256.713 458.263h272v175h-272z" transform="translate(-1256.731 -458.219)"/>
      <g transform="translate(113.144 35.331)">
        <path
          d="M22.179 73.099v-73.1a16.219 16.219 0 0 0-15.813 16.2h10.611a2.435 2.435 0 0 1 2.505 2.508 2.557 2.557
            0 0 1-2.505 2.508H6.366v3.667h10.611a2.393 2.393 0 0 1 2.505 2.314 2.557 2.557 0 0 1-2.505
            2.503H6.366v3.665h10.611a2.508 2.508 0 0 1 0 5.015H6.366v3.665h10.611a2.506 2.506 0 0 1 0
            5.013H6.366v3.665h10.611a2.434 2.434 0 0 1 2.505 2.508 2.532 2.532 0 0 1-2.505 2.316H6.366v1.353a16.222
            16.222 0 0 0 15.813 16.2"
          fill="#f6f6f6"/>
        <path
          d="M38.769 56.899v-1.354h-10.8a2.533 2.533 0 0 1-2.512-2.314 2.438 2.438 0 0 1 2.512-2.51h10.8v-3.662
            h-10.8a2.508 2.508 0 1 1 0-5.015h10.8v-3.663h-10.8a2.509 2.509 0 1 1 0-5.018h10.8v-3.664h-10.8a2.556
            2.556 0 0 1-2.512-2.5 2.4 2.4 0 0 1 2.512-2.316h10.8v-3.668h-10.8a2.561 2.561 0 0 1-2.512-2.51 2.438
            2.438 0 0 1 2.51-2.506h10.8a16.483 16.483 0 0 0-15.818-16.2v73.1a16.483 16.483 0 0 0 15.82-16.2"
          fill="#f6f6f6"/>
        <path
          d="M44.935 45.323v11.379c0 1.542 0 4.05-.194 5.787 0 0-.191 1.157-.576 2.7a24.9 24.9 0 0 1-2.118 5.209
            54.027 54.027 0 0 1-5.4 6.168 64.464 64.464 0 0 1-7.329 3.665 17.667 17.667 0 0 1-3.662.385v17.359
            h6.366a1.66 1.66 0 0 1 1.542 1.544v3.466a1.487 1.487 0 0 1-1.542 1.348h-19.1a1.315 1.315 0 0
            1-1.347-1.348v-3.466a1.491 1.491 0 0 1 1.348-1.544h6.366V80.808a1.158 1.158 0 0 0-.772-.194
            13.352 13.352 0 0 1-2.895-.385c-1.351-.579-3.662-1.542-5.011-2.312-1.355-.579-5.209-4.246-6.175-5.4A43.59
            43.59 0 0 1 .77 65.185 63.213 63.213 0 0 1 0 56.702V45.323a19.013 19.013 0 0 1 .961-5.594L2.7
            34.716c.572-1.544.956-1.544.956.194v21.792c0 1.542.2 3.856.2 5.207a37.455 37.455 0 0 0 2.312 6.557 27.271
            27.271 0 0 0 4.439 5.013 34.8 34.8 0 0 0 5.972 3.086 40.011 40.011 0 0 0 5.018.774h1.736c.191 0 1.346-.194
            2.7-.385a37.386 37.386 0 0 0 6.55-2.123c.97-.579 4.054-3.665 5.018-4.434a36.518 36.518 0 0 0
            3.099-6.174c.194-1.155.579-5.979.579-7.521V34.91c0-1.738.387-1.738.963-.194l1.733 5.013a18.942 18.942
            0 0 1 .963 5.594"
          fill="#7dcbce"/>
      </g>
      <circle cx="149" cy="37" r="10" fill="#FF4136" opacity="1" display="none">
        <animate attributeName="r" begin="0s" dur="3s" repeatCount="indefinite" values="10;12;8;10"/>
        <animate attributeName="opacity" begin="0s" dur="3s" repeatCount="indefinite" values="1;.75;1.75;1"/>
      </circle>
    </svg>
  );
}

function UseWebcam() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="272" height="175" viewBox="0 0 272.2 175.8">
      <path fill="#7dcbce" d="M0 0h272.2v175.8H0z"/>
      <path
        fill="#fdd152"
        d="m49.6 158.7 14-15.5c4.2-4.6 8.2-9.5 13.8-12.4 2.7-1.4 11.1-6.3 13.6-2.8 12.1 33.8-20.5
          47.8-20.5 47.8H35.2s8-10.2 13.9-16.5l.5-.6"
      />
      <path fill="#ffe388" d="m37 170-2.5 5.8h8.3z"/>
      <path
        fill="#fdd152" d="M198.3 156.6c-8.6-12.7-19.3-23.8-34.4-27.2-35.4-2.6 18.3 46.4 18.3 46.4h19.6s3.2-9.4-3.5-19.2"
      />
      <path fill="#ffe388" d="M201.8 174.6v1.2h-1.6z"/>
      <path
        fill="#ffdd60"
        d="m70.6 175.8 1.6-23.5c.6-8.2 2.3-18.3 9.8-23a77.8 77.8 0 0 1 40.3-11.3c14.8-.1 32 3.5 44.9 11.3 12 11.7
          9.8 30.7 10.4 46.4"
      />
      <path
        fill="#fdd152"
        d="M122.3 118a77.8 77.8 0 0 0-23.7 3.9l.1 1.8c1 9.4 5.4 17 14.7 20.1a35 35 0 0 0 29.2-3.3c5.3-3.4 7.9-9.3
          8.3-15.5l.2-2.8a96.6 96.6 0 0 0-28.8-4.2z"
      />
      <path
        fill="#fff"
        d="m71.7 159.3-.4 5.5c36.5 5.3 72.1 6.5 105.9.6l-.1-5.5c-35 5.6-70.5 5.4-105.4-.6zm-.7 11.3-.4
          5.2h107.2l-.3-4.3a320.8 320.8 0 0 1-106.5-1z"
      />
      <path
        fill="#f8a17a"
        d="M138.2 135.4c-13.4 8.8-39.5 2-32-15.6a51 51 0 0 0 2.5-6.4c1-3.7.9-7.5.8-11.2L109 89a59.2 59.2 0 0 1
          14-1.2c4.4.1 8.7-.3 13 0a1.7 1.7 0 0 1 1 .2 1.1 1.1 0 0 1 .3.8c.5 6 0 12 .4 17.8a30 30 0 0 0 2.2 10.2c2.4 4.7
          5.8 9.1 2.5 14.3a14.7 14.7 0 0 1-4.3 4.2"
      />
      <path
        fill="#e5785c"
        d="M108.9 112.6c.8-3.4.6-7 .5-10.5L109 89a59.2 59.2 0 0 1 14-1.2c4.4.2 8.7-.2 13 0a1.7 1.7 0 0 1 1 .3 1.1 1.1 0
          0 1 .2.8c.6 5.9 0 11.9.5 17.8a38.7 38.7 0 0 0 .7 5 32.2 32.2 0 0 1-2 1.5 24.4 24.4 0 0 1-18.9 3 29.3 29.3 0 0
          1-8.6-3.6"
      />
      <path
        fill="#e5785c"
        d="M154.2 71.8c6.8-3.8 9 5.7 8 10.5a15.2 15.2 0 0 1-2.5 5.5c-1.6 2.2-6.3 5.9-9
          3.5-1.3-1-1.4-2.9-1.5-4.5-.1-4.4.3-12.3 5-15"
      />
      <path
        fill="#e5785c"
        d="M96.3 54.7c7.2-19 34-23.4 50.1-9.6 3.8 3.3 7.8 8.4 9.5 13 1.9 5 .8 9 .2 14.2-.4-4.3-1.8-6.7-4.7-10a38.4 38.4
          0 0 0-4.7-4.4c-.6-.4-5.1-4.6-4.7-5.1-1.6 2-3.2 4.2-5.7 5.2a53.3 53.3 0 0 1-9.6 2.8 26.1 26.1 0 0 0-14.6 7.3
          39.5 39.5 0 0 0-8.6 14.3c-.6 1.6-1.6 4.2-3.8 3.4a40.5 40.5 0 0 1-5-3.7 58 58 0 0 1-1.1-12 46 46 0 0
          1 2.7-15.4"
      />
      <path
        fill="#5e4130"
        d="m88.8 71-.4-.7c-4.5-6.6-12.1-17.7-7.9-25.9 1.5-2.8 4.1-4.5 6.6-6.2a19.9 19.9 0 0 0 5.3-4.5 20.6 20.6 0 0 0
          2.4-5.1 35.8 35.8 0 0 1 1.5-3.7c3.6-7.2 11.4-10.7 20-8.9a45.7 45.7 0 0 1 8.2 3c4.9 2 9.8 4 14.5 2.8a20.6 20.6
          0 0 0 4.8-2.3 23.5 23.5 0 0 1 5.2-2.4 15.8 15.8 0 0 1 12.3 1.6 18.8 18.8 0 0 1 9 14 22.5 22.5 0 0 1-2.8 14l-1
          1.4c-1.2 2-2.4 4-2.4 6H162c0-2.7 1.4-5 2.8-7.2l.9-1.3a20.4 20.4 0 0 0 2.5-12.7 16.6 16.6 0 0 0-8-12.4 13.7
          13.7 0 0 0-10.6-1.4 21.6 21.6 0 0 0-4.8 2.2 22.4 22.4 0 0 1-5.2 2.5c-5.4 1.5-10.7-.8-15.9-3a43.6 43.6 0 0
          0-7.8-2.7c-7.6-1.6-14.6 1.4-17.7 7.7a33.6 33.6 0 0 0-1.4 3.5 22.5 22.5 0 0 1-2.7 5.6 21.8 21.8 0 0 1-5.8 5
          16.2 16.2 0 0 0-5.9 5.5c-3.7 7 3.7 17.9 7.7 23.7l.5.7z"
      />
      <path
        fill="#5e4130"
        d="M105.1 93.6c4.1 4.9 9.8 8.1 15.4 11a18.5 18.5 0 0 0 4.3 1.9c4.7 1 9.4-1.5 13.3-4.3a76.8 76.8 0 0 0
        14.5-13.5c4.2-5.1 6.6-10.8 9.3-16.8a35.9 35.9 0 0 0 2.8-18.9c-.6-6.8 3.3-13 1.5-19.9a19.8 19.8 0 0 0-7.4-11
        14.3 14.3 0 0 0-6.8-2.8c-5.5-.6-10.2 3.1-15.6 3.4-5.5.2-10.8-1.8-16-3-4.7-1-9.7-1.6-14.3 0-5.1 1.7-7.6 5.6-10.4
        10.1-2.4 3.9-6 6.8-9 10.2A18.9 18.9 0 0 0 83 56.4a50.9 50.9 0 0 0 5.6 14.7c2.2 4 4.7 8 7.3 11.7a65.8 65.8 0 0 0
        6.5 7.4c1 1 1.9 2.2 2.8 3.4"
      />
      <path
        fill="#f8a17a"
        d="M100.2 70.1a51.3 51.3 0 0 1 2.4-15.3c6.4-19 30.2-23.5 44.4-9.7 15.8 15.5 9.3 51.9-7.6 64.5a18.9 18.9 0 0
          1-16.1 3c-16.5-4.5-23.4-25-23.1-42.5"
      />
      <path
        fill="#5e4130"
        d="M109.1 66.3A36.5 36.5 0 0 0 106 74c-1.4 4.2-2.5 8.6-4.2 12.6-6-3.6-10-12.2-12.8-18.4-2.5-5.6-3.2-10-1-15.7a26
          26 0 0 1 6.9-10c8.8-8.2 22-12.4 34.7-11.6 4.4.3 8 1.9 12.3 3.1 3.8 1.1 6.8 1.9 9.6 5a29.4 29.4 0 0 1 5.4 10
          43.8 43.8 0 0 1 2.8 17.9c-.3 4-.1 8.9-4 11a54 54 0 0 0 .4-6.7 34.8 34.8 0 0 0-.6-4.7 17 17 0 0 0-.8-3 23 23 0
          0 0-4-7.5c-2.6-3-6-5.9-6.7-9.8a56.8 56.8 0 0 1-21.4 12.4c-5.1 1.7-10.3 2.8-13.3 7.6v.1"
      />
      <path
        fill="#f8a17a"
        d="M99.3 73.3c-7.3-4-9.7 6-8.5 11.1a16 16 0 0 0 2.6 5.9c1.7 2.3 6.7 6.2 9.6 3.7 1.3-1.1 1.4-3
          1.5-4.8.1-4.7-.3-13.1-5.2-15.9"
      />
      <path
        fill="#8e6751"
        d="M144.6 31c-6 .3-12-1-17.9-2.2a60.3 60.3 0 0 0-9-.9H113a28.6 28.6 0 0 0-4.6.5 12.8 12.8 0 0 0-7.8 4.8 8.7 8.7
        0 0 0-1.3 4.5 31.2 31.2 0 0 1-.4 4.5A9.3 9.3 0 0 1 97 46a25 25 0 0 0-2.6 3.8 11.5 11.5 0 0 0-1 4.4 31.5 31.5 0 0
        0 .2 4.6 39 39 0 0 1 0-4.5A11.1 11.1 0 0 1 95 50a25.2 25.2 0 0 1 2.6-3.6 10 10 0 0 0 2.2-4 25.8 25.8 0 0 0
        .5-4.6 7.8 7.8 0 0 1 1.2-4 8.3 8.3 0 0 1 1.4-1.6 12.3 12.3 0 0 1 1.8-1.3 13 13 0 0 1 4-1.5 27.7 27.7 0 0 1
        4.5-.5h4.4a71.5 71.5 0 0 1 9 .7l9 1.4a40 40 0 0 0 9 0"
      />
      <path
        fill="#f8a17a"
        d="m201.2 59.6-.4.1c-1.2.4-1.8 4.6-1.8 5.6 0 2.4.3 5 .8 9.3.6 4.9 1.2 9.8 1.4
          14.8l-5.1-20c-.3-1.6-1-3-2-4.2-.7-1-2.1-.8-2.6.3-1 1.5-.9 3.5-.7 5.3.8 8.2 2 16.3 3.8 24.4.6 3.2 1.8 7.2 1.3
          10.4-1.7-.3-3-3.5-4-4.9a49.5 49.5 0 0 0-5.2-6c-1-1-3-2.5-4.5-2.4-4.3.4.6 8 1.4 9.2 2.9 4.2 6 8.3 8.7
          12.7a31 31 0 0 0 9.3 10.4c-.5 17.3 0 33.2.2 51.2h27.5a488.7 488.7 0 0 0-11-55.5c1.9-3.7 3.5-7.5 4.6-11.4
          1.4-5.5 1.2-11.1 1.7-16.8.5-6 1.6-12.5.7-18.4l-.2-.7-.5-1a2 2 0 0 0-.8-.7c-2-.8-3.3 6.3-3.5 7.6l-2.4
          10.6c0-4.5-.1-9-.5-13.5-.2-2-.2-4.1-.3-6.2 0-2 0-4-1-5.6-.4-.5-.7-1-1.2-1-.3 0-.6 0-.9.2-2.4 1-2.2 4.9-2.6
          7a57 57 0 0 0-1 8.3 83 83 0 0 1-1.1 11.3c-1-3.9-1.6-7.8-2.1-11.7-.9-5.2-.9-6.6-1.6-10-.9-4-2-8.8-4.4-8.7z"
      />
      <path fill="#fdb51f" d="M176.2 146.3s1.6 27.3 1.6 29.4h4.5s-1-21.7-6.1-29.4z"/>
    </svg>
  );
}
