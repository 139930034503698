import Title from '../components/Title';
import TitleHeading from '../components/TitleHeading';
import Stage from "../components/Stage";
import React, {useContext, useLayoutEffect, useState} from "react";
import StageTitle from "../components/StageTitle";
import ButtonBar from "../components/ButtonBar";
import ButtonLink from "../components/ButtonLink";
import {RecordingContext} from "../contexts/RecordingContext";
import VideoTiles from "../components/VideoTiles";
import TextInput from "../components/TextInput";
import {Form, Formik} from "formik";
import RadioButton from "../components/RadioButton";

export default function VideosAndMemos() {

  const recordingContext = useContext(RecordingContext);

  const [itemsInRow, setItemsInRow] = useState(1);
  const [filter, setFilter] = useState([]);
  const [searchType, setSearchType] = useState('');
  const [searchBar, setSearchBar] = useState('');

  useLayoutEffect(() => {
    const calcRows = () => {
      if (window.screen.width >= 1640) {
        setItemsInRow(4);
      } else if (window.screen.width >= 1300) {
        setItemsInRow(3);
      } else {
        setItemsInRow(2);
      }
    };

    calcRows();
    window.addEventListener('resize', calcRows);
    return () => {
      window.removeEventListener('resize', calcRows);
    };
  }, []);

  let timeoutId;
  const setSearchFilter = values => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      let newFilter = [];
      if (values.searchType) {
        newFilter.push({property: "type", operator: "equals", value: values.searchType});
      }
      if (values.searchBar) {
        newFilter.push({property: "name", operator: "contains", value: values.searchBar});
      }

      if (
        (searchBar !== values.searchBar)
        || (searchType !== values.searchType)
      ) {
        setFilter(newFilter);
        setSearchType(values.searchType);
        setSearchBar(values.searchBar);
      }
    }, 250);
  };

  return (
    <main>
      <Title>
        <TitleHeading>Videos &amp; Memos</TitleHeading>
      </Title>
      <Stage>
        <Formik initialValues={{searchBar: '', searchType: ''}} validateOnChange validate={setSearchFilter}>
          {({values}) => (
            <Form>
              <StageTitle>Neu erstellen</StageTitle>
              <ButtonBar align="left">
                <ButtonLink to="/video/hochladen" text="Video hochladen"/>
                <ButtonLink
                  to="/video/aufnehmen/konfiguration/video" text="Video aufnehmen"
                  onClick={() => recordingContext.setIntention('general')}
                />
                <ButtonLink
                  to="/video/aufnehmen/konfiguration/audio" text="Memo aufnehmen"
                  onClick={() => recordingContext.setIntention('general')}
                />
                <ButtonLink
                  to="/video/aufnehmen/konfiguration/screencast" text="Bildschirm aufnehmen"
                  onClick={() => recordingContext.setIntention('general')}
                />
              </ButtonBar>
              <hr/>
              <ButtonBar align="left" className="mb-1">
                <RadioButton name="searchType" value="" text="Alle Medien"/>
                <RadioButton name="searchType" value="video" text="Videos"/>
                <RadioButton name="searchType" value="memo" text="Memos"/>
                <RadioButton name="searchType" value="screencast" text="Bildschirmaufnahmen"/>
              </ButtonBar>
              <TextInput name="searchBar" placeholder="Suche..."/>
              {
                ((values.searchType === '') || (values.searchType === 'video')) &&
                <>
                  <hr/>
                  <VideoTiles editAble title="Videos" tilesPerRow={itemsInRow} filter={filter}
                    isLoading={searchType !== values.searchType}/>
                </>
              }
              {
                ((values.searchType === '') || (values.searchType === 'memo')) &&
                <>
                  <hr/>
                  <VideoTiles editAble recordType="memo" title="Memos" tilesPerRow={itemsInRow} filter={filter}
                    isLoading={searchType !== values.searchType}/>
                </>
              }
              {
                ((values.searchType === '') || (values.searchType === 'screencast')) &&
                <>
                  <hr/>
                  <VideoTiles
                    editAble recordType="screencast" title="Bildschirmaufnahmen" tilesPerRow={itemsInRow}
                    filter={filter} isLoading={searchType !== values.searchType}
                  />
                </>
              }
            </Form>
          )}
        </Formik>
      </Stage>
    </main>
  );

}
