import useCRUD from "../../hooks/useCRUD";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import Title from "../../components/Title";
import TitleHeading from "../../components/TitleHeading";
import Breadcrumb from "../../components/Breadcrumb";
import Stage from "../../components/Stage";
import {Form, Formik} from "formik";
import TextInput from "../../components/TextInput";
import TextareaInput from "../../components/TextareaInput";
import ButtonBar from "../../components/ButtonBar";
import Button from "../../components/Button";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import FormErrors from "../../components/FormErrors";
import Modal from "../../components/Modal";
import ErrorModal from "../../components/ErrorModal";
import IconWithText from "../../components/IconWithText";

export default function Edit() {

  const crud = useCRUD();
  const navigate = useNavigate();
  const params = useParams();

  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);

  useEffect(() => {
    setLoading(true);
    crud.data.read({entity: 'letter', id: parseInt(params.id)})
      .then(result => setItem({...result}))
      .finally(() => setLoading(false))
      .catch(errors => setErrors(errors));
  }, [crud.data, params.id]);

  const validationSchema = Yup.object({
    title: Yup.string().required('Bitte geben Sie einen Titel ein'),
    content: Yup.string().required('Bitte füllen Sie das Anschreiben aus')
  });

  function onSubmit(values, {setSubmitting}) {
    crud.data.update({entity: 'letter', id: params.id, update: {...values}})
      .then(() => setShowSuccess(true))
      .finally(() => setSubmitting(false))
      .catch(errors => setErrors(errors));
  }

  return (
    <main>
      <Title>
        <TitleHeading>Anschreiben bearbeiten</TitleHeading>
        <Breadcrumb>
          <NavLink to="/anschreiben">Anschreiben</NavLink>
          Neu
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
        <Formik
          initialValues={{
            title: item.title,
            content: item.content
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          onReset={() => setShowSuccess(false)}
        >
          {({isSubmitting, touched, errors}) => (
            <Form className="mt-1-75">
              <TextInput name="title" label="Titel des Anschreibens*" placeholder="Bsp. Formales Anschreiben"/>
              <TextareaInput className="mt-1-75" name="content" label="Anschreiben*" placeholder={
                "Bsp. Vielen Dank, dass ich mich Ihrem Unternehmen vorstellen durfte. " +
                "Folgend möchte ich Ihnen unser Unternehmen kurz vorstellen und unser Angebot erläutern."
              }
              />
              <ButtonBar>
                <Button color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                  onClick={() => navigate(-1)}/>
                <Button icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                  text="Speichern" type="submit" disabled={isSubmitting}/>
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal
                title="Das Anschreiben wurde erfolgreich gespeichert" show={showSuccess}
                onDismiss={() => navigate('/anschreiben')}
              >
                <ButtonBar>
                  <Button text="Ok" onClick={() => navigate(-1)}/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
