import './StandaloneModal.scss';
import {XIcon} from "./Icons";
import {useNavigate} from "react-router-dom";
import {useCallback, useEffect} from "react";

export default function StandaloneModal({maxWidth, closer = true, onDismiss, ...props}) {

  const navigate = useNavigate();
  const doNavigate = useCallback(() => onDismiss ? onDismiss() : navigate(-1), [navigate, onDismiss]);

  let style = {};
  if (maxWidth) {
    style.maxWidth = maxWidth;
  }

  useEffect(() => {
    const onEscape = (event) => {
      if (event.key === "Escape") {
        doNavigate();
      }
    };
    window.addEventListener("keyup", onEscape);
    return () => {
      window.removeEventListener("keyup", onEscape);
    };
  }, [doNavigate]);

  return (
    <div className="StandaloneModal">
      <div className="modal" style={Object.keys(style).length > 0 ? style : null}>
        {closer && <div className="closer" onClick={doNavigate}><XIcon/></div>}
        {props.children}
      </div>
    </div>
  );

}
