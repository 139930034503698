import './RowActionButton.scss';
import {useContext} from "react";
import {RowContext} from "../contexts/RowContext";
import {NavLink} from "react-router-dom";

export default function RowActionLink(props) {

  const row = useContext(RowContext);

  let to = props.to(row);

  return (
    <>
      {
        !to.match(/^http/) &&
        <NavLink to={to} className={'RowActionButton ' + props.color} title={props.title}>
          {props.children}
        </NavLink>
      }
      {
        to.match(/^http/) &&
        <a href={to} className={'RowActionButton ' + props.color} title={props.title} target="_blank" rel="noreferrer">
          {props.children}
        </a>
      }
    </>
  );
}

RowActionLink.defaultProps = {
  color: 'turquoise'
};
