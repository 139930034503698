import './Accordion.scss';
import Switch from "../components/Switch";
import {useEffect, useState} from "react";
import {useRef} from "react";
import {ChevronDownIcon, ChevronUpIcon} from "./Icons";
import {useContext} from "react";
import {AccordionGroupContext} from "../contexts/AccordionGroupContext";
import React from "react";

export default function Accordion({title, className, onToggle, reload, ...props}) {

  const bodyWrapper = useRef();

  const accordionGroupContext = useContext(AccordionGroupContext);

  const [active, setActive] = useState(props.active);
  useEffect(() => setActive(props.active), [props.active]);
  useEffect(() => {
    const changeHeight = () =>
      bodyWrapper.current.style.maxHeight = active ? bodyWrapper.current.children[0].offsetHeight + 'px' : 0;
    changeHeight();
    window.addEventListener('resize', changeHeight);
    return () => {
      window.removeEventListener('resize', changeHeight);
    };

  }, [active, reload]);

  const toggle = () => {
    if (accordionGroupContext) {
      accordionGroupContext.setActiveIndex(accordionGroupContext.activeIndex === props.index ? null : props.index);
    } else {
      setActive(!active);
    }
    onToggle && onToggle();
  };

  return (
    <div className={('Accordion ' + className).trim()}>
      <div className="title" onClick={toggle}>
        {title && <span>{title}</span>}
        {props.buttons && props.buttons.map((button, index) => <React.Fragment key={index}>{button}</React.Fragment>)}
        {props.indicator === 'switch' && <Switch active={active}/>}
        {props.indicator === 'chevron' && (active ? <ChevronUpIcon/> : <ChevronDownIcon/>)}
      </div>
      <div ref={bodyWrapper} className="body-wrapper">
        <div className="body">{props.children}</div>
      </div>
    </div>
  );

}

Accordion.defaultProps = {
  indicator: 'switch'
};
