import './setup.scss';
import {useNavigate, useParams} from "react-router-dom";
import {UseWebcam, AudioOnly} from "../../../images/CreateVideoGraphics";
import {useContext, useEffect} from "react";
import {RecordingContext} from "../../../contexts/RecordingContext";

export default function Setup(props) {

  const navigate = useNavigate();
  const params = useParams();
  const recordingContext = useContext(RecordingContext);

  useEffect(() => {
    if (params.preset === 'video') {
      props.setSettings({video: {width: 1280, height: 720}, audio: true, screencast: false});
      recordingContext.setType('video');
      navigate('../neu', {replace: true});
    }
    if (params.preset === 'audio') {
      props.setSettings({video: false, audio: true, screencast: false});
      recordingContext.setType('memo');
      navigate('../neu', {replace: true});
    }
    if (params.preset === 'screencast') {
      props.setSettings({video: false, audio: true, screencast: true});
      recordingContext.setType('screencast');
      navigate('../neu', {replace: true});
    }
  }, [params, navigate, props, recordingContext]);

  return (
    <div className="videoSetup">
      <h2>Video erstellen</h2>
      <div>
        <div onClick={() => {
          props.setSettings({
            video: true,
            audio: true,
            screencast: false
          });
          recordingContext.setType('video');
          navigate('../neu');
        }}
        >
          <UseWebcam />
          <span>Webcam</span>
        </div>
        <div onClick={() => {
          props.setSettings({
            video: false,
            audio: true,
            screencast: false
          });
          recordingContext.setType('memo');
          navigate('../neu');
        }}
        >
          <AudioOnly />
          <span>Nur Audio</span>
        </div>
      </div>
    </div>
  );

}
