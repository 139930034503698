import TitleHeading from "../../components/TitleHeading";
import Breadcrumb from "../../components/Breadcrumb";
import {NavLink, useNavigate} from "react-router-dom";
import Title from "../../components/Title";
import ButtonBar from "../../components/ButtonBar";
import ButtonLink from "../../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import Stage from "../../components/Stage";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {useState} from "react";
import ErrorModal from "../../components/ErrorModal";
import Button from "../../components/Button";
import FormErrors from "../../components/FormErrors";
import Modal from "../../components/Modal";
import useCRUD from "../../hooks/useCRUD";
import TextInput from "../../components/TextInput";
import Row from "../../components/Row";
import useTemplates from "../../hooks/useTemplates";

export default function Neu() {

  const crud = useCRUD();
  const templates = useTemplates();
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const validationSchema = Yup.object({name: Yup.string().required('Bitte einen Namen eingeben')});

  const initialValues = {name : '', content: {}};
  for (const text of templates[0].texts) {
    initialValues.content[text] = '';
  }

  const submit = (values, {setSubmitting}) => {
    crud.data.create({entity: 'translation', item: {name: values.name, content: JSON.stringify(values.content)}})
      .then(() => setShowSuccess(true))
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Texte erstellen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/texte">Texte</NavLink>
          Neu
        </Breadcrumb>
      </Title>
      <Stage>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema} onSubmit={submit}
          onReset={() => setShowSuccess(false)}
        >
          {({isSubmitting, touched, errors, initialValues}) => (
            <Form>
              <div className="translationDetails">
                <Row>
                  <TextInput name='name' label="Name für abweichende Angebotstexte"/>
                </Row>
                {
                  Object.entries(initialValues.content).map(([key, value], index) => (
                    <Row key={index} style={{alignItems: 'center', rowGap: '5px'}}>
                      <label htmlFor={'content.[\'' + key + '\']'}>{key}</label>
                      <TextInput name={'content.[\'' + key + '\']'}/>
                    </Row>
                  ))
                }
              </div>
              <FormErrors touched={touched} errors={errors}/>
              <ButtonBar>
                <ButtonLink
                  to="/texte" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                />
                <Button
                  icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                  text="Speichern" type="submit" disabled={isSubmitting}
                />
              </ButtonBar>
              <Modal
                title="Die Texte wurde erfolgreich erstellt" show={showSuccess}
                onDismiss={() => navigate('/texte')}
              >
                <ButtonBar>
                  <ButtonLink to="/texte" text="Zurück zur Übersicht"/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
