import {useField, useFormikContext} from "formik";
import './TextInput.scss';
import Tooltip from "./Tooltip";

export default function TextInput({label, icon, iconClick, labelTooltip, className, ...props}) {

  const [field, meta] = useField(props);
  const {isSubmitting} = useFormikContext();

  let classNames = ['TextInput'];
  classNames.push(className);
  if (meta.touched && meta.error) {
    classNames.push('has-error');
  }

  return (
    <div className={classNames.join(' ')}>
      {label && <label htmlFor={props.id || props.name}>{label}</label>}
      {labelTooltip && <Tooltip>{labelTooltip}</Tooltip>}
      <div className="input-wrapper">
        <input
          className={icon ? 'has-icon' : null} id={props.id || props.name} {...field} {...props}
          disabled={isSubmitting}
        />
        {icon && <span onClick={iconClick ?? null}>{icon}</span>}
      </div>
    </div>
  );

}
