import TitleHeading from "../../components/TitleHeading";
import Breadcrumb from "../../components/Breadcrumb";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import Title from "../../components/Title";
import Stage from "../../components/Stage";
import ButtonLink from "../../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import Button from "../../components/Button";
import ButtonBar from "../../components/ButtonBar";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../../components/TextInput";
import FormErrors from "../../components/FormErrors";
import ErrorModal from "../../components/ErrorModal";
import {useState} from "react";
import Row from "../../components/Row";
import Modal from "../../components/Modal";
import {useEffect} from "react";
import IconWithText from "../../components/IconWithText";
import useCRUD from "../../hooks/useCRUD";

export default function Bearbeiten() {

  const crud = useCRUD();
  const params = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    setLoading(true);
    crud.data.read({entity: 'picture_gallery', id: parseInt(params.id)})
      .then(result => setItem(result))
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud.data, params.id]);

  const validationSchema = Yup.object({
    headline: Yup.string().required('Bitte geben Sie eine Überschrift ein'),
  });

  const submit = (values, {setSubmitting}) => {
    crud.data.update({entity: 'picture_gallery', id: parseInt(params.id), update: {...values}})
      .then(() => setShowSuccess(true))
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Galerie bearbeiten</TitleHeading>
        <Breadcrumb>
          <NavLink to="/galerien">Galerien</NavLink>
          {item && <NavLink to={`/galerie/${item.id}/details`}>{item.headline}</NavLink>}
          Bearbeiten
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
          <Formik initialValues={{headline: item.headline || ''}} validationSchema={validationSchema} onSubmit={submit}>
            {({isSubmitting, touched, errors}) => (
              <Form>
                <Row>
                  <TextInput name="headline" label="Überschrift*" placeholder="Bsp. Meine Galerie"/>
                </Row>
                <ButtonBar>
                  <ButtonLink
                    to={`/galerie/${item.id}/details`} color="gray" icon={<ChevronLeftIcon/>} iconPosition="left"
                    text="Zurück"
                  />
                  <Button
                    icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                    text="Speichern" type="submit" disabled={isSubmitting}
                  />
                </ButtonBar>
                <FormErrors touched={touched} errors={errors}/>
                <Modal
                  title="Die Galerie wurde erfolgreich gespeichert" show={showSuccess}
                  onDismiss={() => navigate(-1, {replace: true})}
                >
                  <ButtonBar>
                    <Button type="button" text="Ok" onClick={() => navigate(-1, {replace: true})}/>
                  </ButtonBar>
                </Modal>
              </Form>
            )}
          </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
