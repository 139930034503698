import LightBulbIcon from "../images/LightBulbIcon";
import ButtonLink from "../components/ButtonLink";
import StandaloneModal from "../components/StandaloneModal";
import ButtonBar from "../components/ButtonBar";
import {useContext} from "react";
import {OfferContext} from "../contexts/OfferContext";

export default function FirstOffer() {

  const offerContext = useContext(OfferContext);

  return (
    <StandaloneModal>
      <LightBulbIcon/>
      <h1>Erstes Angebot</h1>
      <p>Hey, es sieht so aus, als wolltest du dein erstes Angebot erstellen.</p>
      <p>SUPER!</p>
      <ButtonBar align="center">
        <ButtonLink to="/angebot/neu" text="Los geht's" onClick={offerContext.resetOffer}/>
      </ButtonBar>
    </StandaloneModal>
  );

}
