import './Widget.scss';
import {NavLink} from "react-router-dom";

export default function Widget({className, linkTo, onClick, ...props}) {

  const classes = 'Widget' + (className ? ` ${className}` : '');

  return (
    <>
      {linkTo && <NavLink className={classes} to={linkTo} onClick={onClick}>{props.children}</NavLink>}
      {!linkTo && <div className={classes} onClick={onClick}>{props.children}</div>}
    </>
  );

}
