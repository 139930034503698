import './OfferFileOptionSelect.scss';
import {FileIcon, XIcon} from "./Icons";
import Accordion from "./Accordion";
import TextInput from "./TextInput";
import {Formik, useField} from "formik";
import {useContext, useEffect, useState} from "react";
import RadioButton from "./RadioButton";
import Tooltip from "./Tooltip";
import {OfferContext} from "../contexts/OfferContext";

export default function OfferFileOptionSelect({offerFile, onRemove, name, label}) {

  const [optionName,] = useState(('option-' + (offerFile.name || offerFile.id)).replace('.', ''));
  const [option, setOption] = useState(
    (offerFile.accept_via_e_mail === "1" ? 'e-mail' : null)
    ?? (offerFile.use_accept_via_link ? 'link' : null)
    ?? (offerFile.accept_via_link ? 'link' : null)
  );
  const [active, setActive] =
    useState(offerFile.accept_via_e_mail === "1" || offerFile.use_accept_via_link || offerFile.accept_via_link);
  const [reloadKey, setReloadKey] = useState(0);
  const offerContext = useContext(OfferContext);

  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  const saveSelection = values => {
    let offerFilesArray = value;
    let indexOfOfferFile = offerFilesArray.findIndex(item =>
      (item.name && item.name === offerFile.name)
      || (item.file?.name && item.file?.name === offerFile.file?.name)
      || (item.id && item.id === offerFile.id)
    );
    if (indexOfOfferFile >= 0) {
      let offerSelection = offerFile.path ? {file: offerFile, option: null} : {...offerFile, option: null};

      if (values[optionName] === 'e-mail') {
        offerSelection.option = 'e-mail';
        offerSelection.accept_via_e_mail = "1";
        offerSelection.use_accept_via_link = "0";
      } else if (values[optionName] === 'link') {
        offerSelection.option = values.url;
        offerSelection.accept_via_e_mail = "0";
        offerSelection.use_accept_via_link = "1";
      }

      offerSelection.accept_via_link = values.url;

      offerFilesArray[indexOfOfferFile] = offerSelection;
      setValue(offerFilesArray);
    }
    setReloadKey(reloadKey + 1);
  };

  let initialValues = {url: offerFile.accept_via_link ?? ''};
  initialValues[optionName] = option;

  useEffect(() => {
    saveSelection(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="OfferFileOptionSelect">
      <div className={'file-label' + (label ? '' : ' no-link')}>
        <FileIcon/>
        {label || offerFile.name || offerFile.file?.name || offerFile.filename}
        <span className="x-icon" onClick={() => onRemove()}>
          <XIcon/>
        </span>
      </div>
      <Formik initialValues={initialValues} onSubmit={() => {}} validateOnChange initialTouched={{url: true}}
        validate={(values) => {
          setOption(values[optionName]);
          saveSelection(values);
        }}>
        {({values, touched}) => (
          <Accordion title="Kaufoption" active={active} reload={reloadKey}
            onToggle={() => {
              saveSelection({...values, [optionName]: active ? null : values[optionName]});
              setActive(!active);
            }}>
            <div className="radio-buttons">
              <RadioButton name={optionName} value="link" text={
                <>
                  Kaufen per digitaler Unterschrift
                  <Tooltip>
                    {"Wenn du über eine digitale Variante der Angebotsannahme via Link verfügst, " +
                    "kannst du diesen hier platzieren."}
                  </Tooltip>
                </>
              }/>
              <RadioButton name={optionName} value="e-mail" text={
                <>
                  Kaufen per Kontaktaufnahme
                  <Tooltip>
                    {"Der Ansprechpartner, der bei diesem Angebot hinterlegt ist, " +
                    "bekommt eine Nachricht über das Kaufinteresse des Kunden."}
                  </Tooltip>
                </>
              }/>
            </div>
            {
              (option === 'link') &&
              <TextInput
                name="url" label="Link zum Kaufvorgang" placeholder="Bsp. https://sellestar.de"
                className={
                  (values.url.length === 0 && (offerContext.offer.offer_file_error || touched.url))
                    ? 'has-error'
                    : ''
                }
              />
            }
          </Accordion>
        )}
      </Formik>
    </div>
  );
}
