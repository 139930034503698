import Dropzone from 'react-dropzone';
import './DropzoneInput.scss';
import {XIcon} from "./Icons";
import {useField} from "formik";
import {useState} from "react";

export default function DropzoneInput({label, multiple, infoText, className, displayFiles,  ...props}) {

  const [, meta, helpers] = useField(props.name);
  const { value } = meta;
  const { setValue } = helpers;
  const [dragOver, setDragOver] = useState(false);

  const onDrop = acceptedFiles => {
    setDragOver(false);
    if (multiple) {
      const newValue = [...value];
      for (const acceptedFile of acceptedFiles) {
        let alreadyThere = false;
        for (const oldValue of value) {
          if (oldValue.name === acceptedFile.name) {
            alreadyThere = true;
          }
        }
        !alreadyThere && newValue.push(acceptedFile);
      }
      setValue(newValue);
    } else {
      setValue(acceptedFiles[0]);
    }
  };

  const removeFile = index => {
    setValue(multiple ? value.filter((_, i) => i !== index) : null);
  };

  return (
    <Dropzone
      onDrop={onDrop} multiple={multiple} onDragEnter={() => setDragOver(true)} onDragLeave={() => setDragOver(false)}
    >
      {({getRootProps, getInputProps}) => (
        <div
          className={
            'DropzoneInput' + (className ? ` ${className}` : '') + (dragOver ? ' drag-over' : '') +
            (meta.touched && meta.error ? ' has-error' : '')
          }
        >
          {label && <label htmlFor={props.id || props.name}>{label}</label>}
          <div className="zone" {...getRootProps()}>
            <input id={props.id || props.name} {...getInputProps()}/>
            <div><span>
              {props.text || (label && label.replace('*', '') + ' hochladen') || 'Hochladen'}
            </span> oder Drag and Drop</div>
            {infoText && <div className="info-text">({infoText})</div>}
          </div>
          {
            multiple && value && value.length > 0 && !displayFiles &&
            <ul>
              {
                value.map(
                  (file, index) =>
                    <li key={index}>{file.name}<span onClick={() => removeFile(index)}><XIcon/></span></li>
                )
              }
            </ul>
          }
          {
            multiple && value && value.length > 0 && displayFiles &&
              <>
                {
                  value.map(
                    (file, index) =>
                      <div key={index} className="mt-1">
                        {displayFiles(file, () => removeFile(index))}
                      </div>
                  )
                }
              </>
          }
          {!multiple && value && <ul><li>{value.name}<span onClick={removeFile}><XIcon/></span></li></ul>}
        </div>
      )}
    </Dropzone>
  );
}
