import Modal from "./Modal";
import './ErrorModal.scss';

export default function ErrorModal(props) {
  return (
    <Modal className="ErrorModal" title="Fehler" show={props.errors.length > 0} {...props}>
      <p>
        {props.errors.length === 1 && 'Der Vorgang kann aus folgendem Grund nicht ausgeführt werden:'}
        {props.errors.length !== 1 && 'Der Vorgang kann aus folgenden Gründen nicht ausgeführt werden:'}
      </p>
      <ul>
        {props.errors.map((error, index) => <li key={index}>{error.message}</li>)}
      </ul>
    </Modal>
  );
}
