import './RowActionButton.scss';
import {useContext} from "react";
import {RowContext} from "../contexts/RowContext";

export default function RowActionButton({color = 'turquoise', type, title, onClick, children}) {

  const row = useContext(RowContext);

  return (
    <button className={'RowActionButton ' + color} type={type} title={title} onClick={() => onClick(row)}>
      {typeof children === 'function' ? children(row) : children}
    </button>
  );

}
