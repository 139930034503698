import {useEffect, useState} from "react";
import useBL from "../hooks/useBL";
import {LoaderIcon} from "./Icons";

export default function DeviceCount({offer_id}) {

  const bl = useBL();

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState('?');

  useEffect(() => {
    setLoading(true);
    bl.count_devices({offer_id})
      .then(result => setCount(result))
      .finally(() => setLoading(false));
  }, [bl, offer_id]);

  return loading ? <LoaderIcon/> : count;

}
