import Title from '../components/Title';
import TitleHeading from '../components/TitleHeading';
import Stage from "../components/Stage";
import ButtonBar from "../components/ButtonBar";
import ButtonLink from "../components/ButtonLink";
import {EmptyPackageIcon, LoaderIcon, PlusCircleIcon} from "../components/Icons";
import {useEffect, useState} from "react";
import ErrorModal from "../components/ErrorModal";
import IconWithText from "../components/IconWithText";
import File from "../components/File";
import Flex from "../components/Flex";
import useCRUD from "../hooks/useCRUD";
import Pagination from "../components/Pagination";

export default function Documents() {

  const crud = useCRUD();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [files, setFiles] = useState({});
  const [totalDocuments, setTotalDocuments] = useState();
  const [page, setPage] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    crud.data.bulk.read({entity: 'document', page, page_size: pageSize})
      .then(bulkReadResult => {
        setDocuments([...bulkReadResult.items]);
        setTotalDocuments(bulkReadResult.total);
        if (bulkReadResult.items.length > 0) {
          let reads = [];
          setFiles({});
          bulkReadResult.items.forEach(item => reads.push(crud.data.read({entity: 'file', id: item.file_id})));
          Promise.all(reads)
            .then(result => result.forEach(item => {
              setFiles(files => ({...files, [item.id]: item}));
            }))
            .catch(errors => setErrors(errors))
            .finally(() => setLoading(false));
        } else {
          setLoading(false);
        }
      })
      .catch(errors => {
        setErrors(errors);
        setLoading(false);
      });
  }, [crud.data, page, pageSize]);

  return (
    <main>
      <Title>
        <TitleHeading>Dokumente</TitleHeading>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading && documents.length === 0 &&
          <IconWithText><EmptyPackageIcon/> Keine Einträge vorhanden.</IconWithText>
        }
        {!loading &&
          <Flex>{
            documents.map(
              document =>
                files[document.file_id] &&
                <File key={document.id} to={'/dokumente/' + document.id + '/details'} name={document.name}>
                  {files[document.file_id]}
                </File>
            )
          }</Flex>
        }
        {
          !loading && totalDocuments > documents.length &&
          <Pagination className="mt-1-75" total={totalDocuments} page={page} pageSize={pageSize} setPage={setPage}/>
        }
        <ButtonBar align="left">
          <ButtonLink to="neu" text="Hinzufügen" icon={<PlusCircleIcon/>}/>
        </ButtonBar>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
