import Title from '../components/Title';
import TitleHeading from '../components/TitleHeading';
import Stage from "../components/Stage";
import Table from "../components/Table";
import RowActionButton from "../components/RowActionButton";
import {DuplicateIcon, EditIcon, LoaderIcon, PlusCircleIcon, TrashIcon} from "../components/Icons";
import Button from "../components/Button";
import Column from "../components/Column";
import {useState} from "react";
import ErrorModal from "../components/ErrorModal";
import ButtonBar from "../components/ButtonBar";
import ButtonLink from "../components/ButtonLink";
import Modal from "../components/Modal";
import RowActionLink from "../components/RowActionLink";
import useCRUD from "../hooks/useCRUD";
import {useNavigate} from "react-router-dom";

export default function OfferTemplate() {
  const crud = useCRUD();
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const [duplicating, setDuplicating] = useState(false);
  const [duplicateRow, setDuplicateRow] = useState(null);
  const [tableKey, setTableKey] = useState(1);

  const doDuplicate = () => {
    setDuplicating(true);
    crud.data.read({entity: 'offer', id: duplicateRow.id})
      .then(offer_template_result => {
        crud.data.create({entity: 'offer', item: {
          title : offer_template_result.title + ' (Kopie)',
          is_template : 1,
          contact_person_id: offer_template_result.contact_person_id,
          header_image_id: offer_template_result.header_image_id,
          testimonial_group_id: offer_template_result.testimonial_group_id,
          faq_group_id: offer_template_result.faq_group_id,
          usp_group_id: offer_template_result.usp_group_id,
          letter: offer_template_result.letter,
          introduction_video_id: offer_template_result.introduction_video_id,
          offer_video_id: offer_template_result.offer_video_id,
          cta_id: offer_template_result.cta_id,
          translation_id: offer_template_result.translation_id,
        }})
          .then(async (created_offer) => {
            await crud.data.bulk.read({
              entity: "offer_has_file",
              page_size: 1000,
              filter: {
                group: 'or',
                components: [
                  {property: 'offer_id', operator: 'equals', value: duplicateRow.id},
                ]
              }
            }).then(async (offer_files) => {
              if (offer_files.total) {
                crud.data.bulk.create({
                  entity: 'offer_has_file',
                  items: offer_files.items.map(document => ({
                    offer_id: created_offer.id,
                    file_id: document.file_id ,
                    type: 'other',
                  }))
                });
              }
            });

            const picture_gallery_ids = await crud.data.bulk.read({
              entity: 'offer_has_picture_gallery',
              page_size: 1000,
              filter: crud.filter.equals('offer_id', duplicateRow.id)
            }).then(bulkReadResult => bulkReadResult.items.map(item => item.picture_gallery_id));
            if (picture_gallery_ids.length === 0 && offer_template_result.picture_gallery_id) {
              picture_gallery_ids.push(offer_template_result.picture_gallery_id);
            }
            if (picture_gallery_ids.length > 0) {
              await crud.data.bulk.create({
                entity: 'offer_has_picture_gallery',
                items: picture_gallery_ids.map(picture_gallery_id => ({
                  offer_id: created_offer.id,
                  picture_gallery_id: picture_gallery_id
                }))
              });
            }

            await crud.data.bulk.read({
              entity: "offer_has_logo_gallery",
              page_size: 1000,
              filter: {
                group: 'or',
                components: [
                  {property: 'offer_id', operator: 'equals', value: duplicateRow.id},
                ]
              }
            }).then(async (logo_galleries) => {
              if (logo_galleries.total) {
                await crud.data.bulk.create({
                  entity: 'offer_has_logo_gallery',
                  items: logo_galleries.items.map(item => ({
                    offer_id: created_offer.id,
                    logo_gallery_id: item.logo_gallery_id
                  }))
                });
              }
            })
              .catch(errors => setErrors(errors));

            await crud.data.bulk.read({
              entity: "offer_has_team_member",
              page_size: 1000,
              filter: {
                group: 'or',
                components: [
                  {property: 'offer_id', operator: 'equals', value: duplicateRow.id},
                ]
              }
            }).then(async (team_members) => {
              if (team_members.total) {
                await crud.data.bulk.create({
                  entity: 'offer_has_team_member',
                  items: team_members.items.map(item => ({
                    offer_id: created_offer.id,
                    team_member_id: item.team_member_id
                  }))
                });
              }
            })
              .catch(errors => setErrors(errors));
            navigate('/angebots-vorlagen/' + created_offer.id + '/bearbeiten');
          })
          .catch(errors => setErrors(errors))
          .finally(() => {
            setDuplicateRow(null);
            setTableKey(tableKey => tableKey + 1);
            setDuplicating(false);
          });
      })
      .catch(errors => setErrors(errors));
  };

  const [deleteRow, setDeleteRow] = useState(null);
  const doDelete = () => {
    crud.data.delete({'entity': 'offer', id: deleteRow.id})
      .then(() => {
        setDeleteRow(null);
        setTableKey(tableKey + 1);
      })
      .catch(errors => setErrors(errors));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Vorlagen für Angebote</TitleHeading>
      </Title>

      <Stage>
        <Table entity="offer" defaultOrderBy="title" key={tableKey}
          filter={{property: 'is_template', operator: 'equals', value: 1}}>
          <Column title="Bezeichnung" property="title"/>
          <Column type="actions">
            <RowActionLink to={(row) => row.id + '/bearbeiten'} color="blue" title="Bearbeiten">
              <EditIcon/>
            </RowActionLink>
            <RowActionButton color="turquoise" title="Duplizieren" onClick={setDuplicateRow}>
              <DuplicateIcon/>
            </RowActionButton>
            <RowActionButton color="red" title="Löschen" onClick={setDeleteRow}><TrashIcon/></RowActionButton>
          </Column>
        </Table>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        {
          duplicateRow &&
          <Modal title="Duplizieren bestätigen" onDismiss={() => setDuplicateRow(null)}>
            <p>Möchten Sie die Angebots Vorlage „{duplicateRow.title}“ wirklich duplizieren?</p>
            <ButtonBar>
              <Button color="grey" text="Abbrechen" disabled={duplicating} onClick={() => setDuplicateRow(null)}/>
              <Button
                color="turquoise" text="Duplizieren" icon={duplicating ? <LoaderIcon/> : <DuplicateIcon/>}
                disabled={duplicating} onClick={doDuplicate}
              />
            </ButtonBar>
          </Modal>
        }
        {
          deleteRow &&
          <Modal title="Löschen bestätigen" onDismiss={() => setDeleteRow(null)}>
            <p>Möchten Sie die Vorlage „{deleteRow.title}“ wirklich löschen?</p>
            <ButtonBar>
              <Button text="Behalten" onClick={() => setDeleteRow(null)}/>
              <Button color="red" text="Löschen" icon={<TrashIcon/>} onClick={doDelete}/>
            </ButtonBar>
          </Modal>
        }
        <ButtonBar>
          <ButtonLink to="/angebots-vorlagen/neu" text="Neue Vorlage anlegen" icon={<PlusCircleIcon/>}/>
        </ButtonBar>
      </Stage>
    </main>
  );

}
