import Title from "../components/Title";
import TitleHeading from "../components/TitleHeading";
import Breadcrumb from "../components/Breadcrumb";
import {NavLink, useParams} from "react-router-dom";
import Stage from "../components/Stage";
import {useEffect, useState} from "react";
import ErrorModal from "../components/ErrorModal";
import {ChevronLeftIcon, EditIcon, EmptyPackageIcon, LoaderIcon, PlusCircleIcon, TrashIcon} from "../components/Icons";
import ButtonBar from "../components/ButtonBar";
import ButtonLink from "../components/ButtonLink";
import IconWithText from "../components/IconWithText";
import Accordion from "../components/Accordion";
import React from "react";
import TextToParagraphs from "../components/TextToParagraphs";
import StageTitle from "../components/StageTitle";
import Modal from "../components/Modal";
import Button from "../components/Button";
import useCRUD from "../hooks/useCRUD";
import Table from "../components/Table";
import Column from "../components/Column";

export default function FaqGroupDetails() {

  const crud = useCRUD();
  const params = useParams();
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [stageKey, setStageKey] = useState(1);

  const [deleteFaq, setDeleteFaq] = useState(null);
  const doDelete = () => {
    crud.data.delete({'entity': 'faq', id: deleteFaq.id})
      .then(() => {
        setDeleteFaq(null);
        setStageKey(stageKey + 1);
      })
      .catch(errors => setErrors(errors));
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      crud.data.read({entity: 'faq_group', id: parseInt(params.id)})
        .then(result => setItem({...result}))
        .catch(errors => setErrors(errors)),
      crud.data.bulk.read({entity: 'faq', filter: crud.filter.equals('faq_group_id', params.id), page_size: 1000})
        .then(result => setFaqs(result.items))
        .catch(errors => setErrors(errors))
    ]).finally(() => setLoading(false));
  }, [crud.data, crud.filter, params.id, stageKey]);

  const transformAccordion = (question, faq) => (
    <Accordion
      title={faq.question} indicator="chevron" buttons={[
        <NavLink
          title="Bearbeiten" className="blue" to={`/faq-gruppen/${item.id}/${faq.id}/bearbeiten`}
        >
          <EditIcon/>
        </NavLink>,
        <button
          className="red"
          title="Löschen"
          onClick={(e) => {e.stopPropagation(); setDeleteFaq(faq);}}
        >
          <TrashIcon/>
        </button>
      ]}
    >
      <TextToParagraphs>{faq.answer}</TextToParagraphs>
    </Accordion>
  );

  return (
    <main>
      <Title>
        <TitleHeading>FAQ Gruppe</TitleHeading>
        <Breadcrumb>
          <NavLink to="/faq-gruppen">FAQ Gruppen</NavLink>
          {item && item.name}
        </Breadcrumb>
      </Title>
      <Stage key={stageKey}>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {
          !loading &&
          <>
            <StageTitle>
              {item.name}
              <NavLink to={'/faq-gruppen/' + item.id + '/bearbeiten'}><EditIcon/></NavLink>
            </StageTitle>
            {
              !item.headline &&
              <StageTitle className="subtitle disabled">
                Keine Überschrift
                <NavLink to={'/faq-gruppen/' + item.id + '/bearbeiten'}><EditIcon/></NavLink>
              </StageTitle>
            }
            {
              item.headline &&
              <StageTitle className="subtitle">
                {item.headline}
                <NavLink to={'/faq-gruppen/' + item.id + '/bearbeiten'}><EditIcon/></NavLink>
              </StageTitle>
            }
            {
              faqs.length === 0 &&
              <IconWithText><EmptyPackageIcon/> Keine Einträge vorhanden.</IconWithText>
            }
            <Table
              name="faqs"
              entity="faq"
              filter={{property: 'faq_group_id', operator: 'equals', value: parseInt(params.id)}}
              draggable
            >
              <Column property="question" transform={transformAccordion}/>
            </Table>
            {
              deleteFaq &&
              <Modal title="Löschen bestätigen" onDismiss={() => setDeleteFaq(null)}>
                <p>Möchten Sie die FAQ „{deleteFaq.question}“ wirklich löschen?</p>
                <ButtonBar>
                  <Button text="Behalten" onClick={() => setDeleteFaq(null)}/>
                  <Button color="red" text="Löschen" icon={<TrashIcon/>} onClick={doDelete}/>
                </ButtonBar>
              </Modal>
            }
            <ButtonBar>
              <ButtonLink to="/faq-gruppen" text="Zurück" icon={<ChevronLeftIcon/>} color="gray" iconPosition="left"/>
              {item && <ButtonLink to={`/faq-gruppen/${item.id}/neu`} text="Hinzufügen" icon={<PlusCircleIcon/>}/>}
            </ButtonBar>
          </>
        }
      </Stage>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </main>
  );

}
