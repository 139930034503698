import Title from "../components/Title";
import TitleHeading from "../components/TitleHeading";
import Stage from "../components/Stage";
import Table from "../components/Table";
import RowActionButton from "../components/RowActionButton";
import {DuplicateIcon, EditIcon, LoaderIcon, PlusCircleIcon, TrashIcon} from "../components/Icons";
import Button from "../components/Button";
import Column from "../components/Column";
import {useEffect, useState} from "react";
import ErrorModal from "../components/ErrorModal";
import ButtonBar from "../components/ButtonBar";
import ButtonLink from "../components/ButtonLink";
import RowActionLink from "../components/RowActionLink";
import {NavLink} from "react-router-dom";
import Modal from "../components/Modal";
import useCRUD from "../hooks/useCRUD";
import TextTeaser from "../components/TextTeaser";

export default function FaqGroups() {

  const crud = useCRUD();
  const [errors, setErrors] = useState([]);

  const [faqCount, setFaqCount] = useState({});
  const [pendingReadFaqCounts, setPendingReadFaqCounts] = useState([]);
  const readFaqCount = (faq_group) => {
    if (!pendingReadFaqCounts.includes(faq_group.id)) {
      setPendingReadFaqCounts(x => {x.push(faq_group.id); return x;});
      crud.data.bulk.read({
        entity: 'faq',
        page_size: 0,
        filter: {property: 'faq_group_id', operator: 'equals', value: faq_group.id}
      })
        .then(
          result => {
            faqCount[faq_group.id] = result.total;
            setFaqCount({...faqCount});
          }
        )
        .catch(errors => setErrors(errors))
        .finally(() => setPendingReadFaqCounts(x => x.filter(i => i !== faq_group.id)));
    }
  };

  const [deleteRow, setDeleteRow] = useState(null);
  const doDelete = () => {
    crud.data.delete({'entity': 'faq_group', id: deleteRow.id})
      .then(() => {
        setDeleteRow(null);
        setTableKey(tableKey + 1);
      })
      .catch(errors => setErrors(errors));
  };

  const [duplicating, setDuplicating] = useState(false);
  const [duplicateRow, setDuplicateRow] = useState(null);
  const doDuplicate = () => {
    setDuplicating(true);
    crud.data.read({entity: 'faq_group', id: duplicateRow.id})
      .then(faqGroupResult => {
        const {id, revision, created_at, updated_at, deleted_at, ...newFaqGroup} = {
          ...faqGroupResult,
          name: `${faqGroupResult.name} (Kopie)`
        };
        crud.data.create({entity: 'faq_group', item: newFaqGroup})
          .then(async newFaqGroupResult => {
            const faqItems = await crud.data.bulk.read({
              entity: 'faq',
              filter: crud.filter.equals('faq_group_id', faqGroupResult.id),
              page_size: 1000
            });
            if (faqItems.items.length > 0) {
              await crud.data.bulk.create({
                entity: 'faq',
                items: faqItems.items.map(faqItem => {
                  const {id, revision, created_at, updated_at, deleted_at, ...newFaqItem} = {
                    ...faqItem,
                    faq_group_id: newFaqGroupResult.id
                  };
                  return newFaqItem;
                })
              });
            }
          })
          .catch(errors => setErrors(errors))
          .finally(() => {
            setDuplicateRow(null);
            setTableKey(tableKey => tableKey + 1);
            setDuplicating(false);
          });
      })
      .catch(errors => setErrors(errors));
  };

  const [teaserWidth, setTeaserWidth] = useState();
  useEffect(() => {
    const handleResize = () => setTeaserWidth(window.innerWidth / 3);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [tableKey, setTableKey] = useState(1);
  const transformName = (name, faq_group) => <NavLink to={faq_group.id + '/details'}>{name}</NavLink>;
  const transformContent = content => <TextTeaser width={teaserWidth}>{content}</TextTeaser>;

  return (
    <main>
      <Title>
        <TitleHeading>FAQ</TitleHeading>
      </Title>
      <Stage>
        <Table name="faq_groups" entity="faq_group" defaultOrderBy="name" key={tableKey}>
          <Column title="Gruppe" property="name" transform={transformName}/>
          <Column title="Überschrift auf der späteren Angebotsseite" property="headline" transform={transformContent}/>
          <Column title="Anzahl FAQ" values={faqCount} populate={readFaqCount} type="number"/>
          <Column type="actions">
            <RowActionLink to={(row) => row.id + '/details'} color="blue" title="Bearbeiten">
              <EditIcon/>
            </RowActionLink>
            <RowActionButton color="turquoise" title="Duplizieren" onClick={setDuplicateRow}>
              <DuplicateIcon/>
            </RowActionButton>
            <RowActionButton color="red" title="Löschen" onClick={setDeleteRow}><TrashIcon/></RowActionButton>
          </Column>
        </Table>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        {
          duplicateRow &&
          <Modal title="Duplizieren bestätigen" onDismiss={() => setDuplicateRow(null)}>
            <p>Möchten Sie die FAQ Gruppe „{duplicateRow.name}“ wirklich duplizieren?</p>
            <ButtonBar>
              <Button color="grey" text="Abbrechen" disabled={duplicating} onClick={() => setDuplicateRow(null)}/>
              <Button
                color="turquoise" text="Duplizieren" icon={duplicating ? <LoaderIcon/> : <DuplicateIcon/>}
                disabled={duplicating} onClick={doDuplicate}
              />
            </ButtonBar>
          </Modal>
        }
        {
          deleteRow &&
          <Modal onDismiss={() => setDeleteRow(null)}>
            <p>Möchten Sie die FAQ Gruppe „{deleteRow.name}“ wirklich löschen?</p>
            <ButtonBar>
              <Button text="Behalten" onClick={() => setDeleteRow(null)}/>
              <Button color="red" text="Löschen" icon={<TrashIcon/>} onClick={doDelete}/>
            </ButtonBar>
          </Modal>
        }
        <ButtonBar>
          <ButtonLink to="neu" text="Hinzufügen" icon={<PlusCircleIcon/>}/>
        </ButtonBar>
      </Stage>
    </main>
  );

}
