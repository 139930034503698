import React from "react";

export default function TextToParagraphs(props) {
  return [...props.children.matchAll(/(.+?)(\n\n+|$)/gs)]
    .map((match, index) =>
      <p key={index}>
        {
          match[1].split('\n').map((line, index, lines) =>
            <React.Fragment key={index}>{line}{index < lines.length - 1 && <br/>}</React.Fragment>
          )
        }
      </p>
    );
}
