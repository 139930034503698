import DonutChart from "../../components/DonutChart";
import Title from "../../components/Title";
import TitleHeading from "../../components/TitleHeading";
import Stage from "../../components/Stage";
import Row from "../../components/Row";
import ButtonBar from "../../components/ButtonBar";
import ButtonLink from "../../components/ButtonLink";
import {ChevronLeftIcon} from "../../components/Icons";
import {useParams} from "react-router-dom";

export default function FaqStatistics() {

  const params = useParams();

  return (
    <main>
      <Title>
        <TitleHeading>Angebote</TitleHeading>
      </Title>
      <Stage>
        <Row>
          <DonutChart title="FAQ-Klicks" getFAQClicks offerId={params.id}/>
        </Row>
        <ButtonBar align="left">
          <ButtonLink
            to={`/angebot/${params.id}/statistik`} text="Zurück" icon={<ChevronLeftIcon/>} iconPosition="left"
            color="gray"
          />
        </ButtonBar>
      </Stage>
    </main>
  );

}
