import TitleHeading from "../components/TitleHeading";
import Breadcrumb from "../components/Breadcrumb";
import {NavLink, useParams} from "react-router-dom";
import Title from "../components/Title";
import Stage from "../components/Stage";
import ButtonLink from "../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../components/Icons";
import Button from "../components/Button";
import ButtonBar from "../components/ButtonBar";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../components/TextInput";
import FormErrors from "../components/FormErrors";
import ErrorModal from "../components/ErrorModal";
import {useState} from "react";
import Row from "../components/Row";
import Modal from "../components/Modal";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import IconWithText from "../components/IconWithText";
import TextareaInput from "../components/TextareaInput";
import useCRUD from "../hooks/useCRUD";

export default function EditFaq() {

  const crud = useCRUD();
  const params = useParams();
  const [item, setItem] = useState(null);
  const [faqGroup, setFaqGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      crud.data.read({entity: 'faq_group', id: parseInt(params.faq_group_id)})
        .then(result => setFaqGroup({...result}))
        .catch(errors => setErrors(errors)),
      crud.data.read({entity: 'faq', id: parseInt(params.faq_id)})
        .then(result => setItem({...result}))
        .catch(errors => setErrors(errors))
    ]).finally(() => setLoading(false));
  }, [crud.data, params.faq_group_id, params.faq_id]);

  const validationSchema = Yup.object({
    question: Yup.string().required('Bitte geben Sie eine Frage ein'),
    answer: Yup.string().required('Bitte geben Sie eine Antwort ein'),
  });

  const submit = (values, {setSubmitting}) => {
    crud.data.update({entity: 'faq', id: params.faq_id, update: {...values}})
      .then(() => {
        item.name = values.name;
        setItem({...item});
        setShowSuccess(true);
      })
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  return (
    <main>
      <Title>
        <TitleHeading>FAQ bearbeiten</TitleHeading>
        <Breadcrumb>
          <NavLink to="/faq-gruppen">FAQ Gruppen</NavLink>
          {faqGroup && <NavLink to={`/faq-gruppen/${faqGroup.id}/details`}>{faqGroup.name}</NavLink>}
          Bearbeiten
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
          <Formik
            initialValues={{question: item.question, answer: item.answer}} validationSchema={validationSchema}
            onSubmit={submit} onReset={() => setShowSuccess(false)}
          >
            {({isSubmitting, touched, errors}) => (
              <Form>
                <Row lgWidth="50%">
                  <TextInput
                    name="question" label="Stelle eine Frage*" placeholder="Bsp. Wie viele Mitarbeiter hat vyn?"
                  />
                </Row>
                <Row lgWidth="50%">
                  <TextareaInput
                    name="answer" label="Gib die Antwort ein*" placeholder="Bsp. vyn beschäftigt 10 Mitarbeiter."
                  />
                </Row>
                <ButtonBar align="left">
                  <ButtonLink
                    to={`/faq-gruppen/${faqGroup.id}/details`} color="gray" icon={<ChevronLeftIcon/>}
                    iconPosition="left" text="Zurück"
                  />
                  <Button
                    icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                    text="Speichern" type="submit" disabled={isSubmitting}
                  />
                </ButtonBar>
                <FormErrors touched={touched} errors={errors}/>
                <Modal
                  title="Die FAQ wurde erfolgreich gespeichert" show={showSuccess}
                  onDismiss={() => navigate(-1, {replace: true})}
                >
                  <ButtonBar>
                    <Button type="button" text="Ok" onClick={() => navigate(-1, {replace: true})}/>
                  </ButtonBar>
                </Modal>
              </Form>
            )}
          </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
