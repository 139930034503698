import './Tooltip.scss';
import {useLayoutEffect, useRef, useState} from 'react';

export default function Tooltip({...props}) {

  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({top: 0, left: 0});
  const [windowHeight, setWindowHeight] = useState(0);
  const [windowHWidth, setWindowWidth] = useState(0);

  const iconRef = useRef();
  const tooltipRef = useRef();

  useLayoutEffect(() => {
    const setTooltipPosition = () => {
      const iconRect = iconRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      let toolTippPos = {top: 0, left: 0};
      if ((tooltipRect.height + 2 * iconRect.height) > (window.innerHeight - iconRect.top)) {
        toolTippPos.top = iconRect.top - tooltipRect.height - iconRect.height;
      } else {
        toolTippPos.top = iconRect.top + 2 * iconRect.height;
      }
      if ((tooltipRect.width + 2 * iconRect.width) > (window.innerWidth - iconRect.left)) {
        toolTippPos.left = iconRect.left - tooltipRect.width - iconRect.width;
      } else {
        toolTippPos.left = iconRect.left + 2 * iconRect.width;
      }
      setPosition(toolTippPos);
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    };
    if (windowHeight !== window.innerHeight || windowHWidth !== window.innerWidth) {
      setTooltipPosition();
    }
    window.addEventListener('scroll', setTooltipPosition);
    window.addEventListener('resize', setTooltipPosition);
    return () => {
      window.removeEventListener('scroll', setTooltipPosition);
      window.removeEventListener('resize', setTooltipPosition);
    };
  }, [position, visible, windowHWidth, windowHeight]);

  return (
    <div ref={iconRef} className={'tooltipIcon'}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}>
      i
      <div className={'tooltipHover' + (visible ? '' : ' hidden')}/>
      <div
        ref={tooltipRef} className={'Tooltip' + (visible ? '' : ' hidden')}
        style={{top: position.top, left: position.left}}
      >
        {props.children}
      </div>
    </div>
  );
}
