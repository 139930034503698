import './Breadcrumb.scss';
import React from "react";

export default function Breadcrumb(props) {
  return (
    <nav className="Breadcrumb">
      <ul>
        {React.Children.toArray(props.children).map((child, index) => <li key={index}>{child}</li>)}
      </ul>
    </nav>
  );
}
