import {Form, Formik} from "formik";
import * as Yup from "yup";
import {ChevronLeftIcon, LoaderIcon} from "../components/Icons";
import Button from "../components/Button";
import FormErrors from "../components/FormErrors";
import PasswordInput from "../components/PasswordInput";
import Row from "../components/Row";
import Stage from "../components/Stage";
import Title from "../components/Title";
import TitleHeading from "../components/TitleHeading";
import ButtonBar from "../components/ButtonBar";
import {useState} from "react";
import Modal from "../components/Modal";
import {useNavigate} from "react-router-dom";
import useBL from "../hooks/useBL";
import ButtonLink from "../components/ButtonLink";

export default function ChangePassword() {

  const bl = useBL();

  const navigate = useNavigate();
  const [remoteErrors, setRemoteErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const validationSchema = Yup.object({
    old_password: Yup.string().required('Bitte das alte Passwort eingeben'),
    new_password: Yup.string().required('Bitte ein neues Passwort eingeben'),
    new_password_repetition: Yup.string()
      .required('Bitte das neue Passwort wiederholen')
      .oneOf([Yup.ref('new_password'), null], 'Passwörter müssen übereinstimmen')
  });

  const submit = (values, {setSubmitting}) => {
    bl.change_password({old_password: values.old_password, new_password: values.new_password})
      .then(() => setShowSuccess(true))
      .catch(remoteErrors => setRemoteErrors(remoteErrors))
      .finally(() => setSubmitting(false));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Persönliche Daten</TitleHeading>
      </Title>
      <Stage>
        <Formik
          initialValues={{old_password: '', new_password: '', new_password_repetition: ''}}
          validationSchema={validationSchema} onSubmit={submit} validate={() => setRemoteErrors([])}>
          {({isSubmitting, touched, errors}) => (
            <Form>
              <Row lgWidth="50%">
                <PasswordInput name="old_password" label="Altes Passwort eingeben"/>
              </Row>
              <Row lgWidth="50%">
                <PasswordInput name="new_password" label="Neues Passwort eingeben"/>
              </Row>
              <Row lgWidth="50%">
                <PasswordInput name="new_password_repetition" label="Neues Passwort wiederholen"/>
              </Row>
              <ButtonBar align="left">
                <ButtonLink to="/setup" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"/>
                <Button
                  text="Speichern" type="submit" icon={isSubmitting ? <LoaderIcon/> : null} disabled={isSubmitting}
                />
              </ButtonBar>
              <FormErrors touched={touched} errors={errors} remoteErrors={remoteErrors}/>
              <Modal
                title="Das Passwort wurde erfolgreich geändert" show={showSuccess}
                onDismiss={() => navigate('/setup')}
              >
                <ButtonBar>
                  <Button type="button" text="Ok" onClick={() => navigate('/setup')}/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
      </Stage>
    </main>
  );

}
