import React, {useEffect, useState} from "react";
import useCRUD from "../../hooks/useCRUD";
import ErrorModal from "../../components/ErrorModal";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import TitleHeading from "../../components/TitleHeading";
import Breadcrumb from "../../components/Breadcrumb";
import Title from "../../components/Title";
import Stage from "../../components/Stage";
import IconWithText from "../../components/IconWithText";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import {Form, Formik} from "formik";
import Row from "../../components/Row";
import TextInput from "../../components/TextInput";
import ButtonBar from "../../components/ButtonBar";
import Button from "../../components/Button";
import FormErrors from "../../components/FormErrors";
import Modal from "../../components/Modal";

export default function VideoEdit() {

  const crud = useCRUD();
  const params = useParams();
  const navigate = useNavigate();
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    setLoading(true);
    crud.data.read({entity: 'video', id: parseInt(params.id)})
      .then(result => setVideo({...result}))
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud.data, params.id]);

  const submit = (values, {setSubmitting}) => {
    crud.data.update({entity: 'video', id: params.id, update: {...values}})
      .then(() => {
        video.name = values.name;
        setVideo({...video});
        setShowSuccess(true);
      })
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Video bearbeiten</TitleHeading>
        <Breadcrumb>
          <NavLink to="/videos-und-memos/">Videos & Memos</NavLink>
          Bearbeiten
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
        <Formik
          initialValues={{name: video.name}} onSubmit={submit}
          onReset={() => setShowSuccess(false)}
        >
          {({isSubmitting, touched, errors}) => (
            <Form>
              <Row>
                <TextInput name="name" label="Name"/>
              </Row>
              <ButtonBar>
                <Button
                  onClick={() => navigate(-1)} color="gray" icon={<ChevronLeftIcon/>} iconPosition="left"
                  text="Zurück"
                />
                <Button
                  icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                  text="Speichern" type="submit" disabled={isSubmitting}
                />
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal title="Das Video wurde erfolgreich gespeichert" show={showSuccess}
                onDismiss={() => navigate(-1, {replace: true})}
              >
                <ButtonBar>
                  <Button type="button" text="Ok" onClick={() => navigate(-1, {replace: true})}/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
