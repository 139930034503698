import StageTitle from "../../../components/StageTitle";
import {useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import DesignTemplate from "../../../components/DesignTemplate";
import IconWithText from "../../../components/IconWithText";
import {ChevronRightIcon, LoaderIcon} from "../../../components/Icons";
import ButtonBar from "../../../components/ButtonBar";
import Button from "../../../components/Button";
import ErrorModal from "../../../components/ErrorModal";
import Stage from "../../../components/Stage";
import {Form, Formik} from "formik";
import FormErrors from "../../../components/FormErrors";
import * as Yup from "yup";
import useCRUD from "../../../hooks/useCRUD";
import useTemplates from "../../../hooks/useTemplates";
import Flex from "../../../components/Flex";
import {TemplateContext} from "../../../contexts/TemplateContext";

export default function Template() {

  const navigate = useNavigate();
  const crud = useCRUD();
  const templates = useTemplates();
  const templateContext = useContext(TemplateContext);

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  const validationSchema = Yup.object({
    template: Yup.number().required('Bitte wählen Sie eine Vorlage aus')
  });

  useEffect(() => {
    crud.data.read({entity: 'design', id: 1})
      .then(design => {
        if (design.template !== null) {
          navigate('/angebot/neu/allgemeines', {replace: true});
        }
      })
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud.data, navigate]);

  const submit = (values, {setSubmitting}) => {
    crud.data.update({entity: 'design', id: 1, update: values})
      .then(() => {
        templateContext.setTemplate(templates.find(template => template.id === parseInt(values.template)));
        navigate('/angebot/neu/allgemeines', {replace: true});
      })
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  return (
    <Stage>
      {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
      {!loading &&
        <Formik initialValues={{template: undefined}} validationSchema={validationSchema} onSubmit={submit}>
          {({values, setFieldValue, isSubmitting, errors}) => (
            <Form>
              <StageTitle>Welche Vorlage möchtest du nutzen?</StageTitle>
              <Flex>
                {templates.map(template => {
                  return (
                    <DesignTemplate
                      key={template.id}
                      src={`/${template.previewImage}`}
                      setActive={() => setFieldValue('template', template.id)}
                      active={values.template === template.id}
                    />
                  );
                })}
              </Flex>
              <FormErrors errors={errors}/>
              <ButtonBar>
                <Button
                  icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>} text="Weiter" type="submit"
                  disabled={isSubmitting}
                />
              </ButtonBar>
            </Form>
          )}
        </Formik>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Stage>
  );

}
