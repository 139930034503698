import Title from '../components/Title';
import TitleHeading from '../components/TitleHeading';
import Stage from "../components/Stage";
import Table from "../components/Table";
import RowActionButton from "../components/RowActionButton";
import {ChevronLeftIcon, EditIcon, LoaderIcon, PlusCircleIcon, TrashIcon} from "../components/Icons";
import Button from "../components/Button";
import Column from "../components/Column";
import {useEffect, useState} from "react";
import ErrorModal from "../components/ErrorModal";
import ButtonBar from "../components/ButtonBar";
import ButtonLink from "../components/ButtonLink";
import Modal from "../components/Modal";
import {NavLink, useParams} from "react-router-dom";
import RowActionLink from "../components/RowActionLink";
import Breadcrumb from "../components/Breadcrumb";
import StageTitle from "../components/StageTitle";
import IconWithText from "../components/IconWithText";
import useCRUD from "../hooks/useCRUD";

export default function UspGroup() {
  const crud = useCRUD();
  const params = useParams();
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);
  const [tableKey, setTableKey] = useState(1);
  const [deleteRow, setDeleteRow] = useState(null);

  const doDelete = () => {
    crud.data.delete({'entity': 'usp', id: deleteRow.id})
      .then(() => {
        setDeleteRow(null);
        setTableKey(tableKey + 1);
      })
      .catch(errors => setErrors(errors));
  };
  useEffect(() => {
    setLoading(true);
    crud.data.read({entity: 'usp_group', id: parseInt(params.id)})
      .then(result => setItem({...result}))
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud.data, params.id, setLoading]);

  const transformIcon = (icon, usp_group) => (
    <NavLink to={usp_group.id + '/bearbeiten'}><i className={icon + ' fa-2x'}/></NavLink>
  );
  const transformName = (name, usp_group) => <NavLink to={usp_group.id + '/bearbeiten'}>{name}</NavLink>;

  return (
    <main>
      <Title>
        <TitleHeading>Unternehmensvorteile</TitleHeading>
        <Breadcrumb>
          <NavLink to="/usp-gruppen">Unternehmensvorteile</NavLink>
          {item && <NavLink to={`/usp-gruppen/${item.id}/`}>{item.name}</NavLink>}
        </Breadcrumb>
      </Title>

      <Stage key={tableKey}>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {
          !loading &&
          <>
            <StageTitle>
              {item.name}
              <NavLink to={'/usp-gruppen/' + item.id + '/bearbeiten'}><EditIcon/></NavLink>
            </StageTitle>
            {
              !item.headline &&
              <StageTitle className="subtitle disabled">
                Keine Überschrift
                <NavLink to={'/usp-gruppen/' + item.id + '/bearbeiten'}><EditIcon/></NavLink>
              </StageTitle>
            }
            {
              item.headline &&
              <StageTitle className="subtitle">
                {item.headline}
                <NavLink to={'/usp-gruppen/' + item.id + '/bearbeiten'}><EditIcon/></NavLink>
              </StageTitle>
            }
            <Table
              name="usps"
              entity="usp"
              filter={{property: 'usp_group_id', operator: 'equals', value: parseInt(params.id)}}
              defaultOrderBy="headline"
              draggable
              key={tableKey}
            >
              <Column title="Icon" property="icon" transform={transformIcon}/>
              <Column title="Überschrift" property="headline" transform={transformName}/>
              <Column title="Unterüberschrift" property="sub_headline"/>
              <Column type="actions">
                <RowActionLink to={(row) => row.id + '/bearbeiten'} color="blue" title="Bearbeiten">
                  <EditIcon/>
                </RowActionLink>
                <RowActionButton color="red" title="Löschen" onClick={setDeleteRow}><TrashIcon/></RowActionButton>
              </Column>
            </Table>
            <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
            {
              deleteRow &&
              <Modal title="Löschen bestätigen" onDismiss={() => setDeleteRow(null)}>
                <p>Möchten Sie den Vorteil „{deleteRow.headline}“ wirklich löschen?</p>
                <ButtonBar>
                  <Button text="Behalten" onClick={() => setDeleteRow(null)}/>
                  <Button color="red" text="Löschen" icon={<TrashIcon/>} onClick={doDelete}/>
                </ButtonBar>
              </Modal>
            }
            <ButtonBar>
              <ButtonLink color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück" to="/usp-gruppen"/>
              <ButtonLink to="neu" text="Vorteil hinzufügen" icon={<PlusCircleIcon/>}/>
            </ButtonBar>
          </>
        }
      </Stage>
    </main>
  );
}
