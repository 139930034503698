import Title from '../components/Title';
import TitleHeading from '../components/TitleHeading';
import Stage from "../components/Stage";
import FactWidget from "../components/FactWidget";
import {useEffect, useState} from "react";
import useCRUD from "../hooks/useCRUD";
import Row from "../components/Row";
import IconWithText from "../components/IconWithText";
import {LoaderIcon} from "../components/Icons";
import ErrorModal from "../components/ErrorModal";

export default function MySubscription() {

  const crud = useCRUD();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      crud.data.read({entity: 'settings', id: 1}),
      crud.data.bulk.read({entity: "contact_person", page_size: 0})
    ])
      .then(([settings, contact_person]) => setData({
        plan: settings.subscription_plan,
        active: settings.active,
        allowedUsers: settings.allowed_users,
        emptyUserSlots: Math.max(settings.allowed_users - contact_person.total, 0),
        expirationDate: new Date(settings.expiration_date),
        daysLeft: Math.round((Math.abs((new Date(settings.expiration_date) - new Date()) / (24 * 60 * 60 * 1000))))
      }))
      .catch(() => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud.data, errors]);

  return (
    <main>
      <Title>
        <TitleHeading>Gebuchtes Modell</TitleHeading>
      </Title>
      <Stage transparent>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
          <Row>
            <FactWidget
              color={data.active === "1" ? "green" : "red"}
              number={data.plan.toUpperCase()}
              description="Ausgewähltes Abonnement"
              details={data.active === "1" ? "Aktiv" : "Nicht Aktiv"}
            />
            <FactWidget
              color={(data.emptyUserSlots <= (data.allowedUsers / 10)) ? "yellow" : "green"}
              number={data.emptyUserSlots} description="Freie Benutzer"
              details={"von " + data.allowedUsers}
            />
          </Row>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
