import TextInput from "./TextInput";
import './IconInput.scss';
import './TextInput.scss';
import icons from '../resources/icons';
import {useField} from "formik";

export default function IconInput({setFieldValue}) {
  const match = function (value, icon) {
    if (icons().filter(icon => icon === String(value)).length === 1) {
      return (icon === String(value) ? '' : 'disabled');
    } else {
      return (icon.includes(String(value)) ? '' : 'disabled');
    }
  };

  const [field] = useField('icon');

  return (
    <div className="IconInput">

      <TextInput
        name="icon" label="Icon" placeholder="Kein Icon ausgewählt"
        labelTooltip={
          'Das gewünschte Icon muss nur angeklickt werden und ist dann automatisch ausgewählt. '
        }
        onChange={(event) => {setFieldValue('icon', event.target.value);}}
      />

      <div className="Icons">{
        icons().map((icon, index) => {
          return (
            <i key={index} className={`${icon} fa-lg ico ${match(field.value, icon)}`}
              onClick={() => {setFieldValue('icon', icon);}}
            ></i>
          );
        })
      }
      </div>

    </div>
  );

}
