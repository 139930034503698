import {useEffect, useState} from "react";
import useCRUD from "../hooks/useCRUD";
import {LoaderIcon} from "./Icons";

export default function OfferCount({status, year}) {

  const crud = useCRUD();

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState('?');

  useEffect(() => {
    let filterComponents = [
      {property: 'created_at', operator:'beginsWith', value: year},
      {
        group: 'or',
        components: [
          {property: 'is_template', operator:'isNull'},
          {property: 'is_template', operator:'equals', value: 0}
        ]}
    ];
    if (status) {
      filterComponents.push({property: 'status', operator:'equals', value: status});
    }
    setLoading(true);
    crud.data.bulk.read({entity: 'offer', page_size: 0, filter: {group: 'and', components: filterComponents}})
      .then(offerBulkReadResult => setCount(offerBulkReadResult.total))
      .finally(() => setLoading(false));
  }, [crud, status, year]);

  return loading ? <LoaderIcon/> : count;

}
