import useCRUD from "../../hooks/useCRUD";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import Title from "../../components/Title";
import TitleHeading from "../../components/TitleHeading";
import Breadcrumb from "../../components/Breadcrumb";
import Stage from "../../components/Stage";
import {Form, Formik} from "formik";
import TextInput from "../../components/TextInput";
import TextareaInput from "../../components/TextareaInput";
import ButtonBar from "../../components/ButtonBar";
import Button from "../../components/Button";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import FormErrors from "../../components/FormErrors";
import Modal from "../../components/Modal";
import ErrorModal from "../../components/ErrorModal";
import IconWithText from "../../components/IconWithText";

export default function BewertungBearbeiten() {

  const crud = useCRUD();
  const navigate = useNavigate();
  const params = useParams();

  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [testimonial, setTestimonial] = useState(null);
  const [testimonialGroup, setTestimonialGroup] = useState(null);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      crud.data.read({entity: 'testimonial', id: parseInt(params.testimonial_id)}),
      crud.data.read({entity: 'testimonial_group', id: parseInt(params.testimonial_group_id)})
    ])
      .then(([testimonialResult, testimonialGroupResult]) => {
        setTestimonial(testimonialResult);
        setTestimonialGroup(testimonialGroupResult);
      })
      .finally(() => setLoading(false))
      .catch(errors => setErrors(errors));
  }, [crud.data, params]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Bitte einen Namen eingeben'),
    content: Yup.string().required('Bitte eine Bewertung eingeben').max(1000, 'Der Text für die Bewertung ist zu lang')
  });

  function onSubmit(values, {setSubmitting}) {
    crud.data.update({
      entity: 'testimonial',
      id: params.testimonial_id,
      update: {...values, testimonial_group_id: params.testimonial_group_id}
    })
      .then(() => setShowSuccess(true))
      .finally(() => setSubmitting(false))
      .catch(errors => setErrors(errors));
  }

  return (
    <main>
      <Title>
        <TitleHeading>Bewertung bearbeiten</TitleHeading>
        <Breadcrumb>
          <NavLink to="/bewertungsgruppen">Bewertungsgruppen</NavLink>
          {
            testimonialGroup &&
            <NavLink to={`/bewertungsgruppen/${params.testimonial_group_id}/details`}>
              {testimonialGroup.name}
            </NavLink>
          }
          bearbeiten
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
        <Formik
          initialValues={{name: testimonial.name, title: testimonial.title, content: testimonial.content}}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          onReset={() => setShowSuccess(false)}
        >
          {({isSubmitting, touched, errors}) => (
            <Form className="mt-1-75">
              <TextInput name="name" label="Name*" placeholder="Name der bewertenden Person"
                className="mb-1"/>
              <TextInput name="title" label="Titel" placeholder="Bezeichnung des Titels / der Position"/>
              <TextareaInput
                className="mt-1-75" name="content" label="Bewertung*"
                placeholder={'Bsp. Hervorragend!\nGroßartiger, schneller Service.'}
              />
              <ButtonBar>
                <Button color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                  onClick={() => navigate(-1)}/>
                <Button icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                  text="Speichern" type="submit" disabled={isSubmitting}/>
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal
                title="Die Bewertung wurde erfolgreich gespeichert" show={showSuccess}
                onDismiss={() => navigate(`/bewertungsgruppen/${params.testimonial_group_id}/${params.testimonial_id}`)}
              >
                <ButtonBar>
                  <Button text="Ok" onClick={() => navigate(-1)}/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
