import './galerie.scss';
import TitleHeading from "../../components/TitleHeading";
import Breadcrumb from "../../components/Breadcrumb";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import Title from "../../components/Title";
import Stage from "../../components/Stage";
import {useEffect, useState} from "react";
import useCRUD from "../../hooks/useCRUD";
import ErrorModal from "../../components/ErrorModal";
import IconWithText from "../../components/IconWithText";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon, TrashIcon} from "../../components/Icons";
import {Form, Formik} from "formik";
import TextInput from "../../components/TextInput";
import DropzoneInput from "../../components/DropzoneInput";
import ButtonBar from "../../components/ButtonBar";
import ButtonLink from "../../components/ButtonLink";
import Button from "../../components/Button";
import FormErrors from "../../components/FormErrors";
import Modal from "../../components/Modal";
import * as Yup from "yup";
import Row from "../../components/Row";
import ImageInput from "../../components/ImageInput";
import RowActionButton from "../../components/RowActionButton";
import useFiles from "../../hooks/useFiles";
import Video from "../../components/Video";
import SelectInput from "../../components/SelectInput";

export default function Bearbeiten() {

  const crud = useCRUD();
  const files = useFiles();
  const params = useParams();
  const navigate = useNavigate();
  const [reload, setReload] = useState(0);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keepImage, setKeepImage] = useState(false);
  const [keepVideo, setKeepVideo] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [pictureGalleryItem, setPictureGalleryItem] = useState(null);

  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => setShowLoading(true), 1000);
    crud.data.read({entity: 'picture_gallery_item', id: parseInt(params.id)})
      .then(async result => {
        if (result.image_id) {
          setPictureGalleryItem((await crud.expand([result], ['picture_gallery', 'image:file']))[0]);
          setKeepImage(true);
          setKeepVideo(false);
        } else if (result.video_id) {
          let data = (await crud.expand([result], ['picture_gallery', 'video']))[0];
          if (data.video.file_id) {
            data.video = (await crud.expand([data.video], ['file']))[0];
          }
          setPictureGalleryItem(data);
          setKeepVideo(true);
          setKeepImage(false);
        } else {
          setKeepImage(false);
          setKeepVideo(false);
        }
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        setLoading(false) || setShowLoading(false) || clearTimeout(timeout);
      });
    return () => clearTimeout(timeout);
  }, [crud, params.id, reload]);

  const validationSchema = Yup.object({
    image: Yup.mixed().nullable(),
    video: Yup.string().nullable()
  }).test(
    'image or video',
    'Bitte ein Bild oder ein Video auswählen',
    function(value) {
      if (!keepImage && !keepVideo) {
        if (value.video !== null && value.image !== null) {
          return this.createError({
            path: 'video'
          });
        } else if (value.video === null && value.image === null) {
          return this.createError({
            path: 'image'
          });
        }
      }
    });

  const submit = (values, {setSubmitting}) => {
    const save = image_id => {
      const update = {text: values.text};
      if (image_id) {
        update.image_id = image_id;
        update.video_id = null;
      }
      crud.data.update({entity: 'picture_gallery_item', id: parseInt(params.id), update})
        .then(() => setReload(x => x + 1))
        .catch(errors => setErrors(errors))
        .finally(() => setShowSuccess(true) || setSubmitting(false));
    };

    if (values.image) {
      files.upload(values.image)
        .then(result => save(result.id))
        .catch(errors => setErrors(errors) || setSubmitting(false));
    } else {
      if (values.video) {
        const update = {text: values.text, image_id: null, video_id: values.video};
        crud.data.update({entity: 'picture_gallery_item', id: parseInt(params.id), update})
          .then(() => setReload(x => x + 1))
          .catch(errors => setErrors(errors))
          .finally(() => setShowSuccess(true) || setSubmitting(false));
      } else {
        save();
      }
    }

  };

  return (
    <main>
      <Title>
        <TitleHeading>Eintrag bearbeiten</TitleHeading>
        <Breadcrumb>
          <NavLink to="/galerien">Galerien</NavLink>
          {
            pictureGalleryItem &&
            <NavLink to={`/galerie/${pictureGalleryItem.picture_gallery_id}/details`}>
              {pictureGalleryItem.picture_gallery.headline}
            </NavLink>
          }
          Eintrag bearbeiten
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && showLoading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {
          pictureGalleryItem &&
          <Formik
            initialValues={{text: pictureGalleryItem.text, image: null, video: null}}
            validationSchema={validationSchema}
            onSubmit={submit}
          >
            {({isSubmitting, touched, errors}) => (
              <Form>
                <TextInput name="text" label="Text" placeholder="Bsp. Unser Bürogebäude"/>
                {
                  <Row className="mt-1-75" lgWidth="75%">
                    {pictureGalleryItem.image && keepImage &&
                      <ImageInput label="Bild*">
                        <RowActionButton title="Entfernen" color="red" onClick={() => setKeepImage(false)}
                          className="delete-button">
                          <TrashIcon/>
                        </RowActionButton>
                        <img src={pictureGalleryItem.image.url} alt=""/>
                      </ImageInput>
                    }
                    {pictureGalleryItem.video && keepVideo &&
                      <div className="video-box">
                        <RowActionButton color="red" title="Löschen" onClick={() => setKeepVideo(false)}>
                          <TrashIcon/>
                        </RowActionButton>
                        <Video videoProps={pictureGalleryItem.video}/>
                      </div>
                    }
                  </Row>
                }
                {
                  (!keepImage && !keepVideo) &&
                      <div className="mt-1">
                        <DropzoneInput
                          className="mt-1-75" name="image" label="Bild hochladen"
                          infoText="Nur PNG-, JPG-, GIF-, SVG- oder WEBP-Dateien"
                        />
                        <Row className="mt-1" lgWidth="50%">
                          <SelectInput name="video" entity="video"
                            getOptionLabel={item => item.name}
                            label="oder Video auswählen"/>
                        </Row>
                      </div>
                }
                <ButtonBar align="left">
                  <ButtonLink
                    to={`/galerie/${pictureGalleryItem.picture_gallery_id}/details`} color="gray"
                    icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                  />
                  <Button
                    icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                    text="Speichern" type="submit" disabled={isSubmitting}
                  />
                </ButtonBar>
                <FormErrors touched={touched} errors={errors}/>
                {
                  showSuccess &&
                  <Modal
                    title="Der Galerieeintrag wurde erfolgreich gespeichert"
                    onDismiss={() => navigate(`/galerie/${pictureGalleryItem.picture_gallery_id}/details`)}
                  >
                    <ButtonBar>
                      <ButtonLink
                        to={`/galerie/${pictureGalleryItem.picture_gallery_id}/details`}
                        text="Zurück zur Galerie"
                      />
                    </ButtonBar>
                  </Modal>
                }
              </Form>
            )}
          </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
