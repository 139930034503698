import './Track.scss';
import React from 'react';
import TrackStation from "./TrackStation";

export default function Track(props) {

  let activeIndex = null;
  let lastIndex = null;

  React.Children.toArray(props.children).forEach((child, index) => {
    if (child.props.active) {
      activeIndex = index;
    }
    lastIndex = index;
  });

  return (
    <div className="Track">
      <ol>
        {
          activeIndex === 0 &&
          <TrackStation className="placeholder-start"/>
        }
        {
          React.Children.toArray(props.children)
            .map((child, index) => {
              let addProps = {index: index + 1, lastIndex: lastIndex + 1};
              if (index === activeIndex - 1) {
                addProps.className = ((child.props.className || '') + ' pre-active').trim();
              }
              if (index === activeIndex + 1) {
                addProps.className = ((child.props.className || '') + ' post-active').trim();
              }
              return React.cloneElement(child, addProps);
            })
        }
        {
          activeIndex === lastIndex &&
          <TrackStation className="placeholder-end"/>
        }
      </ol>
    </div>
  );

}
