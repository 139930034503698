import Title from "../../components/Title";
import TitleHeading from "../../components/TitleHeading";
import Stage from "../../components/Stage";
import {Form, Formik} from "formik";
import TextInput from "../../components/TextInput";
import TextareaInput from "../../components/TextareaInput";
import ButtonBar from "../../components/ButtonBar";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import Button from "../../components/Button";
import useCRUD from "../../hooks/useCRUD";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import ErrorModal from "../../components/ErrorModal";
import FormErrors from "../../components/FormErrors";
import Modal from "../../components/Modal";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import IconWithText from "../../components/IconWithText";

export default function BewertungNeu() {

  const crud = useCRUD();
  const navigate = useNavigate();
  const params = useParams();

  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [testimonialGroup, setTestimonialGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orderIndex, setOrderIndex] = useState(null);

  const validationSchema = Yup.object({
    name: Yup.string().required('Bitte einen Namen eingeben'),
    content: Yup.string().required('Bitte eine Bewertung eingeben').max(1000, 'Der Text für die Bewertung ist zu lang')
  });

  useEffect(() => {
    setLoading(true);
    Promise.all([
      crud.data.read({entity: 'testimonial_group', id: parseInt(params.testimonial_group_id)}),
      crud.data.bulk.read({
        entity: 'testimonial',
        page_size: 9999,
        filter: crud.filter.equals('testimonial_group_id', parseInt(params.testimonial_group_id))
      })
    ])
      .then(([testimonialGroupResult, testimonialResult]) => {
        setTestimonialGroup(testimonialGroupResult);
        setOrderIndex(
          testimonialResult.items.map(usp => parseInt(usp.order ?? '-1')).reduce((a, b) => Math.max(a, b), - 1) + 1
        );
      })
      .finally(() => setLoading(false))
      .catch(errors => setErrors(errors));
  }, [crud, params]);

  function onSubmit(values, {setSubmitting}) {
    crud.data.create({
      entity: 'testimonial',
      item: {...values, testimonial_group_id: params.testimonial_group_id, order: orderIndex}
    })
      .then(() => setShowSuccess(true))
      .finally(() => setSubmitting(false))
      .catch(errors => setErrors(errors));
  }

  return (
    <main>
      <Title>
        <TitleHeading>Bewertung erstellen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/bewertungsgruppen">Bewertungsgruppen</NavLink>
          {
            testimonialGroup &&
            <NavLink to={`/bewertungsgruppen/${params.testimonial_group_id}/details`}>
              {testimonialGroup.name}
            </NavLink>
          }
          neu
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
          <Formik
            initialValues={{name: '', title: '', content: ''}}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            onReset={() => setShowSuccess(false)}
          >
            {({isSubmitting, touched, errors}) => (
              <Form className="mt-1-75">
                <TextInput name="name" label="Name*" placeholder="Name der bewertenden Person"
                  className="mb-1"/>
                <TextInput name="title" label="Titel" placeholder="Bezeichnung des Titels / der Position"/>
                <TextareaInput
                  className="mt-1-75" name="content" label="Bewertung*"
                  placeholder={'Bsp. Hervorragend!\nGroßartiger, schneller Service.'}
                />
                <ButtonBar>
                  <Button
                    color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                    onClick={() => navigate(-1)}
                  />
                  <Button icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                    text="Speichern" type="submit" disabled={isSubmitting}/>
                </ButtonBar>
                <FormErrors touched={touched} errors={errors}/>
                <Modal
                  title="Die Bewertung wurde erfolgreich erstellt" show={showSuccess}
                  onDismiss={() => navigate('/bewertungen')}
                >
                  <ButtonBar>
                    <Button type="reset" color="gray" text="Weitere Bewertung erstellen"/>
                    <Button text="Zurück zur Übersicht" onClick={() => navigate(-1)}/>
                  </ButtonBar>
                </Modal>
              </Form>
            )}
          </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
