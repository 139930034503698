import Title from "../../components/Title";
import TitleHeading from "../../components/TitleHeading";
import Stage from "../../components/Stage";
import {Form, Formik} from "formik";
import TextInput from "../../components/TextInput";
import ButtonBar from "../../components/ButtonBar";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import Button from "../../components/Button";
import useCRUD from "../../hooks/useCRUD";
import React, {useContext, useEffect, useState} from "react";
import * as Yup from "yup";
import ErrorModal from "../../components/ErrorModal";
import FormErrors from "../../components/FormErrors";
import Modal from "../../components/Modal";
import {NavLink, useNavigate} from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import SelectInput from "../../components/SelectInput";
import Accordion from "../../components/Accordion";
import Row from "../../components/Row";
import TextareaInput from "../../components/TextareaInput";
import {TemplateContext} from "../../contexts/TemplateContext";

export default function CreateOfferTemplate() {

  const crud = useCRUD();
  const navigate = useNavigate();

  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [useContactPerson, setUseContactPerson] = useState(false);
  const [useTeamMembers, setUseTeamMembers] = useState(false);
  const [useHeaderImage, setUseHeaderImage] = useState(false);
  const [useLetter, setUseLetter] = useState(false);
  const [selectLetter, setSelectLetter] = useState(false);
  const [useUsp, setUseUsp] = useState(false);
  const [useFaq, setUseFaq] = useState(false);
  const [usePictureGalleries, setUsePictureGalleries] = useState(false);
  const [useLogoGalleries, setUseLogoGalleries] = useState(false);
  const [useDocuments, setUseDocuments] = useState(false);
  const [useCta, setUseCta] = useState(false);
  const [useTestimonials, setUseTestimonials] = useState(false);
  const [useTranslation, setUseTranslation] = useState(false);
  const [useIntroductionVideo, setUseIntroductionVideo] = useState(false);
  const [useOfferVideo, setUseOfferVideo] = useState(false);
  const [offerButtonDisabled, setOfferButtonDisabled] = useState(0);
  const templateContext = useContext(TemplateContext);

  const name = contactPerson => {
    let result  = ((contactPerson.first_name ?? '') + ' ' + (contactPerson.last_name ?? '')).trim();
    return result.length > 0 ? result : contactPerson.e_mail;
  };

  const [contactPersonsAvailable, setContactPersonsAvailable] = useState(false);
  const [headerImageAvailable, setHeaderImageAvailable] = useState(false);
  const [letterAvailable, setLetterAvailable] = useState(false);
  const [uspAvailable, setUspAvailable] = useState(false);
  const [faqAvailable, setFaqAvailable] = useState(false);
  const [pictureGalleryAvailable, setPictureGalleryAvailable] = useState(false);
  const [ctaAvailable, setCtaAvailable] = useState(false);
  const [translationAvailable, setTranslationAvailable] = useState(false);
  const [videoAvailable, setVideoAvailable] = useState(false);
  const [testimonialsAvailable, setTestimonialsAvailable] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      crud.data.bulk.read({entity: "contact_person", page_size: 0}),
      crud.data.bulk.read({entity: "header_image", page_size: 0}),
      crud.data.bulk.read({entity: "usp_group", page_size: 0}),
      crud.data.bulk.read({entity: "faq_group", page_size: 0}),
      crud.data.bulk.read({entity: "picture_gallery", page_size: 0}),
      crud.data.bulk.read({entity: "cta", page_size: 0}),
      crud.data.bulk.read({entity: "translation", page_size: 0}),
      crud.data.bulk.read({entity: "letter", page_size: 0}),
      crud.data.bulk.read({entity: "video", page_size: 0}),
      crud.data.bulk.read({entity: "testimonial_group", page_size: 0}),
    ])
      .then(([
        contact_person, header_image, usp, faq, picture_gallery, cta, translation, letter,video, testimonial_group
      ]) => {
        setContactPersonsAvailable(contact_person.total > 0);
        setHeaderImageAvailable(header_image.total > 0);
        setUspAvailable(usp.total > 0);
        setFaqAvailable(faq.total > 0);
        setPictureGalleryAvailable(picture_gallery.total > 0);
        setCtaAvailable(cta.total > 0);
        setTranslationAvailable(translation.total > 0);
        setLetterAvailable(letter.total > 0);
        setVideoAvailable(video.total > 0);
        setTestimonialsAvailable(testimonial_group.total > 0);
      })
      .finally(() => setIsLoading(false))
      .catch(errors => setErrors([errors]));
  }, [crud.data.bulk]);

  const validationSchema = Yup.object({
    title: Yup.string().required('Bitte eine Bezeichnung für die Vorlage angeben'),
  });

  function onSubmit(values, {setSubmitting}) {
    let newOffer = {
      title : values.title,
      is_template : 1,
      contact_person_id: !useContactPerson ? null : values.contact_person_id,
      header_image_id: !useHeaderImage ? null : values.header_image_id,
      testimonial_group_id: !useTestimonials ? null : values.testimonial_group_id,
      faq_group_id: !useFaq ? null : values.faq_group_id,
      usp_group_id: !useUsp && values.usp_group_id ? null : values.usp_group_id,
      cta_id: !useCta ? null : values.cta_id,
      translation_id: !useTranslation ? null : values.translation_id,
      introduction_video_id: !useIntroductionVideo ? null : values.introduction_video_id,
      offer_video_id: !useOfferVideo ? null : values.offer_video_id,
      letter: !useLetter ? null : values.letter,
      offer_button_disabled: offerButtonDisabled
    };
    crud.data.create({entity: 'offer', item: {...newOffer}})
      .then(async (created_offer) => {
        if (useDocuments && values.documents && values.documents.length > 0) {
          await crud.data.bulk.read({
            entity: "document",
            page_size: 1000,
            filter: {
              group: 'or',
              components: values.documents.map(document_id => ({
                property: 'id',
                operator: 'equals',
                value: document_id
              }))
            }
          })
            .then(document_objects => {
              crud.data.bulk.create({
                entity: 'offer_has_file',
                items: document_objects.items.map(document => ({
                  offer_id: created_offer.id,
                  file_id: document.file_id ,
                  type: 'other',
                }))
              });
            })
            .catch(errors => setErrors([errors]));
        }
        if (useLogoGalleries && values.logo_galleries && values.logo_galleries.length > 0 ) {
          await crud.data.bulk.create({
            entity: 'offer_has_logo_gallery',
            items: values.logo_galleries.map(item => ({ offer_id: created_offer.id, logo_gallery_id: item}))
          });
        }

        if (usePictureGalleries && values.picture_galleries && values.picture_galleries.length > 0 ) {
          await crud.data.bulk.create({
            entity: 'offer_has_picture_gallery',
            items: values.picture_galleries.map(item => ({ offer_id: created_offer.id, picture_gallery_id: item}))
          });
        }

        if (useTeamMembers && values.team_members && values.team_members.length > 0 ) {
          await crud.data.bulk.create({
            entity: 'offer_has_team_member',
            items: values.team_members.map(item => ({offer_id: created_offer.id, team_member_id: item }))
          });
        }
      })
      .finally(() => {
        setSubmitting(false);
        setShowSuccess(true);
      })
      .catch(errors => setErrors([errors]));
    setSubmitting(false);
  }

  const [liteRestrictions, setLiteRestrictions] = useState(true);
  useEffect(() => {
    crud.data.read({entity: "settings", id: 1})
      .then(settings => setLiteRestrictions(settings.subscription_plan === "lite"));
  }, [crud.data]);

  return (
    <main>
      <Title>
        <TitleHeading>Angebots Vorlage erstellen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/angebots-vorlagen">Vorlagen</NavLink>
          Neu
        </Breadcrumb>
      </Title>
      <Stage>
        <Formik
          initialValues={{
            title: '',
            contact_person_id: null,
            team_members: null,
            header_image_id: null,
            letter: '',
            introduction_video_id: null,
            offer_video_id: null,
            testimonial_group_id: null,
            usp_group_id: null,
            faq_group_id: null,
            picture_galleries: null,
            logo_galleries: null,
            cta_id: null,
            documents: null,
            translation_id: null,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          onReset={() => setShowSuccess(false)}
        >
          {({isSubmitting, touched, errors, setFieldValue}) => (
            <Form className="mt-1-75">
              <TextInput
                name="title" label="Name" placeholder="Bsp. Standard-Vorlage"
                className="mb-1"
              />

              <Accordion
                className="mt-1-75" title="Ansprechpartner deiner Firma" active={useContactPerson}
                onToggle={() => {
                  setUseContactPerson(!useContactPerson);
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !contactPersonsAvailable &&
                  !isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Ansprechpartner angelegt.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/ansprechpartner">Ansprechpartner</NavLink>
                    </p>
                  </div>
                }
                {
                  contactPersonsAvailable &&
                  !isLoading &&
                  <Row lgWidth="50%">
                    <SelectInput
                      label="Ansprechpartner" name="contact_person_id" menuPosition="fixed" entity="contact_person"
                      getOptionLabel={name}
                    />
                  </Row>
                }
              </Accordion>
              {
                templateContext.template && templateContext.template.exclusiveFeatures.includes('team') &&
                <Accordion
                  title="Teammitglieder" active={useTeamMembers}
                  onToggle={() => {
                    setUseTeamMembers(!useTeamMembers);
                  }}
                >
                  <SelectInput
                    label="Teammitglieder" name="team_members" menuPosition="fixed" entity="team_member"
                    getOptionLabel={item => `${item.first_name.trim()} ${item.last_name.trim()}`} isMulti isClearable
                  />
                </Accordion>
              }
              <Accordion
                title="Titelbild" active={useHeaderImage}
                onToggle={() => {
                  setUseHeaderImage(!useHeaderImage);
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !headerImageAvailable &&
                  !isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Titelbilder hochgeladen.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/titelbilder">Titelbilder</NavLink>
                    </p>
                  </div>
                }
                {
                  headerImageAvailable &&
                  !isLoading &&
                  <Row lgWidth="50%">
                    <SelectInput
                      label="Titelbilder" name="header_image_id" menuPosition="fixed" entity="header_image"
                      getOptionLabel={item => (item.name).trim()}
                    />
                  </Row>
                }
              </Accordion>
              <Accordion
                title="Anschreiben" active={useLetter}
                onToggle={() => {
                  setUseLetter(!useLetter);
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !letterAvailable &&
                  !isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Anschreiben hochgeladen.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/anschreiben">Titelbilder</NavLink>
                    </p>
                  </div>
                }
                {
                  letterAvailable &&
                  !isLoading &&
                  <Row lgWidth="50%">
                    { !selectLetter ?
                      <ButtonBar align="left">
                        <Button text="Anschreiben auswählen" onClick={() => setSelectLetter(true)}/>
                      </ButtonBar>
                      :
                      <SelectInput onChange={(value) => {
                        setFieldValue('letter', value.content);
                        setSelectLetter(false);
                      }} className="mt-1-75" name="letterSelection" entity="letter"
                      getOptionLabel={item => item.title} menuPosition="fixed"/>
                    }
                  </Row>
                }
                <TextareaInput
                  className="mt-1-75" name="letter" label="Anschreiben"
                  placeholder={
                    "Bsp. Vielen Dank, dass ich mich Ihrem Unternehmen vorstellen durfte. " +
                    "Folgend möchte ich Ihnen unser Unternehmen kurz vorstellen und unser Angebot erläutern."
                  }
                />
              </Accordion>

              <Accordion
                title="Begrüßungsvideo" active={useIntroductionVideo}
                onToggle={() => {
                  setUseIntroductionVideo(!useIntroductionVideo);
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !videoAvailable &&
                  !isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Videos hochgeladen.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/videos-und-memos">Videos & Memos</NavLink>
                    </p>
                  </div>
                }
                {
                  videoAvailable &&
                  !isLoading &&
                  <Row lgWidth="50%">
                    <SelectInput name="introduction_video_id" entity="video" getOptionLabel={item => item.name}
                      menuPosition="fixed"/>
                  </Row>
                }
              </Accordion>
              <Accordion
                title="Angebotsvideo" active={useOfferVideo}
                onToggle={() => {
                  setUseOfferVideo(!useOfferVideo);
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !videoAvailable &&
                  !isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Videos hochgeladen.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/videos-und-memos">Videos & Memos</NavLink>
                    </p>
                  </div>
                }
                {
                  videoAvailable &&
                  !isLoading &&
                  <Row lgWidth="50%">
                    <SelectInput name="offer_video_id" entity="video" getOptionLabel={item => item.name}
                      menuPosition="fixed"/>
                  </Row>
                }
              </Accordion>
              <Accordion
                title="Bewertungen" active={useTestimonials}
                onToggle={() => setUseTestimonials(!useTestimonials)}
              >
                {
                  isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !testimonialsAvailable &&
                  !isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Bewertungen angelegt.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/bewertungsgruppen">Bewertungen</NavLink>
                    </p>
                  </div>
                }
                {
                  testimonialsAvailable &&
                  !isLoading &&
                  <>
                    <Row lgWidth="50%">
                      <SelectInput
                        label="Gruppe" name="testimonial_group_id" menuPosition="fixed" entity="testimonial_group"
                        getOptionLabel={item => (item.name).trim()}
                      />
                    </Row>
                  </>
                }
              </Accordion>
              {
                !liteRestrictions &&
                <Accordion
                  title="Unternehmensvorteile" active={useUsp}
                  onToggle={() => {
                    setUseUsp(!useUsp);
                  }}
                >
                  {
                    isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                  }
                  {
                    !uspAvailable &&
                    !isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Vorteile angelegt.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/usp-gruppen">Unternehmensvorteile</NavLink>
                    </p>
                  </div>
                  }
                  {
                    uspAvailable &&
                    !isLoading &&
                    <>
                      <Row lgWidth="50%">
                        <SelectInput
                          label="Gruppe" name="usp_group_id" menuPosition="fixed" entity="usp_group"
                          getOptionLabel={item => (item.name).trim()}
                        />
                      </Row>
                    </>
                  }
                </Accordion>
              }
              {
              	!liteRestrictions &&
              	<Accordion
              		title="FAQs" active={useFaq}
              		onToggle={() => {
              			setUseFaq(!useFaq);
              		}}
              	>
              		{
              			isLoading &&
              	<div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
              			<LoaderIcon/>
              			</div>
              		}
              		{
              			!faqAvailable &&
              			!isLoading &&
              	<div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
              		<p>
              			Du hast bisher keine FAQ-Gruppe angelegt.<br/>
              			Bitte gehe zu Angebotsdaten &gt; <NavLink to="/faq-gruppen">FAQ</NavLink>
              		</p>
              	</div>
              	}
              	{
              		faqAvailable &&
              		!isLoading &&
              		<>
              			<Row lgWidth="50%">
              				<SelectInput
              					label="FAQ Paket" name="faq_group_id" menuPosition="fixed" entity="faq_group"
              					getOptionLabel={item => (item.name).trim()}
              				/>
              			</Row>
              		</>
              	}
              	</Accordion>
              }
              <Accordion
                title="Galerien" active={usePictureGalleries}
                onToggle={() => {
                  setUsePictureGalleries(!usePictureGalleries);
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !pictureGalleryAvailable &&
                  !isLoading &&
                  <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Galerie angelegt.<br/>
                      Bitte gehe zu Angebotsdaten &gt; <NavLink to="/galerien">Galerien</NavLink>
                    </p>
                  </div>
                }
                {
                  pictureGalleryAvailable &&
                  !isLoading &&
                  <>
                    <Row lgWidth="50%">
                      <SelectInput
                        label="Galerien" name="picture_galleries" menuPosition="fixed" entity="picture_gallery"
                        getOptionLabel={item => (item.headline).trim()} isMulti isClearable
                      />
                    </Row>
                  </>
                }
              </Accordion>
              {
                templateContext.template && templateContext.template.exclusiveFeatures.includes('cta') &&
                <Accordion
                  title="Call-To-Action" active={useCta}
                  onToggle={() => {
                    setUseCta(!useCta);
                  }}
                >
                  {
                    isLoading &&
                    <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <LoaderIcon/>
                    </div>
                  }
                  {
                    !ctaAvailable &&
                    !isLoading &&
                    <div style={{height: "5rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <p>
                        Du hast bisher keine Call-To-Actions angelegt.<br/>
                        Bitte gehe zu Angebotsdaten &gt; <NavLink to="/cta">Call-To-Actions</NavLink>
                      </p>
                    </div>
                  }
                  {
                    ctaAvailable &&
                    !isLoading &&
                    <>
                      <Row lgWidth="50%">
                        <SelectInput
                          label="Call-To-Action" name="cta_id" menuPosition="fixed" entity="cta"
                          getOptionLabel={item => (item.headline).trim()}
                        />
                      </Row>
                    </>
                  }
                </Accordion>
              }
              {
                templateContext.template && templateContext.template.exclusiveFeatures.includes('logo_gallery') &&
                <Accordion
                  title="Logo-Galerien" active={useLogoGalleries}
                  onToggle={() => {setUseLogoGalleries(!useLogoGalleries);}}
                >
                  <SelectInput
                    label="Logo-Galerien" name="logo_galleries" menuPosition="fixed" entity="logo_gallery"
                    getOptionLabel={item => (item.headline).trim()} isMulti isClearable
                  />
                </Accordion>
              }
              <Accordion
                title="Weitere Dokumente" active={useDocuments}
                onToggle={() => {setUseDocuments(!useDocuments);}}
              >
                <SelectInput
                  label="Dokumente" name="documents" menuPosition="fixed" entity="document" isMulti isClearable
                  getOptionLabel={item => item.name}
                />
              </Accordion>
              <Accordion
                title="Texte" active={useTranslation}
                onToggle={() => {
                  setUseTranslation(!useTranslation);
                }}
              >
                {
                  isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <LoaderIcon/>
                  </div>
                }
                {
                  !translationAvailable &&
                  !isLoading &&
                  <div style={{height: "4.4rem", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <p>
                      Du hast bisher keine Textänderungen erstellt.<br/>
                      Bitte gehe zu Texte &gt; <NavLink to="/texte">Texte</NavLink>
                    </p>
                  </div>
                }
                {
                  translationAvailable &&
                  !isLoading &&
                  <Row lgWidth="50%">
                    <SelectInput
                      label="Texte" name="translation_id" menuPosition="fixed" entity="translation"
                      getOptionLabel={item => (item.name).trim()}
                    />
                  </Row>
                }
              </Accordion>
              <Accordion
                title="„Zum Angebot“ - Schaltfläche ausblenden?" active={offerButtonDisabled === 1}
                onToggle={() => setOfferButtonDisabled(1 - offerButtonDisabled)}
              ></Accordion>
              <ButtonBar>
                <Button
                  color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                  onClick={() => navigate(-1)}/>
                <Button icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                  text="Speichern" type="submit" disabled={isSubmitting}/>
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal
                title="Die Angebotsvorlage wurde erfolgreich erstellt" show={showSuccess}
                onDismiss={() => navigate('/angebot-vorlage')}
              >
                <ButtonBar>
                  <Button
                    type="reset" color="gray" text="Weitere Angebotsvorlage erstellen"
                    onClick={() => setOfferButtonDisabled(0)}
                  />
                  <Button text="Zurück zur Übersicht" onClick={() => navigate(-1)}/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
