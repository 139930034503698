import config from "../config.json";
import React, {useContext, useEffect, useState} from 'react';
import './MainMenu.scss';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  AwardIcon, GridIcon, FileIcon, FileTextIcon, LogoutIcon, UserIcon, VideoIcon, HelpCircleIcon, SettingsIcon, MenuIcon,
  MousePointerIcon, ApertureIcon, XIcon, MapIcon, FeatherIcon, ImageIcon, ImagesIcon, Star, TextIcon, Octagon,
  UsersIcon, CopyIcon, InfoIcon
} from './Icons';
import {ThemeContext} from "../contexts/ThemeContext";
import {localStorage, sessionStorage} from "../storage";
import {AuthContext} from "../contexts/AuthContext";
import MenuItem from "./MenuItem";
import {TemplateContext} from "../contexts/TemplateContext";

export default function MainMenu() {

  const authContext = useContext(AuthContext);
  const templateContext = useContext(TemplateContext);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const location = useLocation();
  useEffect(() => setExpanded(false), [location.pathname]);

  return (
    <nav className={'MainMenu' + (expanded ? ' expanded' : '')}>
      <div className="title">
        <ThemeContext.Consumer>
          {({theme}) => (
            <>
              {theme.logoUrl && <img src={theme.logoUrl} alt="Logo"/>}
              <span>{theme.company}</span>
            </>
          )}
        </ThemeContext.Consumer>
        <button onClick={() => setExpanded(!expanded)}>
          {expanded ? <XIcon/> : <MenuIcon/>}
        </button>
      </div>
      <ul>
        <li>
          <ul>
            <MenuItem to="/dashboard" icon={<GridIcon/>} label="Dashboard"/>
            <MenuItem to="/angebote" icon={<FileTextIcon/>} label="Angebote"/>
          </ul>
        </li>
        <li>
          <div>Angebotsdaten</div>
          <ul>
            <MenuItem to="/ansprechpartner" icon={<UserIcon/>} label="Ansprechpartner"/>
            {
              templateContext.template && templateContext.template.exclusiveFeatures.includes('team') &&
              <MenuItem to="/team" icon={<UsersIcon/>} label="Team" accessControl="team"/>
            }
            <MenuItem to="/videos-und-memos" icon={<VideoIcon/>} label="Videos &amp; Memos"/>
            <MenuItem to="/dokumente" icon={<FileIcon/>} label="Dokumente"/>
            <MenuItem to="/anschreiben" icon={<FeatherIcon/>} label="Anschreiben"/>
            <MenuItem to="/bewertungsgruppen" icon={<Star/>} label="Bewertungen"/>
            <MenuItem to="/titelbilder" icon={<ImageIcon/>} label="Titelbilder"/>
            <MenuItem to="/galerien" icon={<ImagesIcon/>} label="Galerien"/>
            {
              templateContext.template && templateContext.template.exclusiveFeatures.includes('logo_gallery') &&
              <MenuItem to="/logo-galerien" icon={<Octagon/>} label="Logo-Galerien"/>
            }
            <MenuItem to="/faq-gruppen" icon={<HelpCircleIcon/>} label="FAQ" liteRestrictions/>
            <MenuItem to="/usp-gruppen" icon={<AwardIcon/>} label="Unternehmensvorteile" liteRestrictions/>
            {
              templateContext.template && templateContext.template.exclusiveFeatures.includes('cta') &&
              <MenuItem to="/cta" icon={<MousePointerIcon/>} label="Call-To-Action" accessControl="cta"/>
            }
            <MenuItem to="/angebots-vorlagen" icon={<CopyIcon/>} label="Vorlagen" accessControl="offer_template"/>
          </ul>
        </li>
        <li>
          <div>Einstellungen</div>
          <ul>
            <MenuItem to="/unternehmensdaten" icon={<SettingsIcon/>} label="Unternehmensdaten"/>
            <MenuItem to="/design" icon={<ApertureIcon/>} label="Design"/>
            <MenuItem to="/texte" icon={<TextIcon/>} label="Texte"/>
            <MenuItem to="/benachrichtigungen" icon={<InfoIcon/>} label="Benachrichtigungen"/>
          </ul>
        </li>
        <li>
          <div>Mitgliedschaft</div>
          <ul>
            <MenuItem to="/gebuchtes-modell" icon={<MapIcon/>} label="Gebuchtes Modell"/>
          </ul>
        </li>
        {
          config.USE_AUTH &&
          <li>
            <ul>
              <li>
                <button onClick={() => {
                  localStorage.remove('authToken');
                  sessionStorage.remove('authToken');
                  authContext.setAuthToken('');
                  navigate('/anmeldung');
                }}>
                  <LogoutIcon/><span>Abmelden</span>
                </button>
              </li>
            </ul>
          </li>
        }
      </ul>
    </nav>
  );

}
