import {useState, useEffect, useRef} from "react";

export default function ContainerSize({...props}) {

  const elementRef = useRef();
  const [width, setWidth] = useState();

  const getContainerSize = () => {
    setWidth(elementRef.current.clientWidth);
  };

  const setClassForContainerSize = () => {
    let size = 'xxs';
    if (width >= 320) {
      size = 'xs';
    }
    if (width >= 576) {
      size = 'sm';
    }
    if (width >= 768) {
      size = 'md';
    }
    if (width >= 992) {
      size = 'lg';
    }
    return size;
  };

  useEffect(() => {
    getContainerSize();
    window.addEventListener('resize', getContainerSize);
    return () => {
      window.removeEventListener('resize', getContainerSize);
    };
  }, []);

  return (
    <div ref={elementRef} className={`container-size-${setClassForContainerSize()}`}>
      {props.children}
    </div>
  );
}
