import TrackStation from "../../../components/TrackStation";
import Track from "../../../components/Track";
import {Form, Formik} from "formik";
import {ChevronRightIcon} from "../../../components/Icons";
import Button from "../../../components/Button";
import {NavLink, useNavigate} from "react-router-dom";
import ButtonBar from "../../../components/ButtonBar";
import Row from "../../../components/Row";
import TextInput from "../../../components/TextInput";
import StageTitle from "../../../components/StageTitle";
import DropzoneInput from "../../../components/DropzoneInput";
import * as Yup from "yup";
import FormErrors from "../../../components/FormErrors";
import {useCallback, useContext, useEffect, useState} from "react";
import {OfferContext} from "../../../contexts/OfferContext";
import OfferFileOptionSelect from "../../../components/OfferFileOptionSelect";
import Select from "react-select";
import useCRUD from "../../../hooks/useCRUD";
import Modal from "../../../components/Modal";

export default function General() {

  const navigate = useNavigate();
  const offerContext = useContext(OfferContext);

  const validationSchema = Yup.object({
    client_company_name: Yup.string().required('Bitte den Firmennamen des Kunden eingeben'),
    number: Yup.string().required('Bitte die Angebotsnummer eingeben'),
    net_price: Yup.string().matches(/^\d+(,\d+)?$/, 'Die Angebotssumme bitte als Zahl eingeben'),
    offer_file_error: Yup.boolean()
      .test('file error', 'Für Käufe per digitaler Unterschrift muss ein Link angegeben werden', value => !value),
  });

  const onSubmit = (validate, setFieldValue, submitForm) => {
    let fileError = false;
    offerContext.offer.offer_files
      .filter(item => !offerContext.offer.delete_offer_files.includes(item.id))
      .every(file => {
        if (file.option !== null && (file.accept_via_link?.length === 0 && file.accept_via_e_mail === '0')) {
          fileError = true;
          return false;
        } else {
          return true;
        }
      });

    offerContext.offer.new_offer_files
      .every(file => {
        if (file.option !== null && (file.accept_via_link?.length === 0 && file.accept_via_e_mail === '0')) {
          fileError = true;
          return false;
        } else {
          return true;
        }
      });

    if (fileError) {
      setFieldValue('offer_file_error', true).then(() => {
        submitForm();
      });
    } else {
      setFieldValue('offer_file_error', false).then(() => {
        validate().then(() => navigate('../einleitung'));
      });
    }
  };

  const crud = useCRUD();
  const [selectOptions, setSelectOptions] = useState([]);
  const [showTemplateChange, setShowTemplateChange] = useState(false);
  const [offerTemplateSelected, setOfferTemplateSelected] = useState(offerContext.offer.offerTemplate ?? null);
  const [offerTemplates, setOfferTemplates] = useState([]);
  const [loading, setLoading] = useState(true);

  const changeOfferTemplate = useCallback((newTemplateObject) => {
    crud.data.read({entity: 'offer', id: newTemplateObject.value})
      .then(async offer => {

        let offerHasLogoGalleries = await crud.data.bulk.read({
          entity: 'offer_has_logo_gallery',
          page_size: 1000,
          filter: {property: 'offer_id', operator: 'equals', value: offer.id}
        }).then(bulkReadResult => bulkReadResult.items);

        let logoGalleries = [];
        if (offerHasLogoGalleries.length > 0) {
          logoGalleries = await crud.data.bulk.read({
            entity: 'logo_gallery',
            page_size: 1000,
            filter: {
              group: 'or',
              components: offerHasLogoGalleries.map(
                offerHasLogoGallery => ({
                  property: 'id',
                  operator: 'equals',
                  value: offerHasLogoGallery.logo_gallery_id
                })
              )
            }
          }).then(bulkReadResult => bulkReadResult.items.map(logoGallery => logoGallery.id));
        }

        let offerHasTeamMembers = await crud.data.bulk.read({
          entity: 'offer_has_team_member',
          page_size: 1000,
          filter: {property: 'offer_id', operator: 'equals', value: offer.id}
        }).then(bulkReadResult => bulkReadResult.items);

        let teamMembers = [];
        if (offerHasTeamMembers.length > 0) {
          teamMembers = await crud.data.bulk.read({
            entity: 'team_member',
            page_size: 1000,
            filter: {
              group: 'or',
              components: offerHasTeamMembers.map(
                offerHasTeamMember => ({
                  property: 'id', operator: 'equals', value: offerHasTeamMember.team_member_id
                })
              )
            }
          }).then(bulkReadResult => bulkReadResult.items.map(teamMember => teamMember.id));
        }

        let offerHasPictureGalleries = await crud.data.bulk.read({
          entity: 'offer_has_picture_gallery',
          page_size: 1000,
          filter: {property: 'offer_id', operator: 'equals', value: offer.id}
        }).then(bulkReadResult => bulkReadResult.items);

        let pictureGalleries = [];
        if (offerHasPictureGalleries.length > 0) {
          pictureGalleries = await crud.data.bulk.read({
            entity: 'picture_gallery',
            page_size: 1000,
            filter: {
              group: 'or',
              components: offerHasPictureGalleries.map(
                offerHasPictureGallery => ({
                  property: 'id', operator: 'equals', value: offerHasPictureGallery.picture_gallery_id
                })
              )
            }
          }).then(bulkReadResult => bulkReadResult.items.map(pictureGallery => pictureGallery.id));
        } else {
          if (offer.picture_gallery_id) {
            pictureGalleries.push(offer.picture_gallery_id);
          }
        }

        let offerHasOtherFiles = await crud.data.bulk.read({
          entity: 'offer_has_file',
          page_size: 1000,
          filter: {group: 'and', components: [
            {property: 'offer_id', operator: 'equals', value: offer.id},
            {property: 'type', operator: 'equals', value: 'other'}
          ]}
        }).then(bulkReadResult => bulkReadResult.items);

        let documents = [];
        if (offerHasOtherFiles.length > 0) {
          documents = await crud.data.bulk.read({
            entity: 'document',
            page_size: 1000,
            filter: {
              group: 'or',
              components: offerHasOtherFiles.map(
                offerHasFile => ({property: 'file_id', operator: 'equals', value: offerHasFile.file_id})
              )
            }
          }).then(bulkReadResult => bulkReadResult.items.map(document => document.id));
        }

        offerContext.setOffer({
          id: offerContext.offer.id ?? '',
          source_template_id: offer.id,
          number: offerContext.offer.number ?? '',
          title: offerContext.offer.title ?? '',
          client_company_name: offerContext.offer.client_company_name ?? '',
          client_contact_name: offerContext.offer.client_contact_name ?? '',
          opening: offerContext.offer.opening ?? '',
          letter: offer.letter ?? '',
          introduction_video_id: offer.introduction_video_id,
          offer_video_id: offer.offer_video_id,
          net_price: offerContext.offer.net_price ?? '',
          use_contact_person: !!offer.contact_person_id,
          contact_person_id: offer.contact_person_id,
          use_team_members: teamMembers.length > 0,
          team_members: teamMembers,
          use_header_image: !!offer.header_image_id,
          header_image_id: offer.header_image_id,
          use_usp: !!offer.usp_group_id,
          usp_group_id: offer.usp_group_id,
          use_faq: !!offer.faq_group_id,
          faq_group_id: offer.faq_group_id,
          use_picture_galleries: pictureGalleries.length > 0,
          picture_galleries: pictureGalleries,
          use_cta: !!offer.cta_id,
          cta_id: offer.cta_id,
          status: 'open',
          use_logo_galleries: logoGalleries.length > 0,
          logo_galleries: logoGalleries,
          new_offer_files: offerContext.offer.new_offer_files ?? [],
          offer_files: offerContext.offer.offer_files ?? [],
          delete_offer_files: offerContext.offer.delete_offer_files ?? [],
          use_documents: documents.length > 0,
          use_testimonials: !!offer.testimonial_group_id,
          testimonial_group_id: offer.testimonial_group_id,
          translation_id: offer.translation_id,
          documents,
          offerTemplate : newTemplateObject,
          offer_button_disabled: parseInt(offer.offer_button_disabled) === 1 ? 1 : 0
        });
        setOfferTemplateSelected(newTemplateObject);
        setShowTemplateChange(false);

      });

  }, [crud.data, offerContext]);

  useEffect(() => {
    setLoading(true);
    crud.data.bulk.read({
      entity: "offer",
      page_size: 1000,
      filter: {
        property: 'is_template',
        operator: 'equals',
        value: '1'
      }
    })
      .then(readOfferTemplates => {
        let options = [];
        readOfferTemplates.items.forEach(async item => {
          options.push({value: item.id, label: item.title});
        });

        setSelectOptions(options);
        setOfferTemplates(readOfferTemplates.items);
      })
      .finally(() => setLoading(false));
  }, [crud.data]);

  useEffect(() => {
    if (!loading && offerContext.offer.offerTemplate && !offerContext.offer.source_template_id) {
      changeOfferTemplate(offerContext.offer.offerTemplate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      <Track>
        <TrackStation active={true}>Allgemeine Angaben</TrackStation>
        <TrackStation link="/angebot/neu/einleitung">Einleitung</TrackStation>
        <TrackStation link="/angebot/neu/angebots-erlaeuterung">Angebots-Erläuterung</TrackStation>
        <TrackStation link="/angebot/neu/elemente">Elemente</TrackStation>
      </Track>
      <Formik
        initialValues={{
          client_company_name: offerContext.offer.client_company_name ?? '',
          client_contact_name: offerContext.offer.client_contact_name ?? '',
          number: offerContext.offer.number ?? '',
          net_price: offerContext.offer.net_price ?? '',
          new_offer_files: offerContext.offer.new_offer_files ?? '',
          offer_files: offerContext.offer.offer_files ?? [],
          delete_offer_files: offerContext.offer.delete_offer_files,
          offer_file_error: false
        }}
        validationSchema={validationSchema} onSubmit={() => {}}
        validate={values => offerContext.setOffer({...offerContext.offer, ...values})}
      >
        {({touched, errors, setFieldValue, values, validateForm, submitForm}) => (
          <Form className="mt-1-75">
            <StageTitle>Wähle deine Vorlage</StageTitle>
            {!loading && offerTemplates.length > 0 ?
              <Select
                options={selectOptions}
                inputValue={''}
                onChange={e => {
                  if (offerTemplateSelected) {
                    e.value !== offerTemplateSelected.value ? setShowTemplateChange(e) : setShowTemplateChange(false);
                  } else {
                    setShowTemplateChange(e);
                  }
                }}
                onInputChange={() => {}}
                onMenuClose={() => {}}
                onMenuOpen={() => {}}
                placeholder={offerTemplateSelected ? offerTemplateSelected.label : <div>Auswählen...</div>}
              /> :
              <p>Bitte erstellen Sie zuerst eine <NavLink to="/angebots-vorlagen">Angebotsvorlage</NavLink> um diese
                Option nutzen zu können</p>}
            <StageTitle className="mt-1-75">Dein Kunde</StageTitle>
            <Row>
              <TextInput
                name="client_company_name" label="Firmenname deines Kunden*" placeholder="Bsp. vyn Marketing GmbH"
              />
              <TextInput name="client_contact_name" label="Name deines Kunden" placeholder="Bsp. Sascha Weinrich"/>
            </Row>
            <hr/>
            <StageTitle>Angebote</StageTitle>
            <DropzoneInput
              name="new_offer_files" multiple={true} label="Angebot(e)"
              displayFiles={(offerFile, onRemove) => <OfferFileOptionSelect
                offerFile={offerFile} onRemove={onRemove} name="new_offer_files"
              />}
            />
            {
              offerContext.offer.offer_files?.map(
                (offerHasFile, index) => values.delete_offer_files.includes(offerHasFile.id) ? null : (
                  <div key={index} className="mt-1">
                    <OfferFileOptionSelect
                      offerFile={offerHasFile} name="offer_files"
                      label={
                        <a href={offerHasFile.file.url} target="_blank" rel="noreferrer">
                          {offerHasFile.file.filename}
                        </a>
                      }
                      onRemove={() => {
                        setFieldValue('delete_offer_files', [...values.delete_offer_files, offerHasFile.id]);
                      }}
                    />
                  </div>
                )
              )
            }
            <Row className="mt-1-75">
              <TextInput name="number" label="Angebotsnummer*" placeholder="Bsp. A05241"/>
              <TextInput name="net_price" label="Angebotssumme (netto)" placeholder="Bsp. 1500"/>
            </Row>
            <ButtonBar>
              <Button icon={<ChevronRightIcon/>} text="Weiter" type="button"
                onClick={() => onSubmit(validateForm, setFieldValue, submitForm)}
              />
            </ButtonBar>
            <FormErrors touched={touched} errors={errors}/>
          </Form>
        )}
      </Formik>
      <Modal
        title="Änderung der Vorlage" show={showTemplateChange}
        onDismiss={() => setShowTemplateChange(false)}
      >
        <p>Ein Ändern der Vorlage überschreibt die die bisherigen Optionen im Angebot.</p>
        <p>Soll das Angebot an die Vorlage angepasst werden?</p>
        <ButtonBar>
          <Button type="reset" color="gray" text="Abbrechen"
            onClick={() => setShowTemplateChange(false)}/>
          <Button text="Vorlage Anwenden" onClick={() => changeOfferTemplate(showTemplateChange)}/>
        </ButtonBar>
      </Modal>
    </>
  );
}
