import '../galerie-eintrag/galerie.scss';
import TitleHeading from "../../components/TitleHeading";
import Title from "../../components/Title";
import Stage from "../../components/Stage";
import ButtonLink from "../../components/ButtonLink";
import {
  ChevronLeftIcon,
  EditIcon,
  LoaderIcon,
  MicIcon,
  PlusCircleIcon,
  TrashIcon,
  VideoIcon
} from "../../components/Icons";
import ButtonBar from "../../components/ButtonBar";
import {NavLink, useParams} from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import React, {useEffect, useMemo, useState} from "react";
import useCRUD from "../../hooks/useCRUD";
import ErrorModal from "../../components/ErrorModal";
import IconWithText from "../../components/IconWithText";
import StageTitle from "../../components/StageTitle";
import Column from "../../components/Column";
import RowActionLink from "../../components/RowActionLink";
import Table from "../../components/Table";
import RowActionButton from "../../components/RowActionButton";
import Modal from "../../components/Modal";
import Button from "../../components/Button";

export default function Details() {

  const crud = useCRUD();
  const params = useParams();
  const [errors, setErrors] = useState([]);
  const [item, setItem] = useState(null);
  const [tableKey, setTableKey] = useState(1);
  const [loading, setLoading] = useState(true);

  const [deletePictureGalleryItem, setDeletePictureGalleryItem] = useState(null);
  const doDelete = () => {
    crud.data.delete({'entity': 'picture_gallery_item', id: deletePictureGalleryItem.id})
      .then(() => {
        setDeletePictureGalleryItem(null);
        setTableKey(tableKey => tableKey + 1);
      })
      .catch(errors => setErrors(errors));
  };

  useEffect(() => {
    setLoading(true);
    crud.data.read({entity: 'picture_gallery', id: parseInt(params.id)})
      .then(result => setItem(result))
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud.data, crud.filter, params.id]);

  return (
    <main>
      <Title>
        <TitleHeading>Galerien</TitleHeading>
        <Breadcrumb>
          <NavLink to="/galerien">Galerien</NavLink>
          {item && item.headline}
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {
          !loading &&
          <StageTitle>
            {item.headline}
            <NavLink to={'/galerie/' + item.id + '/bearbeiten'}><EditIcon/></NavLink>
          </StageTitle>
        }
        <Table
          name="picture_gallery_item" entity="picture_gallery_item" key={tableKey} draggable
          expand={useMemo(() => ['image:file', 'video'], [])}
          filter={useMemo(() => crud.filter.equals('picture_gallery_id', params.id), [crud.filter, params.id])}
        >
          <Column
            title="Bild" type="image" property="image" noSort
            transform={(image, pictureGalleryItem) =>
              <NavLink to={`/galerie-eintrag/${pictureGalleryItem.id}/bearbeiten`}>
                {
                  pictureGalleryItem.image_id ?
                    <img src={image.url} alt=""/> :
                    (pictureGalleryItem.video?.type === 'memo' ? MicIcon() : VideoIcon())
                }
              </NavLink>
            }
          />
          <Column
            title="Text" property="text" style={{width: '100%'}}
            transform={
              (text, pictureGalleryItem) =>
                <NavLink to={`/galerie-eintrag/${pictureGalleryItem.id}/bearbeiten`}>{text}</NavLink>}
          />
          <Column type="actions">
            <RowActionLink to={row => `/galerie-eintrag/${row.id}/bearbeiten`} color="blue" title="Bearbeiten">
              <EditIcon/>
            </RowActionLink>
            <RowActionButton color="red" title="Löschen" onClick={setDeletePictureGalleryItem}>
              <TrashIcon/>
            </RowActionButton>
          </Column>
        </Table>
        <ButtonBar>
          <ButtonLink to="/galerien" text="Zurück" icon={<ChevronLeftIcon/>} color="gray" iconPosition="left"/>
          {
            item &&
            <ButtonLink to={`/galerie-eintrag/${item.id}/neu`} text="Hinzufügen" icon={<PlusCircleIcon/>}/>
          }
        </ButtonBar>
      </Stage>
      {
        deletePictureGalleryItem &&
        <Modal title="Löschen bestätigen" onDismiss={() => setDeletePictureGalleryItem(null)}>
          <p>Möchten Sie den Galerieeintrag „{deletePictureGalleryItem.text}“ wirklich löschen?</p>
          <ButtonBar>
            <Button text="Behalten" onClick={() => setDeletePictureGalleryItem(null)}/>
            <Button color="red" text="Löschen" icon={<TrashIcon/>} onClick={doDelete}/>
          </ButtonBar>
        </Modal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </main>
  );

}
