import {Navigate, Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import Person from "./person";
import Company from "./company";
import Finish from "./finish";
import React, {useContext, useEffect, useState} from "react";
import useBL from "../../hooks/useBL";
import {AuthContext} from "../../contexts/AuthContext";
import ErrorModal from "../../components/ErrorModal";
import PublicModal from "../../components/PublicModal";

export default function Register() {

  const [registrationData, setRegistrationData] = useState({
    email: '',
    password: '',
    subscription_plan: '',
    tenant: '',
    tokenReference: ''
  });

  function Initialize() {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
      let next = '/';
      const subscription_plan = location.pathname.split('/').reverse()[0];
      if (['lite', 'pro'].indexOf(subscription_plan) >= 0) {
        setRegistrationData({...registrationData, subscription_plan});
        next = '../person';
      }
      navigate(next);
    }, [location.pathname, navigate]);
  }

  function RedeemAuthTokenReference() {
    const params = useParams();
    const bl = useBL();
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const authContext = useContext(AuthContext);
    useEffect(() => {
      bl.redeem_token_reference({token_reference: params.tokenReference})
        .then(authToken => {
          authContext.setAuthToken(authToken);
          navigate('/dashboard');
        })
        .catch(errors => setErrors(errors));
    }, [authContext, bl, navigate, params.tokenReference]);
    return (
      <PublicModal closer={false}>
        <ErrorModal errors={errors} onDismiss={() => navigate(-1)}/>
      </PublicModal>
    );
  }

  function Ensure({condition, children}) {
    return condition(registrationData) ? children : <Navigate to="/"/>;
  }

  return (
    <Routes>
      <Route path="f34e04bc/lite" element={<Initialize/>}/>
      <Route path="5e1f61d3/pro" element={<Initialize/>}/>
      <Route path="person" element={
        <Ensure condition={data => data.subscription_plan}>
          <Person
            registrationData={registrationData}
            setRegistrationData={values => setRegistrationData({...registrationData, ...values})}
          />
        </Ensure>
      }/>
      <Route path="unternehmen" element={
        <Ensure condition={data => data.email && data.password}>
          <Company
            registrationData={registrationData}
            setRegistrationData={values => setRegistrationData({...registrationData, ...values})}
          />
        </Ensure>
      }
      />
      <Route path="fertig" element={<Finish registrationData={registrationData}/>}/>
      <Route path="fertig/:tokenReference" element={<RedeemAuthTokenReference/>}/>
    </Routes>
  );

}
