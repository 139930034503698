import './Video.scss';
import React, {useCallback, useEffect, useRef, useState} from "react";
import ContainerSize from "./ContainerSize";
import {NavLink} from "react-router-dom";
import {DownloadIcon, EditIcon, TrashIcon} from "./Icons";
import Modal from "./Modal";
import ButtonBar from "./ButtonBar";
import Button from "./Button";
import useCRUD from "../hooks/useCRUD";
import ErrorModal from "./ErrorModal";
import {MemoImage} from "../images/MemoImage";
import ApiVideoPlayer from "@api.video/react-player";

export default function Video({setVideoDuration, videoProps, deleteFunction, editAble, ...props}) {

  const crud = useCRUD();
  const videoRef = useRef();

  const player = videoProps.api_video_id !== undefined && videoProps.api_video_id !== null ? 'api.video' : 'html5';
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(props?.muted ? 0 : 1);
  const [tempVolume, setTempVolume] = useState(props?.muted ? 0 : 1);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [settingsOverlay, setSettingsOverlay] = useState(false);
  const [errors, setErrors] = useState([]);
  const [deleteVideo, setDeleteVideo] = useState();
  const [videoIsProcessing, setVideoIsProcessing] = useState(
    new Date(new Date(videoProps.created_at).getTime() + 20000).getTime() >= new Date(Date.now()).getTime()
  );

  const calculateMediaDuration = useCallback(media => {
    return new Promise(resolve => {
      media.onloadedmetadata = () => {
        media.currentTime = Number.MAX_SAFE_INTEGER;
        media.ontimeupdate = () => {
          media.ontimeupdate = () => {
          };
          // setting player currentTime back to 0 can be buggy too, set it first to .1 sec
          media.currentTime = 0.1;
          media.currentTime = 0;
          resolve(media.duration);
        };
      };
    });
  }, []);

  useEffect(() => {
    if (player === 'api.video') {
      videoRef.current?.playerSdk.getDuration(duration => {
        if (setVideoDuration) {
          setVideoDuration(duration);
        }
        setDuration(duration);
      });
    } else {
      if (videoRef.current) {
        calculateMediaDuration(videoRef.current).then(duration => {
          if (setVideoDuration) {
            setVideoDuration(duration);
          }
          setDuration(duration);
        });
      }
    }
    if (videoIsProcessing) {
      setTimeout(() => {
        setVideoIsProcessing(false);
      }, 20000);
    }
  }, [calculateMediaDuration, player, setVideoDuration, videoIsProcessing]);

  let interval = null;
  const togglePlayPause = forcePlaying => {
    if (forcePlaying === true || (typeof forcePlaying === 'undefined' && !playing)) {
      setPlaying(true);
      videoRef.current?.play();

      interval = setInterval(function () {
        if (player === 'api.video') {
          videoRef.current?.playerSdk.getCurrentTime(currentTime => setCurrentTime(currentTime));
        } else {
          setCurrentTime(videoRef.current?.currentTime);
        }
      }, 42);
    } else {
      setPlaying(false);
      videoRef.current?.pause();
      interval && clearInterval(interval);
    }
  };

  const handleCurrentTimeChange = (event) => {
    setCurrentTime(event.target.value);
    if (player === 'api.video') {
      videoRef.current?.playerSdk.setCurrentTime(event.target.value);
    } else {
      videoRef.current.currentTime = event.target.value;
    }
  };

  const handleVolumeChange = (event) => {
    setVolume(event.target.value);
    videoRef.current?.playerSdk.setVolume(event.target.value);
  };

  const handleVolumeMute = () => {
    if (volume > 0) {
      setTempVolume(volume);
      setVolume(0);
      if (player === 'api.video') {
        videoRef.current?.playerSdk.setVolume(0);
      } else {
        videoRef.current.volume = 0;
      }
    } else {
      setVolume(tempVolume);
      if (player === 'api.video') {
        videoRef.current?.playerSdk.setVolume(1);
      } else {
        videoRef.current.volume = 1;
      }
      videoRef.current.muted = false;
    }
  };

  const handlePlaybackRateChange = (speed) => {
    setPlaybackRate(speed);
    if (player === 'api.video') {
      videoRef.current?.playerSdk.setPlaybackRate(speed);
    } else {
      videoRef.current.playbackRate = speed;
    }
    handleSettingsOverlay();
  };

  const handleSettingsOverlay = () => {
    setSettingsOverlay(current => !current);
  };

  const doDelete = () => {
    crud.data.delete({'entity': 'video', id: deleteVideo})
      .then(() => {
        setDeleteVideo(null);
        deleteFunction();
      })
      .catch(errors => setErrors(errors));
  };

  return (
    <ContainerSize>
      <div className={'Video' + (videoProps ? ' info-container' : '')}>
        <div className="videoContainer">
          {
            videoProps && player === 'api.video' && !videoIsProcessing &&
            <ApiVideoPlayer
              ref={videoRef}
              video={{id: videoProps.api_video_id}}
              onEnded={() => togglePlayPause(false)}
              chromeless={true}
              videoStyleObjectFit="cover"
            />
          }
          {
            videoProps && player === 'html5' &&
            <video
              ref={videoRef}
              onEnded={() => togglePlayPause(false)}
              {...props}
            >
              <source src={videoProps.url ?? videoProps.file.url}/>
            </video>
          }
          {
            videoProps && videoProps.type === "memo" &&
            <>
              <div className="memo-image">
                <MemoImage playAnimation={playing}/>
              </div>
            </>
          }
          {
            videoIsProcessing &&
            <>
              <div></div>
              <div className="videoIsProcessingOverlay">
                <div>
                Video wird verarbeitet
                </div>
              </div>
            </>
          }
          {
            !videoIsProcessing &&
            <div className="videoOverlay" onClick={() => togglePlayPause()}>
              {!playing && <><div/><div/></>}
            </div>
          }
        </div>
        <div className="controlsContainer">
          <div className="controls" onClick={() => togglePlayPause()}>
            {
              playing ? <div className="pause" /> : <div className="play" />
            }
          </div>
          <div className="progressbarContainer">
            <input
              className="progressbar" type="range" min="0" max={duration} step="any" value={currentTime}
              onChange={handleCurrentTimeChange} />
          </div>
          <div className="timeContainer">
            <span className="time">
              {
                `${Math.floor(currentTime / 60)}:${("0" + Math.floor(currentTime % 60)).slice(-2)}
                 / 
                ${Math.floor(duration / 60)}:${("0" + Math.floor(duration % 60)).slice(-2)}`
              }
            </span>
          </div>
          <div className="volumeContainer">
            <i className={`fa ${
              volume > 0 ? (volume > 0.5 ? 'fa-volume-up' : "fa-volume-down") : 'fa-volume-off'}`
            } onClick={handleVolumeMute} />
            <input
              className="volumeBar" type="range" min="0" max="1" step="0.01" value={volume}
              onChange={handleVolumeChange} />
          </div>
          <div className="playbackRateWrapper">
            <div className="playbackRateContainer">
              <div className="playbackRateIcon" onClick={handleSettingsOverlay}>
                {String(playbackRate).replace('.', ',')}x
              </div>
              {settingsOverlay && (<div className="playbackRate">
                <span>Geschwindigkeit</span>
                <hr/>
                <ul>
                  {[0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map((speed, index) => {
                    return <li
                      key={index} className={playbackRate === speed ? 'active' : ''}
                      onClick={() => handlePlaybackRateChange(speed)}>{String(speed).replace('.', ',')}
                    </li>;
                  })}
                </ul>
              </div>)
              }
            </div>
          </div>
        </div>
        {
          videoProps &&
          editAble &&
          <div className="info-container">
            <div className="video-name">
              <p title={videoProps.name}>{videoProps.name}</p>
            </div>
            <div className="video-actions">
              {
                !videoIsProcessing &&
                <div title="Download" className="download-button">
                  <a
                    href={player === 'api.video'
                      ? `https://vod.api.video/vod/${videoProps.api_video_id}/mp4/source.mp4?dl=1`
                      : `${videoProps.file.url}?dl=1`}
                    target="_blank"
                    rel="noreferrer"
                    download
                  >
                    <DownloadIcon/>
                  </a>
                </div>
              }
              <NavLink title="Bearbeiten" className="edit-button" to={`/video/${videoProps.id}/bearbeiten`}>
                <EditIcon/>
              </NavLink>
              <div title="Löschen" className="delete-button" onClick={() => setDeleteVideo(videoProps.id)}>
                <TrashIcon/>
              </div>
            </div>
          </div>
        }
        {
          deleteVideo &&
          videoProps &&
          <Modal title="Löschen bestätigen" onDismiss={() => setDeleteVideo(null)}>
            <p>Möchten Sie „{videoProps.name}“ wirklich löschen?</p>
            <ButtonBar>
              <Button text="Behalten" onClick={() => setDeleteVideo(null)}/>
              <Button color="red" text="Löschen" icon={<TrashIcon/>} onClick={doDelete}/>
            </ButtonBar>
          </Modal>
        }
      </div>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </ContainerSize>
  );

}
