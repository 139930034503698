import TitleHeading from "../components/TitleHeading";
import Breadcrumb from "../components/Breadcrumb";
import {NavLink, useParams} from "react-router-dom";
import Title from "../components/Title";
import Stage from "../components/Stage";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../components/Icons";
import Button from "../components/Button";
import ButtonBar from "../components/ButtonBar";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../components/TextInput";
import FormErrors from "../components/FormErrors";
import ErrorModal from "../components/ErrorModal";
import {useState} from "react";
import Row from "../components/Row";
import Modal from "../components/Modal";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import IconWithText from "../components/IconWithText";
import IconInput from "../components/IconInput";
import icons from "../resources/icons";
import useCRUD from "../hooks/useCRUD";

export default function EditUsp() {

  const crud = useCRUD();
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [uspGroup, setUspGroup] = useState(null);
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    Promise.all([
      crud.data.read({entity: 'usp_group', id: parseInt(params.usp_group_id)})
        .then(result => setUspGroup({...result}))
        .catch(errors => setErrors(errors)),
      crud.data.read({entity: 'usp', id: parseInt(params.usp_id)})
        .then(result => setItem({...result}))
        .catch(errors => setErrors(errors))
    ]).finally(() => setLoading(false));
  }, [crud.data, params.usp_group_id, params.usp_id]);

  const validationSchema = Yup.object({
    headline: Yup.string().required('Bitte geben Sie eine Überschrift ein'),
    icon: Yup.mixed().oneOf(icons(), 'Bitte wählen Sie ein Icons aus')
  });

  const submit = (values, {setSubmitting}) => {
    crud.data.update({entity: 'usp', id: params.usp_id, update: {...values}})
      .then(() => {
        item.name = values.name;
        setItem({...item});
        setShowSuccess(true);
      })
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Vorteil bearbeiten</TitleHeading>
        <Breadcrumb>
          <NavLink to="/usp-gruppen">Unternehmensvorteile</NavLink>
          {uspGroup && <NavLink to={`/usp-gruppen/${uspGroup.id}/`}>{uspGroup.name}</NavLink>}
          Bearbeiten
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
          <Formik
            initialValues={{headline: item.headline, sub_headline: item.sub_headline, icon: item.icon}}
            validationSchema={validationSchema} onSubmit={submit}
            onReset={() => setShowSuccess(false)}
          >
            {({isSubmitting, touched, errors, setFieldValue}) => (
              <Form>
                <Row>
                  <TextInput name="headline" label="Überschrift*"/>
                  <TextInput name="sub_headline" label="Unterüberschrift" placeholder="Bsp. In Allem"/>
                </Row>
                <Row>
                  <IconInput setFieldValue={setFieldValue}/>
                </Row>
                <ButtonBar>
                  <Button
                    color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                    onClick={() => navigate(-1)}
                  />
                  <Button
                    icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>} text="Speichern" type="submit"
                    disabled={isSubmitting}
                  />
                </ButtonBar>
                <FormErrors touched={touched} errors={errors}/>
                <Modal
                  title="Der Vorteil wurde erfolgreich gespeichert" show={showSuccess}
                  onDismiss={() => navigate(-1, {replace: true})}
                >
                  <ButtonBar>
                    <Button type="button" text="Ok" onClick={() => navigate(-1, {replace: true})}/>
                  </ButtonBar>
                </Modal>
              </Form>
            )}
          </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
