import Title from "../../components/Title";
import TitleHeading from "../../components/TitleHeading";
import Stage from "../../components/Stage";
import Table from "../../components/Table";
import Column from "../../components/Column";
import ButtonBar from "../../components/ButtonBar";
import ButtonLink from "../../components/ButtonLink";
import {EditIcon, PlusCircleIcon, TrashIcon} from "../../components/Icons";
import {useEffect, useState} from "react";
import useCRUD from "../../hooks/useCRUD";
import ErrorModal from "../../components/ErrorModal";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import RowActionLink from "../../components/RowActionLink";
import RowActionButton from "../../components/RowActionButton";
import TextTeaser from "../../components/TextTeaser";

export default function LetterList () {

  const crud = useCRUD();

  const [errors, setErrors] = useState([]);
  const [tableKey, setTableKey] = useState(1);
  const [deleteLetter, setDeleteLetter] = useState(null);
  const [teaserWidth, setTeaserWidth] = useState();

  const doDelete = () => {
    crud.data.delete({'entity': 'letter', id: deleteLetter.id})
      .then(() => {
        setDeleteLetter(null);
        setTableKey(tableKey + 1);
      })
      .catch(errors => setErrors(errors));
  };

  useEffect(() => {
    const handleResize = () => setTeaserWidth(window.innerWidth / 4);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const transformContent = content => <TextTeaser width={teaserWidth}>{content}</TextTeaser>;

  return (
    <main>
      <Title>
        <TitleHeading>Anschreiben</TitleHeading>
      </Title>
      <Stage>
        <Table name="letters" entity="letter" defaultOrderBy="title" key={tableKey}>
          <Column title="Titel" property="title"/>
          <Column title="Vorschau" property="content" transform={transformContent}/>
          <Column type="actions">
            <RowActionLink to={(row) => row.id + '/'} color="blue" title="Bearbeiten">
              <EditIcon/>
            </RowActionLink>
            <RowActionButton color="red" title="Löschen" onClick={setDeleteLetter}><TrashIcon/></RowActionButton>
          </Column>
        </Table>
        <ButtonBar>
          <ButtonLink to="/anschreiben/neu" text="Hinzufügen" icon={<PlusCircleIcon/>}/>
        </ButtonBar>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        {
          deleteLetter &&
          <Modal title="Löschen bestätigen" onDismiss={() => setDeleteLetter(null)}>
            <p>Möchten Sie das Anschreiben „{deleteLetter.title}“ wirklich löschen?</p>
            <ButtonBar>
              <Button text="Behalten" onClick={() => setDeleteLetter(null)}/>
              <Button color="red" text="Löschen" icon={<TrashIcon/>} onClick={doDelete}/>
            </ButtonBar>
          </Modal>
        }
      </Stage>
    </main>
  );

}
