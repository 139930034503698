import './logo-galerie.scss';
import TitleHeading from "../../components/TitleHeading";
import Breadcrumb from "../../components/Breadcrumb";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import Title from "../../components/Title";
import Stage from "../../components/Stage";
import {useEffect, useState} from "react";
import useCRUD from "../../hooks/useCRUD";
import ErrorModal from "../../components/ErrorModal";
import IconWithText from "../../components/IconWithText";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon, TrashIcon} from "../../components/Icons";
import {Form, Formik} from "formik";
import TextInput from "../../components/TextInput";
import DropzoneInput from "../../components/DropzoneInput";
import ButtonBar from "../../components/ButtonBar";
import ButtonLink from "../../components/ButtonLink";
import Button from "../../components/Button";
import FormErrors from "../../components/FormErrors";
import Modal from "../../components/Modal";
import * as Yup from "yup";
import Row from "../../components/Row";
import ImageInput from "../../components/ImageInput";
import RowActionButton from "../../components/RowActionButton";
import useFiles from "../../hooks/useFiles";

export default function Bearbeiten() {

  const crud = useCRUD();
  const files = useFiles();
  const params = useParams();
  const navigate = useNavigate();
  const [reload, setReload] = useState(0);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keepImage, setKeepImage] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => setShowLoading(true), 1000);
    crud.data.read({entity: 'logo_gallery_item', id: parseInt(params.id)})
      .then(async result => {
        setLogo((await crud.expand([result], ['logo_gallery', 'image:file']))[0]);
        setKeepImage(true);
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        setLoading(false) || setShowLoading(false) || clearTimeout(timeout);
      });
    return () => clearTimeout(timeout);
  }, [crud, params.id, reload]);

  const validationSchema = Yup.object({
    url: Yup.string().url('Bitte eine URL eingeben'),
    image: Yup.mixed()
      .test('picture test', 'Bitte ein Bild hochladen', value => value || (logo.image_id && keepImage))
      .test(
        'fileType', 'Erlaubte Bildformate: PNG, JPG, GIF, SVG und WEBP',
        value => !value || ['image/gif', 'image/png', 'image/jpeg', 'image/svg+xml', 'image/webp'].includes(value.type)
      )
  });

  const submit = (values, {setSubmitting}) => {
    const save = image_id => {
      const update = {url: values.url};
      if (image_id) {
        update.image_id = image_id;
      }
      crud.data.update({entity: 'logo_gallery_item', id: parseInt(params.id), update})
        .then(() => setReload(x => x + 1))
        .catch(errors => setErrors(errors))
        .finally(() => setShowSuccess(true) || setSubmitting(false));
    };
    if (values.image) {
      files.upload(values.image)
        .then(result => save(result.id))
        .catch(errors => setErrors(errors) || setSubmitting(false));
    } else {
      save(logo.image_id);
    }
  };

  return (
    <main>
      <Title>
        <TitleHeading>Eintrag bearbeiten</TitleHeading>
        <Breadcrumb>
          <NavLink to="/galerien">Galerien</NavLink>
          {
            logo &&
            <NavLink to={`/logo-galerie/${logo.logo_gallery_id}/details`}>
              {logo.logo_gallery.headline}
            </NavLink>
          }
          Eintrag bearbeiten
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && showLoading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {
          logo &&
          <Formik
            initialValues={{url: logo.url, image: null}}
            validationSchema={validationSchema}
            onSubmit={submit}
          >
            {({isSubmitting, touched, errors}) => (
              <Form>
                {
                  keepImage &&
                  <Row className="mb-1 logo-gallery-item" lgWidth="50%">
                    <ImageInput label="Bild*">
                      <RowActionButton title="Entfernen" color="red" onClick={() => setKeepImage(false)}
                        className="delete-button">
                        <TrashIcon/>
                      </RowActionButton>
                      <img src={logo.image.url} alt=""/>
                    </ImageInput>
                  </Row>
                }
                {
                  !keepImage &&
                  <Row className="mb-1">
                    <DropzoneInput
                      className="mt-1-75" name="image" label="Bild hochladen*"
                      infoText="Nur PNG-, JPG-, GIF-, SVG- oder WEBP-Dateien"
                    />
                  </Row>
                }
                <TextInput name="url" label="URL" placeholder="Bsp. https://dein-logo.de"/>
                <ButtonBar align="left">
                  <ButtonLink
                    to={`/logo-galerie/${logo.logo_gallery_id}/details`} color="gray"
                    icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                  />
                  <Button
                    icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                    text="Speichern" type="submit" disabled={isSubmitting}
                  />
                </ButtonBar>
                <FormErrors touched={touched} errors={errors}/>
                {
                  showSuccess &&
                  <Modal
                    title="Der Eintrag wurde erfolgreich gespeichert"
                    onDismiss={() => navigate(`/logo-galerie/${logo.logo_gallery_id}/details`)}
                  >
                    <ButtonBar>
                      <ButtonLink
                        to={`/logo-galerie/${logo.logo_gallery_id}/details`}
                        text="Zurück zur Logo-Galerie"
                      />
                    </ButtonBar>
                  </Modal>
                }
              </Form>
            )}
          </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
