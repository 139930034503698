import Widget from "./Widget";
import "./TeaserWidget.scss";

export default function TeaserWidget({linkTo, number, headline, text, cta, color}) {

  return (
    <Widget className={`TeaserWidget ${color}`} linkTo={linkTo}>
      <div className="widget-wrapper">
        <div className="number-wrapper">
          <p className="number">{number}</p>
          <span className="dot"/>
        </div>
        <div className="text-wrapper">
          {headline && <p className="headline">{headline}</p>}
          {text && <p className="text">{text}</p>}
          {cta && <p className="call-to-action">{cta}</p>}
        </div>
      </div>
    </Widget>
  );

}
