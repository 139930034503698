import './Button.scss';
import {Link} from "react-router-dom";

export default function ButtonLink({
  to, color = 'turquoise', icon, iconPosition = 'right', text, target = '_blank', onClick
}) {
  return (
    <>
      {
        !to.match(/^http/) &&
        <Link to={to} className={'Button ' + color} onClick={onClick}>
          {icon && iconPosition === 'left' && <span className="icon">{icon}</span>}
          <span className="text">{text}</span>
          {icon && iconPosition === 'right' && <span className="icon">{icon}</span>}
        </Link>
      }
      {
        to.match(/^http/) &&
        <a href={to} className={'Button ' + color} target={target} rel={target === '_blank' ? 'noreferrer' : null}>
          {icon && iconPosition === 'left' && <span className="icon">{icon}</span>}
          <span className="text">{text}</span>
          {icon && iconPosition === 'right' && <span className="icon">{icon}</span>}
        </a>
      }
    </>
  );
}
