import Title from "../../components/Title";
import TitleHeading from "../../components/TitleHeading";
import Stage from "../../components/Stage";
import {NavLink, useNavigate} from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import useCRUD from "../../hooks/useCRUD";
import {useState} from "react";
import * as Yup from "yup";
import ErrorModal from "../../components/ErrorModal";
import {Form, Formik} from "formik";
import Row from "../../components/Row";
import TextInput from "../../components/TextInput";
import ButtonBar from "../../components/ButtonBar";
import ButtonLink from "../../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import Button from "../../components/Button";
import FormErrors from "../../components/FormErrors";
import Modal from "../../components/Modal";

export default function Neu() {

  const crud = useCRUD();
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [createdId, setCreatedId] = useState();

  const validationSchema = Yup.object({
    headline: Yup.string().required('Bitte geben Sie eine Überschrift ein'),
  });

  const submit = (values, {setSubmitting}) => {
    crud.data.create({entity: 'logo_gallery', item: {...values}})
      .then(item => setShowSuccess(true) || setCreatedId(item.id))
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Logo-Galerie erstellen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/logo-galerien">Logo-Galerien</NavLink>
          Neu
        </Breadcrumb>
      </Title>
      <Stage>
        <Formik initialValues={{headline : ''}} validationSchema={validationSchema} onSubmit={submit}>
          {({isSubmitting, touched, errors}) => (
            <Form>
              <Row>
                <TextInput name="headline" label="Überschrift*" placeholder="Bsp. Meine Logo-Galerie"/>
              </Row>
              <ButtonBar>
                <ButtonLink
                  to="/logo-galerien" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                />
                <Button
                  icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>} text="Speichern" type="submit"
                  disabled={isSubmitting}
                />
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal
                title="Die Logo-Galerie wurde erfolgreich erstellt" show={showSuccess}
                onDismiss={() => navigate('/logo-galerien')}
              >
                <ButtonBar>
                  <ButtonLink color="gray" to={`/logo-galerie/${createdId}/details`} text="Jetzt Einträge hinzufügen"/>
                  <ButtonLink to="/logo-galerien" text="Zurück zur Übersicht"/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
      </Stage>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </main>
  );

}
