import './Badge.scss';

export default function Badge({color = 'turquoise', clickable, status, onClick, children}) {
  return (
    <span
      onClick={onClick} className={`Badge ${color} ` + (clickable ? 'clickable ' : '') + (status ? status + ' ' : '')}
    >
      {children}
    </span>
  );
}
