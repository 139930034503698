import TitleHeading from "../components/TitleHeading";
import Breadcrumb from "../components/Breadcrumb";
import {NavLink, useNavigate} from "react-router-dom";
import Title from "../components/Title";
import ButtonBar from "../components/ButtonBar";
import ButtonLink from "../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../components/Icons";
import Stage from "../components/Stage";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {useState} from "react";
import ErrorModal from "../components/ErrorModal";
import DropzoneInput from "../components/DropzoneInput";
import Button from "../components/Button";
import FormErrors from "../components/FormErrors";
import Modal from "../components/Modal";
import useCRUD from "../hooks/useCRUD";
import useFiles from "../hooks/useFiles";

export default function CreateDocument() {

  const crud = useCRUD();
  const files = useFiles();
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const initialValues = {
    documents: []
  };

  const validationSchema = Yup.object({
    documents: Yup.array()
      .min(1, 'Wählen Sie eine Datei aus')
      .max(5, 'Bitte nur fünf Dokumente auf einmal hochladen. Sie können im Nachgang weitere Dokumente hochladen.')
  });

  const submit = (values, {setSubmitting}) => {
    files.upload(values.documents)
      .then(results => {
        crud.data.bulk.create({
          entity: 'document', 
          items: results.map((result, index) => ({
            file_id: result.id, 
            name: values.documents[index].name
          }))
        })
          .then(() => setShowSuccess(true))
          .catch(errors => setErrors(errors))
          .finally(() => setSubmitting(false));
      })
      .catch(errors => {
        setErrors(errors);
        setSubmitting(false);
      });
  };

  return (
    <main>
      <Title>
        <TitleHeading>Dokument(e) hochladen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/dokumente">Dokumente</NavLink>
          Neu
        </Breadcrumb>
      </Title>
      <Stage>
        <Formik
          initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}
          onReset={() => setShowSuccess(false)}
        >
          {({isSubmitting, touched, errors}) => (
            <Form>
              <DropzoneInput name="documents" label="Dokument(e)" multiple/>
              <ButtonBar>
                <ButtonLink
                  to="/dokumente" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                />
                <Button
                  icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                  text="Speichern" type="submit" disabled={isSubmitting}
                />
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal
                title="Das Dokument wurde erfolgreich hochgeladen" show={showSuccess}
                onDismiss={() => navigate('/dokumente')}
              >
                <ButtonBar>
                  <Button type="reset" color="gray" text="Weitere Dokumente hochladen"/>
                  <ButtonLink to="/dokumente" text="Zurück zur Übersicht"/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
