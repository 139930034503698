import Title from '../../components/Title';
import TitleHeading from '../../components/TitleHeading';
import Stage from "../../components/Stage";
import ButtonBar from "../../components/ButtonBar";
import ButtonLink from "../../components/ButtonLink";
import {ChevronLeftIcon, LoaderIcon, TrashIcon} from "../../components/Icons";
import {useEffect, useState} from "react";
import ErrorModal from "../../components/ErrorModal";
import IconWithText from "../../components/IconWithText";
import {useNavigate, useParams} from "react-router-dom";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import useCRUD from "../../hooks/useCRUD";
import Image from "../../components/Image";

export default function HeaderImageDetails() {

  const crud = useCRUD();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [headerImages, setHeaderImages] = useState(null);
  const [file, setFile] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const doDelete = () => {
    setDeleting(true);
    crud.data.delete({'entity': 'header_image', id: headerImages.id})
      .then(() => {
        navigate('/titelbilder');
      })
      .catch(errors => setErrors(errors));
  };

  useEffect(() => {
    crud.data.read({entity: 'header_image', id: params.id})
      .then(headerImage => {
        setHeaderImages(headerImage);
        crud.data.read({entity: 'file', id: headerImage.file_id})
          .then(file => setFile(file))
          .catch(errors => setErrors(errors))
          .finally(() => setLoading(false));
      })
      .catch(errors => {
        setErrors(errors);
        setLoading(false);
      });
  }, [crud.data, params.id]);

  return (
    <main>
      <Title>
        <TitleHeading>Titelbilder</TitleHeading>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {
          !loading &&
          <Image details={true} href={file.url} onDelete={() => setShowDelete(true)} name={headerImages.name}>
            {file}
          </Image>
        }
        <ButtonBar align="left">
          <ButtonLink
            to="/titelbilder" text="Zurück" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left"
          />
        </ButtonBar>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        {
          showDelete &&
          <Modal title="Löschen bestätigen" onDismiss={() => setShowDelete(false)}>
            <p>Möchten Sie das Bild „{headerImages.name}“ wirklich löschen?</p>
            <ButtonBar>
              <Button text="Behalten" onClick={() => setShowDelete(false)}/>
              <Button
                color="red" text="Löschen" icon={deleting ? <LoaderIcon/> : <TrashIcon/>}
                onClick={doDelete}
              />
            </ButtonBar>
          </Modal>
        }
      </Stage>
    </main>
  );
}
