import './DonutChart.scss';
import React, {useEffect, useMemo, useRef, useState} from "react";
import useBL from "../hooks/useBL";
import {EmptyPackageIcon, LoaderIcon} from "./Icons";
import IconWithText from "./IconWithText";
import {useNavigate} from "react-router-dom";

export default function DonutChart({title, type, offerId, getFAQClicks, getDownloads}) {

  const bl = useBL();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [active, setActive] = useState();
  const labelRef = useRef();
  const navigate = useNavigate();

  const colors = ['purple', 'yellow', 'turquoise', 'red', 'green', 'blue', 'pink'];

  useEffect(() => {
    setData([]);
    setLoading(true);
    if (getFAQClicks) {
      bl.faq_statistics({offer_id: offerId})
        .then(data => setData(data))
        .finally(() => setLoading(false));
    } else if (getDownloads) {
      bl.download_statistics({offer_id: offerId})
        .then(data => setData(data))
        .finally(() => setLoading(false));
    } else {
      bl.event_statistics({offer_id: offerId, type})
        .then(data => setData(data))
        .finally(() => setLoading(false));
    }
  }, [bl, offerId, type, getFAQClicks, getDownloads]);

  const total = useMemo(() => {
    return data.map(item => item.events).reduce((a, b) => a + b, 0);
  }, [data]);

  const stops = useMemo(() => {
    let result = [0];
    data.forEach((item, index) => {
      result.push(result[index] + (item.events / total) * 360);
    });
    return result;
  }, [data, total]);

  function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  }

  function describeArc(x, y, radius, startAngle, endAngle) {
    if (startAngle === 0 && endAngle === 360) {
      return (
        `M ${x - radius}, ${y} ` +
        `a ${radius},${radius} 0 1,0 ${2 * radius},0 ` +
        `a ${radius},${radius} 0 1,0 ${-2 * radius},0`
      );
    }
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
    return [
      'M', start.x, start.y,
      'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(' ');
  }

  function setLabelPosition(event) {
    labelRef.current.style.left = (event.clientX + 5) + 'px';
    labelRef.current.style.top = (event.clientY - 20) + 'px';
  }

  return (
    <div className="DonutChart">
      {loading && <div className="overlay"><IconWithText><LoaderIcon/> Laden…</IconWithText></div>}
      {
        !loading && data.length === 0 &&
        <div className="overlay"><IconWithText><EmptyPackageIcon/> Keine {title || type} vorhanden.</IconWithText></div>
      }
      <div className="label" ref={labelRef}>
        {
          active >= 0 &&
          <div>
            {data[active].target}:&nbsp;
            {(100 * data[active].events / total).toLocaleString('de-DE', {maximumFractionDigits: 2})}% -&nbsp;
            {data[active].events} {title || type} -&nbsp;
            letzter Zugriff: {
              new Date(data[active].latest).toLocaleString(
                'de-DE', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'}
              )
            } Uhr
          </div>
        }
      </div>
      <div className="title">
        <div className="headline">{title || type}</div>
        {
          data.length &&
          <div className="period">
            <span>{
              data.map(item => item.first ? new Date(item.first) : null).reduce((a, b) => a && a < b ? a : b, null)
                .toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})
            }</span>
            <span>bis</span>
            <span>{
              data.map(item => item.latest ? new Date(item.latest) : null).reduce((a, b) => a && a > b ? a : b, null)
                .toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})
            }</span>
          </div>
        }
      </div>
      <div className="diagram">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" onMouseMove={setLabelPosition}>
          <g fill="none" strokeWidth="75">
            {stops.slice(1).map((stop, index) => (
              <path
                key={index} d={describeArc(150, 150, 112.5, stops[index], stop)}
                className={colors[index % colors.length].concat(data[index].target === 'FAQ' ? ' pointer' : '')}
                onMouseMove={() => setActive(index)}
                onMouseLeave={() => setActive(undefined)}
                onClick={() => {
                  if (data[index].target === 'FAQ') {
                    navigate('./faq');
                  }
                }}
              />
            ))}
          </g>
          {
            stops.length > 2 &&
            <g strokeWidth="2" stroke="white">
              {stops.slice(1).map((angle, index) => {
                const cartesian = polarToCartesian(150, 150, 150, angle);
                return <line key={index} x1="150" y1="150" x2={cartesian.x} y2={cartesian.y}/>;
              })}
            </g>
          }
        </svg>
        <ul>
          {data.map((item, index) => <li key={index} 
            className={colors[index % colors.length].concat(data[index].target === 'FAQ' ? ' pointer' : '')} 
            onClick={() => {
              if (data[index].target === 'FAQ') {
                navigate('./faq');
              }
            }}>
            {item.target}
          </li>)}
        </ul>
      </div>
    </div>
  );

}
