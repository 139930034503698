import './PublicModal.scss';

export default function PublicModal(props) {

  return (
    <div className="PublicModal">
      <div className="modal">
        <div className="logo">
          <img src={process.env.PUBLIC_URL + '/logo.svg'} alt="Sellestar-Logo"/>
          <div className="brand">Sellestar</div>
          <div className="claim">One Click Success</div>
        </div>
        <div className="content">{props.children}</div>
      </div>
    </div>
  );

}
