import TitleHeading from "../../components/TitleHeading";
import Breadcrumb from "../../components/Breadcrumb";
import {NavLink} from "react-router-dom";
import Title from "../../components/Title";
import Stage from "../../components/Stage";
import ButtonLink from "../../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import Button from "../../components/Button";
import ButtonBar from "../../components/ButtonBar";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../../components/TextInput";
import FormErrors from "../../components/FormErrors";
import ErrorModal from "../../components/ErrorModal";
import {useState} from "react";
import Row from "../../components/Row";
import Modal from "../../components/Modal";
import {useNavigate} from "react-router-dom";
import useCRUD from "../../hooks/useCRUD";

export default function BewertungsgruppeNeu() {

  const crud = useCRUD();
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required('Bitte geben Sie einen Namen ein'),
  });

  const submit = (values, {setSubmitting}) => {
    crud.data.create({entity: 'testimonial_group', item: {...values}})
      .then(() => setShowSuccess(true))
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Bewertungsgruppe erstellen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/bewertungsgruppen">Bewertungsgruppen</NavLink>
          Neu
        </Breadcrumb>
      </Title>
      <Stage>
        <Formik
          initialValues={{name: '', headline : ''}} validationSchema={validationSchema} onSubmit={submit}
          onReset={() => setShowSuccess(false)}
        >
          {({isSubmitting, touched, errors}) => (
            <Form>
              <Row>
                <TextInput name="name" label="Name*" placeholder="Bsp. Neue Bewertungen"/>
              </Row>
              <Row>
                <TextInput
                  name="headline" label="Überschrift auf der späteren Angebotsseite"
                  placeholder="Bsp. Bewertungen unserer neusten Kunden"
                />
              </Row>
              <ButtonBar>
                <ButtonLink
                  to="/bewertungsgruppen" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                />
                <Button
                  icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                  text="Speichern" type="submit" disabled={isSubmitting}
                />
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal
                title="Die Bewertungsgruppe wurde erfolgreich erstellt" show={showSuccess}
                onDismiss={() => navigate('/bewertungsgruppen')}
              >
                <ButtonBar>
                  <Button type="reset" color="gray"  text="Weitere Bewertungsgruppe hinzufügen"/>
                  <ButtonLink to="/bewertungsgruppen" text="Zurück zur Übersicht"/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
