import Video from "../../../components/Video";
import Button from "../../../components/Button";
import ButtonLink from "../../../components/ButtonLink";
import ButtonBar from "../../../components/ButtonBar";
import ContainerSize from "../../../components/ContainerSize";
import {useContext, useState} from "react";
import Modal from "../../../components/Modal";
import useFiles from "../../../hooks/useFiles";
import useCRUD from "../../../hooks/useCRUD";
import {OfferContext} from "../../../contexts/OfferContext";
import {RecordingContext} from "../../../contexts/RecordingContext";
import {ChevronRightIcon, LoaderIcon} from "../../../components/Icons";
import useBL from "../../../hooks/useBL";
import Progress from "../../../components/Progress";
import {VideoUploader} from "@api.video/video-uploader";

export default function Preview(props) {

  const files = useFiles();
  const crud = useCRUD();
  const bl = useBL();
  const offerContext = useContext(OfferContext);
  const recordingContext = useContext(RecordingContext);

  const [duration, setDuration] = useState(0);
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const generateVideoName = () => {
    return (
      {screencast: 'Bildschirmaufnahme', memo: 'Memo', video: 'Video'}[recordingContext.type] + ' ' +
      (new Date().toLocaleString(
        'de-DE', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'}
      ).replace(' ', '').replace(/\D/g, '-'))
    );
  };
  
  const uploadToVideoApi = () => {
    setUploading(true);
    bl.generate_video_upload_token()
      .then(token => {
        const videoUploader = new VideoUploader({
          uploadToken: token,
          file: new File(
            [props.videoBlob], props.videoBlob.type.replace('/', '.'),
            { lastModified: new Date().getTime(), type: props.videoBlob.type }
          ),
          videoName: window.location.hostname.split('.')[0] + ': ' + generateVideoName()
        });
        setProgress(0);
        videoUploader.onProgress(event => {
          setProgress(Math.floor((event.uploadedBytes / event.totalBytes) * 100));
        });
        videoUploader.upload()
          .then(video => {
            crud.data.create({
              entity: 'video',
              item: {
                name: generateVideoName(),
                api_video_id: video.videoId,
                type: recordingContext.type
              }
            })
              .then(result => {
                if (recordingContext.intention === 'introduction_video') {
                  offerContext.setOffer({...offerContext.offer, introduction_video_id: result.id});
                }
                if (recordingContext.intention === 'offer_video') {
                  offerContext.setOffer({...offerContext.offer, offer_video_id: result.id});
                }
                setUploading(false);
                setShowSuccess(true);
              })
              .catch(errors => setErrors(errors))
              .finally(() => setUploading(false));
          })
          .catch(error => setErrors([error]) || setUploading(false));
      })
      .catch(errors => setErrors(errors) || setUploading(false));
  };

  const uploadToFilesServer = () => {
    setShowSuccess(false);
    setUploading(true);
    let video = {};
    files.upload(new File(
      [props.videoBlob], props.videoBlob.type.replace('/', '.'),
      { lastModified: new Date().getTime(), type: props.videoBlob.type }
    ))
      .then(file => {
        video.file_id = file.id;
        video.name = generateVideoName();
        video.duration = Math.floor(duration);
        video.type = recordingContext.type;
        crud.data.create({entity: 'video', item: video})
          .then(result => {
            if (recordingContext.intention === 'introduction_video') {
              offerContext.setOffer({...offerContext.offer, introduction_video_id: result.id});
            }
            if (recordingContext.intention === 'offer_video') {
              offerContext.setOffer({...offerContext.offer, offer_video_id: result.id});
            }
            if (recordingContext.type === 'memo') {
              bl.process_audio({id: result.id}).catch(errors => console.error(errors));
            } else {
              bl.process_video({id: result.id}).catch(errors => console.error(errors));
            }
            setShowSuccess(true);
            setUploading(false);
          })
          .catch(errors => setErrors(errors));
      })
      .catch(errors => {
        setErrors(errors);
      });
  };

  const upload = () => {
    if (recordingContext.type === 'memo') {
      uploadToFilesServer();
    } else {
      uploadToVideoApi();
    }
  };

  return (
    <ContainerSize>
      {
        props.videoBlob !== undefined &&
        <Video
          setVideoDuration={setDuration}
          videoProps={recordingContext.type === 'memo'
            ? {url: URL.createObjectURL(props.videoBlob), type: 'memo'}
            : {url: URL.createObjectURL(props.videoBlob)}}
        />}
      <ButtonBar align="right">
        <ButtonLink to="../neu" text={(recordingContext.type === 'screencast' ? 'Bildschirmaufnahme neu aufnehmen' : (
          recordingContext.type === 'memo' ? 'Memo neu aufnehmen' : 'Video neu aufnehmen'
        ))} color="gray" onClick={() => props.setVideoBlob(undefined)}/>
        {
          props.videoBlob !== undefined &&
          <Button
            text="Speichern" type="button" onClick={upload}
            icon={uploading ? <LoaderIcon/> : <ChevronRightIcon/>} disabled={uploading}
          />
        }
      </ButtonBar>
      {
        errors.length === 0 && uploading && progress > 0 &&
        <Modal dismissable={false}>
          <div className="text-center">
            <Progress value={progress} className="orange"/>
            <div className="mt-1">
              {
                {
                  screencast: 'Deine Bildschirmaufnahme wird hochgeladen',
                  memo: 'Dein Memo wird hochgeladen',
                  video: 'Dein Video wird hochgeladen'
                }[recordingContext.type]
              }
            </div>
          </div>
        </Modal>
      }
      <Modal
        show={showSuccess} onDismiss={recordingContext.navigateBack} title={
          (recordingContext.type === 'screencast' ?
            'Die Bildschirmaufnahme wurde erfolgreich hochgeladen' :
            (
              recordingContext.type === 'memo' ?
                'Das Memo wurde erfolgreich hochgeladen' : 'Das Video wurde erfolgreich hochgeladen'
            )
          )}>
        <ButtonBar>
          {
            recordingContext.intention === 'general' &&
            <ButtonLink
              to="/video/aufnehmen" color="gray" onClick={() => props.setVideoBlob(undefined)} text={
                (
                  recordingContext.type === 'screencast' ?
                    'Weitere Bildschirmaufnahme aufnehmen' : (
                      recordingContext.type === 'memo' ? 'Weiteres Memo aufnehmen' : 'Weiteres Video aufnehmen'
                    )
                )
              }/>
          }
          <Button text="Weiter" onClick={recordingContext.navigateBack}/>
        </ButtonBar>
      </Modal>
      {errors.length > 0 && <div className="errors">
        <ul>
          {errors.map(error => error)}
        </ul>
      </div>
      }
    </ContainerSize>
  );

}
