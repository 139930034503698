import TitleHeading from "../../components/TitleHeading";
import Breadcrumb from "../../components/Breadcrumb";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import Title from "../../components/Title";
import Stage from "../../components/Stage";
import ButtonLink from "../../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import Button from "../../components/Button";
import ButtonBar from "../../components/ButtonBar";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../../components/TextInput";
import FormErrors from "../../components/FormErrors";
import ErrorModal from "../../components/ErrorModal";
import {useState} from "react";
import Row from "../../components/Row";
import Modal from "../../components/Modal";
import {useEffect} from "react";
import IconWithText from "../../components/IconWithText";
import useCRUD from "../../hooks/useCRUD";

export default function HeaderImageEdit() {

  const crud = useCRUD();
  const params = useParams();
  const navigate = useNavigate();
  const [headerImages, setHeaderImages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    setLoading(true);
    crud.data.read({entity: 'header_image', id: parseInt(params.id)})
      .then(result => setHeaderImages({...result}))
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud.data, params.id]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Bitte geben Sie einen Namen ein'),
  });

  const submit = (values, {setSubmitting}) => {
    crud.data.update({entity: 'header_image', id: parseInt(params.id), update: {...values}})
      .then(() => setShowSuccess(true))
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Titelbild bearbeiten</TitleHeading>
        <Breadcrumb>
          <NavLink to="/titelbilder">Titelbilder</NavLink>
          {headerImages && <NavLink to={`/titelbilder/${params.id}/details`}>Details</NavLink>}
          Bearbeiten
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading && headerImages &&
        <Formik
          initialValues={{name: headerImages.name || ''}} validationSchema={validationSchema}
          onSubmit={submit} onReset={() => setShowSuccess(false)}
        >
          {({isSubmitting, touched, errors}) => (
            <Form>
              <Row>
                <TextInput name="name" label="Name*" placeholder="Bsp. Mona Lisa am Strand"/>
              </Row>
              <ButtonBar>
                <ButtonLink
                  to={`/titelbilder/${params.id}/details`} color="gray" icon={<ChevronLeftIcon/>} iconPosition="left"
                  text="Zurück"
                />
                <Button
                  icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                  text="Speichern" type="submit" disabled={isSubmitting}
                />
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal title="Das Bild wurde erfolgreich gespeichert" show={showSuccess}
                onDismiss={() => navigate(`/titelbilder/${params.id}/details`)}
              >
                <ButtonBar>
                  <Button type="button" text="Ok" onClick={() => navigate(`/titelbilder/${params.id}/details`)}/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
