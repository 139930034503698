import Title from '../components/Title';
import TitleHeading from '../components/TitleHeading';
import Column from "../components/Column";
import Stage from "../components/Stage";
import Table from "../components/Table";
import {EditIcon, PlusCircleIcon, TrashIcon} from "../components/Icons";
import React, {useState} from "react";
import ErrorModal from "../components/ErrorModal";
import ButtonLink from "../components/ButtonLink";
import ButtonBar from "../components/ButtonBar";
import RowActionLink from "../components/RowActionLink";
import {NavLink} from "react-router-dom";
import useCRUD from "../hooks/useCRUD";
import Button from "../components/Button";
import Modal from "../components/Modal";
import RowActionButton from "../components/RowActionButton";

export default function Team() {

  const crud = useCRUD();
  const [errors, setErrors] = useState([]);
  const [deleteTeamMember, setDeleteTeamMember] = useState(null);
  const [reloadKey, setReloadKey] = useState(0);
  const mailto_link = e_mail => e_mail ? <a href={'mailto:' + e_mail}>{e_mail}</a> : null;
  const tel_link = tel => tel ? <a href={'tel:' + tel.replace(/[^\d+]/g, '')}>{tel}</a> : null;
  const image = (url, team_member) => (
    url ?
      <NavLink to={team_member.id + '/bearbeiten'}>
        <img className="round" src={url}
          alt={`Portrait von ${team_member.first_name} ${team_member.last_name}`}/>
      </NavLink>
      : null
  );
  const transformFirstName = (first_name, team_member) =>
    <NavLink to={'/team-mitglied/' + team_member.id + '/bearbeiten'}>{first_name}</NavLink>;
  const transformLastName = (last_name, team_member) =>
    <NavLink to={'/team-mitglied/' + team_member.id + '/bearbeiten'}>{last_name}</NavLink>;

  const [imageUrls, setImageUrls] = useState({});
  const pendingImageUrls = [];
  const readImageUrls = (team_member) => {
    if (!team_member.portrait_id) {
      imageUrls[team_member.id] = null;
    } else {
      if (!pendingImageUrls.includes(team_member.id)) {
        pendingImageUrls.push(team_member.id);
        crud.data.read({
          entity: 'file',
          id: team_member.portrait_id
        })
          .then(
            result => {
              imageUrls[team_member.id] = result.url;
              setImageUrls({...imageUrls});
            }
          )
          .catch(errors => setErrors(errors))
          .finally(() => pendingImageUrls.filter(i => i !== team_member.id));
      }
    }
  };

  const doDelete = () => {
    crud.data.delete({'entity': 'team_member', id: deleteTeamMember.id})
      .then(() => {
        setDeleteTeamMember(null);
        setReloadKey(reloadKey + 1);
      })
      .catch(errors => setErrors(errors));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Team</TitleHeading>
      </Title>
      <Stage>
        <Table name="team_member" entity="team_member" defaultOrderBy="last_name" reloadKey={reloadKey}>
          <Column title="Foto" type="image" values={imageUrls} populate={readImageUrls} transform={image}/>
          <Column title="Vorname" property="first_name" transform={transformFirstName}/>
          <Column title="Nachname" property="last_name" transform={transformLastName}/>
          <Column
            title="Position" property="position" transform={position => position?.split('\n').join(', ')}
            maxWidth="250px"
          />
          <Column title="E-Mail" property="e_mail" transform={mailto_link} maxWidth="15rem"/>
          <Column title="Telefon" property="phone" transform={tel_link}/>
          <Column title="Mobil" property="mobile" transform={tel_link}/>
          <Column type="actions">
            <RowActionLink to={row => '/team-mitglied/' + row.id + '/bearbeiten'} color="blue" title="Bearbeiten">
              <EditIcon/>
            </RowActionLink>
            <RowActionButton color="red" title="Löschen" onClick={setDeleteTeamMember}><TrashIcon/></RowActionButton>
          </Column>
        </Table>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        <ButtonBar>
          <ButtonLink to="/team-mitglied/neu" text="Hinzufügen" icon={<PlusCircleIcon/>}/>
        </ButtonBar>
        {
          deleteTeamMember &&
          <Modal title="Löschen bestätigen" onDismiss={() => setDeleteTeamMember(null)}>
            <p>{
              `Möchten Sie das Teammitglied „${deleteTeamMember.first_name} ${deleteTeamMember.last_name}“` +
              ` wirklich löschen?`
            }</p>
            <ButtonBar>
              <Button text="Behalten" onClick={() => setDeleteTeamMember(null)}/>
              <Button color="red" text="Löschen" icon={<TrashIcon/>} onClick={doDelete}/>
            </ButtonBar>
          </Modal>
        }
      </Stage>
    </main>
  );

}
