import Title from '../../components/Title';
import TitleHeading from '../../components/TitleHeading';
import Stage from "../../components/Stage";
import ButtonBar from "../../components/ButtonBar";
import ButtonLink from "../../components/ButtonLink";
import {EmptyPackageIcon, LoaderIcon, PlusCircleIcon} from "../../components/Icons";
import {useEffect, useState} from "react";
import ErrorModal from "../../components/ErrorModal";
import IconWithText from "../../components/IconWithText";
import Flex from "../../components/Flex";
import useCRUD from "../../hooks/useCRUD";
import Pagination from "../../components/Pagination";
import Image from "../../components/Image";

export default function HeaderImageList() {

  const crud = useCRUD();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [headerImages, setHeaderImages] = useState([]);
  const [files, setFiles] = useState({});
  const [totalHeaderImages, setTotalHeaderImages] = useState();
  const [page, setPage] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    crud.data.bulk.read({entity: 'header_image', page, page_size: pageSize})
      .then(bulkReadResult => {
        setHeaderImages([...bulkReadResult.items]);
        setTotalHeaderImages(bulkReadResult.total);
        if (bulkReadResult.items.length > 0) {
          let reads = [];
          setFiles({});
          bulkReadResult.items.forEach(item => reads.push(crud.data.read({entity: 'file', id: item.file_id})));
          Promise.all(reads)
            .then(result => result.forEach(item => {
              setFiles(files => ({...files, [item.id]: item}));
            }))
            .catch(errors => setErrors(errors))
            .finally(() => setLoading(false));
        } else {
          setLoading(false);
        }
      })
      .catch(errors => {
        setErrors(errors);
        setLoading(false);
      });
  }, [crud.data, page, pageSize]);

  return (
    <main>
      <Title>
        <TitleHeading>Titelbilder</TitleHeading>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading && headerImages.length === 0 &&
        <IconWithText><EmptyPackageIcon/> Keine Einträge vorhanden.</IconWithText>
        }
        {!loading &&
        <Flex>{
          headerImages.map(
            headerImage =>
              files[headerImage.file_id] &&
              <Image key={headerImage.id} to={'/titelbilder/' + headerImage.id + '/details'} name={headerImage.name}>
                {files[headerImage.file_id]}
              </Image>
          )
        }</Flex>
        }
        {
          !loading && totalHeaderImages > headerImages.length &&
          <Pagination className="mt-1-75" total={totalHeaderImages} page={page} pageSize={pageSize} setPage={setPage}/>
        }
        <ButtonBar align="left">
          <ButtonLink to="neu" text="Hinzufügen" icon={<PlusCircleIcon/>}/>
        </ButtonBar>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
