import React, {useEffect, useState} from "react";
import {localStorage, sessionStorage} from "../storage";
import useClaims from "../hooks/useClaims";
import useBL from "../hooks/useBL";
import ErrorModal from "../components/ErrorModal";

export const AuthContext = React.createContext({authToken: '', setAuthToken: (authToken) => {}});

export function AuthContextProvider({children}) {

  const [authToken, setAuthToken] = useState(localStorage.get('authToken') ?? sessionStorage.get('authToken') ?? '');
  const [errors, setErrors] = useState([]);

  const claims = useClaims(authToken);
  const bl = useBL(authToken);

  useEffect(() => {
    if (authToken) {
      if (new Date(claims.exp).getTime() < new Date().getTime()) {
        setAuthToken(null);
        localStorage.remove('authToken');
        sessionStorage.remove('authToken');
      } else {
        bl.renew_auth_token()
          .then(token => {
            setAuthToken(token);
            if (localStorage.get('authToken')) {
              localStorage.set('authToken', token);
            } else {
              sessionStorage.set('authToken', token);
            }
          })
          .catch(errors => setErrors(errors));
      }
    }
  }, [authToken, bl, claims.exp]);

  return (
    <AuthContext.Provider value={{authToken, setAuthToken}}>
      {children}
      <ErrorModal errors={errors} setErrors={setErrors}/>
    </AuthContext.Provider>
  );

}
