import TextInput from "./TextInput";
import {useState} from "react";
import {EyeIcon, EyeIconOff} from "./Icons";

export default function PasswordInput(props) {

  const [visible, setVisible] = useState(false);

  return (
    <TextInput
      type={visible ? 'text' : 'password'} icon={visible ? <EyeIconOff/> : <EyeIcon/>}
      placeholder={props.placeholder} {...props}
      iconClick={() => setVisible(!visible)}
    />
  );

}
