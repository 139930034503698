import Title from "../../components/Title";
import TitleHeading from "../../components/TitleHeading";
import Stage from "../../components/Stage";
import {Form, Formik} from "formik";
import TextInput from "../../components/TextInput";
import TextareaInput from "../../components/TextareaInput";
import ButtonBar from "../../components/ButtonBar";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import Button from "../../components/Button";
import useCRUD from "../../hooks/useCRUD";
import {useState} from "react";
import * as Yup from "yup";
import ErrorModal from "../../components/ErrorModal";
import FormErrors from "../../components/FormErrors";
import Modal from "../../components/Modal";
import {NavLink, useNavigate} from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";

export default function Create() {

  const crud = useCRUD();
  const navigate = useNavigate();

  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const validationSchema = Yup.object({
    title: Yup.string().required('Bitte geben Sie einen Titel ein'),
    content: Yup.string().required('Bitte füllen Sie das Anschreiben aus')
  });

  function onSubmit(values, {setSubmitting}) {
    crud.data.create({entity: 'letter', item: {...values}})
      .then(() => setShowSuccess(true))
      .finally(() => setSubmitting(false))
      .catch(errors => setErrors(errors));
  }

  return (
    <main>
      <Title>
        <TitleHeading>Anschreiben erstellen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/anschreiben">Anschreiben</NavLink>
          Neu
        </Breadcrumb>
      </Title>
      <Stage>
        <Formik
          initialValues={{
            title: '',
            content: ''
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          onReset={() => setShowSuccess(false)}
        >
          {({isSubmitting, touched, errors}) => (
            <Form className="mt-1-75">
              <TextInput name="title" label="Titel des Anschreibens*" placeholder="Bsp. Formales Anschreiben"/>
              <TextareaInput className="mt-1-75" name="content" label="Anschreiben*" placeholder={
                "Bsp. Vielen Dank, dass ich mich Ihrem Unternehmen vorstellen durfte. " +
                "Folgend möchte ich Ihnen unser Unternehmen kurz vorstellen und unser Angebot erläutern."
              }
              />
              <ButtonBar>
                <Button
                  color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück" onClick={() => navigate(-1)}
                />
                <Button icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                  text="Speichern" type="submit" disabled={isSubmitting}/>
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal
                title="Das Anschreiben wurde erfolgreich erstellt" show={showSuccess}
                onDismiss={() => navigate('/anschreiben')}
              >
                <ButtonBar>
                  <Button type="reset" color="gray" text="Weiteres Anschreiben hinzufügen"/>
                  <Button text="Zurück zur Übersicht" onClick={() => navigate(-1)}/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
