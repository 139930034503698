import TitleHeading from "../components/TitleHeading";
import Breadcrumb from "../components/Breadcrumb";
import {NavLink, useParams} from "react-router-dom";
import Title from "../components/Title";
import Stage from "../components/Stage";
import ButtonLink from "../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../components/Icons";
import Button from "../components/Button";
import ButtonBar from "../components/ButtonBar";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../components/TextInput";
import FormErrors from "../components/FormErrors";
import ErrorModal from "../components/ErrorModal";
import {useState} from "react";
import Row from "../components/Row";
import Modal from "../components/Modal";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import IconWithText from "../components/IconWithText";
import TextareaInput from "../components/TextareaInput";
import useCRUD from "../hooks/useCRUD";

export default function CreateFaq() {

  const crud = useCRUD();
  const params = useParams();
  const [faqGroup, setFaqGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [orderIndex, setOrderIndex] = useState(null);

  useEffect(() => {
    Promise.all([
      crud.data.read({entity: 'faq_group', id: parseInt(params.id)}),
      crud.data.bulk.read({
        entity: 'faq', page_size: 9999, filter: crud.filter.equals('faq_group_id', parseInt(params.id))
      })
    ])
      .then(([faqGroupResult, faqResult]) => {
        setFaqGroup({...faqGroupResult});
        setOrderIndex(
          faqResult.items.map(usp => parseInt(usp.order ?? '-1')).reduce((a, b) => Math.max(a, b), - 1) + 1
        );
      })
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud, params.id]);

  const initialValues = {
    question: '',
    answer: ''
  };

  const validationSchema = Yup.object({
    question: Yup.string().required('Bitte geben Sie eine Frage ein'),
    answer: Yup.string().required('Bitte geben Sie eine Antwort ein'),
  });

  const submit = (values, {setSubmitting}) => {
    let item = {...values, order: orderIndex};
    item.faq_group_id = faqGroup.id;
    crud.data.create({entity: 'faq', item})
      .then(() => setShowSuccess(true))
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  return (
    <main>
      <Title>
        <TitleHeading>FAQ erstellen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/faq-gruppen">FAQ Gruppen</NavLink>
          {faqGroup && <NavLink to={`/faq-gruppen/${faqGroup.id}/details`}>{faqGroup.name}</NavLink>}
          Neu
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
          <Formik
            initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}
            onReset={() => setShowSuccess(false)}
          >
            {({isSubmitting, touched, errors}) => (
              <Form>
                <Row lgWidth="50%">
                  <TextInput
                    name="question" label="Stelle eine Frage*" placeholder="Bsp. Wie viele Mitarbeiter hat vyn?"
                  />
                </Row>
                <Row lgWidth="50%">
                  <TextareaInput
                    name="answer" label="Gib die Antwort ein*" placeholder="Bsp. vyn beschäftigt 10 Mitarbeiter."
                  />
                </Row>
                <ButtonBar align="left">
                  <ButtonLink
                    to={`/faq-gruppen/${faqGroup.id}/details`} color="gray" icon={<ChevronLeftIcon/>}
                    iconPosition="left" text="Zurück"
                  />
                  <Button
                    icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                    text="Speichern" type="submit" disabled={isSubmitting}
                  />
                </ButtonBar>
                <FormErrors touched={touched} errors={errors}/>
                <Modal
                  title="Die FAQ wurde erfolgreich erstellt" show={showSuccess}
                  onDismiss={() => navigate(`/faq-gruppen/${faqGroup.id}/details`)}
                >
                  <ButtonBar>
                    <Button type="reset" color="gray" text="Weitere FAQ erstellen"/>
                    <ButtonLink to={`/faq-gruppen/${faqGroup.id}/details`} text="Zurück zur FAQ Gruppe"/>
                  </ButtonBar>
                </Modal>
              </Form>
            )}
          </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
