import Title from '../components/Title';
import TitleHeading from '../components/TitleHeading';
import Stage from "../components/Stage";
import React, {useEffect, useState} from "react";
import ErrorModal from "../components/ErrorModal";
import useCRUD from "../hooks/useCRUD";
import IconWithText from "../components/IconWithText";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../components/Icons";
import Switch from "../components/Switch";
import ButtonBar from "../components/ButtonBar";
import ButtonLink from "../components/ButtonLink";
import Button from "../components/Button";
import Row from "../components/Row";
import useClaims from "../hooks/useClaims";
import Modal from "../components/Modal";
import StageTitle from "../components/StageTitle";

export default function Benachrichtigungen() {

  const crud = useCRUD();
  const claims = useClaims();

  const [errors, setErrors] = useState([]);
  const [contactPersonNotifications, setContactPersonNotifications] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    setLoading(true);
    crud.data.read({
      entity: 'contact_person_notifications',
      filter: crud.filter.equals('contact_person_id', claims.user_id)
    })
      .then(contactPersonNotifications => {
        if (contactPersonNotifications) {
          setContactPersonNotifications(contactPersonNotifications);
        } else {
          setContactPersonNotifications({
            contact_person_id: claims.user_id,
            first_page_impression: '1',
            first_introduction_video_play: '0',
            first_offer_video_play: '1',
            first_offer_download: '1'
          });
        }
      })
      .catch(errors => {
        setErrors(errors);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [crud.data, crud.filter, claims.user_id]);

  const toggle = property => setContactPersonNotifications(
    contactPersonNotifications => {
      const newContactPersonNotifications = {...contactPersonNotifications};
      newContactPersonNotifications[property] = newContactPersonNotifications[property] === '0' ? '1' : '0';
      return newContactPersonNotifications;
    }
  );

  const submit = () => {
    setSubmitting(true);
    if (contactPersonNotifications.id) {
      crud.data.update({
        entity: 'contact_person_notifications',
        id: contactPersonNotifications.id,
        update: {
          first_page_impression : contactPersonNotifications.first_page_impression,
          first_introduction_video_play : contactPersonNotifications.first_introduction_video_play,
          first_offer_video_play : contactPersonNotifications.first_offer_video_play,
          first_offer_download : contactPersonNotifications.first_offer_download
        }})
        .then(() => setShowSuccess(true))
        .catch(errors => setErrors(errors))
        .finally(() => setSubmitting(false));
    } else {
      crud.data.create({
        entity: 'contact_person_notifications',
        item: contactPersonNotifications
      })
        .then(item => {
          setContactPersonNotifications({...contactPersonNotifications, id: item.id});
          setShowSuccess(true);
        })
        .catch(errors => setErrors(errors))
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <main>
      <Title>
        <TitleHeading>E-Mail Benachrichtigungen</TitleHeading>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
        <div>
          <Row>
            <StageTitle>
              Lege fest, bei welchen Interaktionen deines Kunden mit deinen Angeboten du eine E-Mail erhalten möchtest
            </StageTitle>
          </Row>
          <Row style={{rowGap: '.75rem'}}>
            <label onClick={() => toggle('first_page_impression')}>
              Das Angebot wird zum ersten Mal geöffnet
            </label>
            <span>
              <Switch
                name="first_page_impression"
                onClick={() => toggle('first_page_impression')}
                active={contactPersonNotifications.first_page_impression === '1'}
              />
            </span>
          </Row>
          <Row style={{rowGap: '.75rem'}}>
            <label onClick={() => toggle('first_introduction_video_play')}>
              Das Begrüßungsvideo/-memo wird zum ersten Mal gestartet
            </label>
            <span>
              <Switch
                name="first_introduction_video_play"
                onClick={() => toggle('first_introduction_video_play')}
                active={contactPersonNotifications.first_introduction_video_play === '1'}
              />
            </span>
          </Row>
          <Row style={{rowGap: '.75rem'}}>
            <label onClick={() => toggle('first_offer_video_play')}>
              Das Angebotsvideo/-memo wird zum ersten Mal gestartet
            </label>
            <span>
              <Switch
                name="first_offer_video_play"
                onClick={() => toggle('first_offer_video_play')}
                active={contactPersonNotifications.first_offer_video_play === '1'}
              />
            </span>
          </Row>
          <Row style={{rowGap: '.75rem'}}>
            <label onClick={() => toggle('first_offer_download')}>
              Die Angebotsdatei wird zum ersten Mal heruntergeladen
            </label>
            <span>
              <Switch
                name="first_offer_download"
                onClick={() => toggle('first_offer_download')}
                active={contactPersonNotifications.first_offer_download === '1'}
              />
            </span>
          </Row>
          <ButtonBar>
            <ButtonLink
              to="../dashboard" text="Zurück" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left"
            />
            <Button
              icon={submitting ? <LoaderIcon/> : <ChevronRightIcon/>} text="Speichern" type="submit"
              onClick={submit}
            />
          </ButtonBar>
        </div>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        <Modal
          title="Ihre Angaben wurden erfolgreich gespeichert"
          show={showSuccess}
          onDismiss={() => setShowSuccess(false)}
        >
          <ButtonBar>
            <Button type="button" text="Ok" onClick={() => setShowSuccess(false)}/>
          </ButtonBar>
        </Modal>
      </Stage>
    </main>
  );
}
