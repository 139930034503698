import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../components/TextInput";
import ButtonBar from "../components/ButtonBar";
import ButtonLink from "../components/ButtonLink";
import Button from "../components/Button";
import FormErrors from "../components/FormErrors";
import PublicModal from "../components/PublicModal";
import {ChevronLeftIcon, LoaderIcon} from "../components/Icons";
import useBL from "../hooks/useBL";
import {useState} from "react";

export default function RequestPasswordRecovery() {

  const bl = useBL();

  const [remoteErrors, setRemoteErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const validate = Yup.object({
    email: Yup.string()
      .required('Bitte geben Sie eine E-Mail-Adresse ein')
      .email('Bitte geben Sie eine gültige E-Mail-Adresse ein')
  });

  const submit = (values, {setSubmitting}) => {
    bl.recover_password({email: values.email, url: window.location.origin + '/passwort-zuruecksetzen/TOKEN'})
      .then(() => setShowSuccess(true))
      .catch(remoteErrors => setRemoteErrors(remoteErrors))
      .finally(() => setSubmitting(false));
  };

  return (
    <PublicModal>
      <h1>Passwort zurücksetzen</h1>
      {
        !showSuccess &&
        <>
          <p>
            Bitte die in diesem System hinterlegte E-Mail-Adresse eingeben, um eine Nachricht mit weiteren
            Anweisungen zum Zurücksetzen des Passworts zu erhalten.
          </p>
          <Formik
            initialValues={{email: ''}} validationSchema={validate} onSubmit={submit}
            validate={() => setRemoteErrors([])}
          >
            {({isSubmitting, touched, errors}) => (
              <Form>
                <TextInput name="email" placeholder="E-Mail"/>
                <ButtonBar align="left">
                  <ButtonLink text="Abbrechen" to="/anmeldung" color="gray"/>
                  <Button
                    color="gray" text="Absenden" type="submit" icon={isSubmitting ? <LoaderIcon/> : null}
                    disabled={isSubmitting}
                  />
                </ButtonBar>
                <FormErrors touched={touched} errors={errors} remoteErrors={remoteErrors}/>
              </Form>
            )}
          </Formik>
        </>
      }
      {
        showSuccess &&
        <>
          <p>
            Eine Nachricht mit weiteren Anweisungen zum Zurücksetzen des Passworts wurde an die eingegebene
            E-Mail-Adresse versendet.
          </p>
          <ButtonBar align="left">
            <ButtonLink color="gray" text="Zurück" to="/anmeldung" icon={<ChevronLeftIcon/>} iconPosition="left"/>
          </ButtonBar>
        </>
      }
    </PublicModal>
  );

}
