import {useEffect, useState} from "react";
import './Switch.scss';

export default function Switch({color, activeColor, backgroundColor, ...props}) {

  useEffect(() => setActive(props.active), [props.active]);

  const [active, setActive] = useState(props.active);

  return (
    <div style={{backgroundColor: backgroundColor}} className='Switch' onClick={() => {
      setActive(!active);
      if (props.onClick) {
        props.onClick();
      }
    }}>
      <div
        className={active ? 'dot dot-active' : 'dot'}
        style={active ? {backgroundColor: activeColor} : {backgroundColor: color}}
      />
    </div>
  );

}
