import Title from '../components/Title';
import TitleHeading from '../components/TitleHeading';
import Row from "../components/Row";
import LinkWidget from "../components/LinkWidget";
import {FileTextIcon, ImageIcon} from "../components/Icons";
import FactWidget from "../components/FactWidget";
import Stage from "../components/Stage";
import TeaserWidget from "../components/TeaserWidget";
import OfferCount from "../components/OfferCount";
import CustomerCount from "../components/CustomerCount";
import {useContext, useEffect, useState} from "react";
import useCRUD from "../hooks/useCRUD";
import ErrorModal from "../components/ErrorModal";
import BarChart from "../components/BarChart";
import useClaims from "../hooks/useClaims";
import {OfferContext} from "../contexts/OfferContext";

export default function Dashboard() {

  const crud = useCRUD();
  const claims = useClaims();
  const offerContext = useContext(OfferContext);

  const [firstName, setFirstName] = useState();
  const [logoId, setLogoId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [teaserWidgetVisible, setTeaserWidgetVisible] = useState(false);

  const currentYear = new Date().getFullYear().toString();

  useEffect(() => {
    setLoading(true);
    const readCalls = [crud.data.read({entity: 'design', id: 1})];
    if (claims.user_id) {
      readCalls.push(crud.data.read({entity: 'contact_person', id: claims.user_id}));
    }
    Promise.all(readCalls)
      .then(result => {
        result[0] && setLogoId(result[0].logo_id);
        result[1] && setFirstName(result[1].first_name);
      })
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [claims, crud]);

  useEffect(() => {
    crud.data.bulk.read({
      entity: 'offer',
      filter: crud.filter.equals('status', 'won'),
      page_size: 0
    }).then(bulkReadResult => setTeaserWidgetVisible(bulkReadResult.total >= 10));
  },[crud]);

  return (
    <main>
      <Title>
        <TitleHeading>
          Hey{firstName ? ` ${firstName}` : ''}, lass uns Geld verdienen.
        </TitleHeading>
      </Title>
      <Stage transparent>
        <Row>
          <Row>
            <FactWidget
              color="purple" number={<OfferCount year={currentYear}/>} description="Erstellt"
              details={`In ${currentYear}`}
            />
            <FactWidget
              color="green" number={<OfferCount status="won" year={currentYear}/>} description="Gewonnen"
              details={`In ${currentYear}`}
            />
            <FactWidget
              color="turquoise" number={<OfferCount status="open" year={currentYear}/>} description="Offen"
              details={`In ${currentYear}`}
            />
            <FactWidget
              color="red" number={<OfferCount status="lost" year={currentYear}/>} description="Verloren"
              details={`In ${currentYear}`}
            />
          </Row>
        </Row>
        {
          teaserWidgetVisible &&
          <Row>
            <BarChart/>
            <TeaserWidget
              color="orange" number={<CustomerCount status="won"/>} headline="Zufriedene Kunden"
              text="Anzahl verschiedener Kunden, die bisher ein Angebot angenommen haben."
            />
          </Row>
        }
        {
          !teaserWidgetVisible &&
          <Row lgWidth="50%">
            <BarChart/>
          </Row>
        }
        <Row>
          <LinkWidget
            color="pink" linkTo="/angebot/neu" icon={<FileTextIcon/>} headline="Angebot" text="Erstelle ein Angebot"
            onClick={offerContext.resetOffer}
          />
          {
            !loading && !logoId &&
            <LinkWidget
              color="yellow" linkTo="/design" icon={<ImageIcon/>} headline="Design" text="Lade ein Logo hoch"
            />
          }
        </Row>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
