const prefix = 'sellestar_client_';

function storage(storage) {
  return {
    get: key => {
      let value = null;
      try {
        value = JSON.parse(storage.getItem(prefix + key));
      } catch (error) {
        console.error(error);
      }
      return value;
    },
    set: (name, value) => {
      try {
        storage.setItem(prefix + name, JSON.stringify(value));
      } catch (error) {
        console.error(error);
      }
    },
    remove: name => {
      try {
        storage.removeItem(prefix + name);
      } catch (error) {
        console.error(error);
      }
    }
  };
}

const sessionStorage = storage(window.sessionStorage);
const localStorage = storage(window.localStorage);

export {sessionStorage, localStorage};
