import Templates from "../resources/templates.json";
import {useContext, useMemo} from "react";
import {TenantContext} from "../contexts/TenantContext";

export default function useTemplates() {

  const tenantContext = useContext(TenantContext);

  return useMemo(
    () =>
      Templates.filter(
        template => template.tenants.includes('*') || template.tenants.includes(tenantContext.tenant)
      )
    ,
    [tenantContext.tenant]
  );

}
