import TitleHeading from "../../components/TitleHeading";
import Breadcrumb from "../../components/Breadcrumb";
import {NavLink, useNavigate} from "react-router-dom";
import Title from "../../components/Title";
import ButtonBar from "../../components/ButtonBar";
import ButtonLink from "../../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import Stage from "../../components/Stage";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {useState} from "react";
import ErrorModal from "../../components/ErrorModal";
import DropzoneInput from "../../components/DropzoneInput";
import Button from "../../components/Button";
import FormErrors from "../../components/FormErrors";
import Modal from "../../components/Modal";
import useCRUD from "../../hooks/useCRUD";
import useFiles from "../../hooks/useFiles";

export default function HeaderImageCreate() {

  const crud = useCRUD();
  const files = useFiles();
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const validationSchema = Yup.object({
    headerImages: Yup.array()
      .min(1, 'Wählen Sie ein Bild aus')
      .max(5, 'Bitte nur fünf Bilder auf einmal hochladen. Sie können im Nachgang weitere Bilder hochladen.')
      .of(
        Yup.mixed()
          .test(
            'fileType', 'Erlaubte Bildformate: PNG, JPG, GIF, SVG und WEBP',
            value => ['image/gif', 'image/png', 'image/jpeg', 'image/svg+xml', 'image/webp'].includes(value.type)
          )
      )
  });

  const submit = (values, {setSubmitting}) => {
    files.upload(values.headerImages)
      .then(results => {
        crud.data.bulk.create({
          entity: 'header_image',
          items: results.map((result, index) => ({
            file_id: result.id,
            name: values.headerImages[index].name
          }))
        })
          .then(() => setShowSuccess(true))
          .catch(errors => setErrors(errors))
          .finally(() => setSubmitting(false));
      })
      .catch(errors => {
        setErrors(errors);
        setSubmitting(false);
      });
  };

  return (
    <main>
      <Title>
        <TitleHeading>Titelbild(er) hochladen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/titelbilder">Titelbilder</NavLink>
          Neu
        </Breadcrumb>
      </Title>
      <Stage>
        <Formik
          initialValues={{headerImages: []}} validationSchema={validationSchema} onSubmit={submit}
          onReset={() => setShowSuccess(false)}
        >
          {({isSubmitting, touched, errors}) => (
            <Form>
              <DropzoneInput name="headerImages" label="Titelbilder" 
                infoText="Nur PNG-, JPG-, GIF-, SVG- oder WEBP-Dateien" multiple/>
              <p>Die optimale Größe für das Titelbild ist Full-HD mit den Maßen 1980x1080px (Querformat).</p>
              <ButtonBar>
                <ButtonLink
                  to="/titelbilder" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                />
                <Button
                  icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                  text="Speichern" type="submit" disabled={isSubmitting}
                />
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal
                title="Die Bilder wurde erfolgreich hochgeladen" show={showSuccess}
                onDismiss={() => navigate('/titelbilder')}
              >
                <ButtonBar>
                  <Button type="reset" color="gray" text="Weitere Bilder hochladen"/>
                  <ButtonLink to="/titelbilder" text="Zurück zur Übersicht"/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
