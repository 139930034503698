import Widget from "./Widget";
import "./LinkWidget.scss";

export default function LinkWidget({linkTo, icon, headline, text, color = 'turquoise', onClick}) {

  return (
    <Widget className="LinkWidget" linkTo={linkTo} onClick={onClick}>
      <div className={'icon ' + color}>{icon}</div>
      <div className="texts">
        <p title={headline} className="headline">{headline}</p>
        {text && <p title={text} className="text">{text}</p>}
      </div>
    </Widget>
  );

}
