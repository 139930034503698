import TitleHeading from "../../components/TitleHeading";
import Breadcrumb from "../../components/Breadcrumb";
import {NavLink, useParams} from "react-router-dom";
import Title from "../../components/Title";
import Stage from "../../components/Stage";
import ButtonLink from "../../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import Button from "../../components/Button";
import ButtonBar from "../../components/ButtonBar";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../../components/TextInput";
import FormErrors from "../../components/FormErrors";
import ErrorModal from "../../components/ErrorModal";
import React, {useState} from "react";
import Modal from "../../components/Modal";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import IconWithText from "../../components/IconWithText";
import useCRUD from "../../hooks/useCRUD";
import DropzoneInput from "../../components/DropzoneInput";
import useFiles from "../../hooks/useFiles";

export default function Neu() {

  const crud = useCRUD();
  const files = useFiles();
  const params = useParams();
  const [logoGallery, setLogoGallery] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [orderIndex, setOrderIndex] = useState(null);

  useEffect(() => {
    Promise.all([
      crud.data.read({entity: 'logo_gallery', id: parseInt(params.id)}),
      crud.data.bulk.read({
        entity: 'logo_gallery_item',
        page_size: 9999,
        filter: crud.filter.equals('logo_gallery_id', parseInt(params.id))
      })
    ])
      .then(([logoGalleryResult, LogoGalleryItemResult]) => {
        setLogoGallery(logoGalleryResult);
        setOrderIndex(
          LogoGalleryItemResult.items.map(usp => parseInt(usp.order ?? '-1')).reduce((a, b) => Math.max(a, b), - 1) + 1
        );
      })
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud, params.id]);

  const initialValues = {
    url: '',
    image: null
  };

  const validationSchema = Yup.object({
    url: Yup.string().url('Bitte eine URL eingeben'),
    image: Yup.mixed()
      .required('Bitte ein Bild hochladen')
      .test(
        'fileType', 'Erlaubte Bildformate: PNG, JPG, GIF, SVG und WEBP',
        value => value && ['image/gif', 'image/png', 'image/jpeg', 'image/svg+xml', 'image/webp'].includes(value.type)
      )
  });

  const submit = (values, {setSubmitting}) => {
    files.upload(values.image)
      .then(file => {
        crud.data.create({
          entity: 'logo_gallery_item',
          item: {
            logo_gallery_id: logoGallery.id,
            url: values.url,
            image_id: file.id,
            order: orderIndex
          }
        })
          .then(() => setShowSuccess(true))
          .catch(errors => setErrors(errors))
          .finally(() => setSubmitting(false));
      })
      .catch(errors => setErrors(errors) || setSubmitting(false));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Eintrag erstellen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/logo-galerien">Logo-Galerien</NavLink>
          {
            logoGallery &&
            <NavLink to={`/logo-galerie/${logoGallery.id}/details`}>{logoGallery.headline}</NavLink>
          }
          Eintrag erstellen
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
          <Formik
            initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}
            onReset={() => setShowSuccess(false)}
          >
            {({isSubmitting, touched, errors}) => (
              <Form>
                <div className="mb-1">
                  <DropzoneInput
                    className="mt-1-75" name="image" label="Bild hochladen*"
                    infoText="Nur PNG-, JPG-, GIF-, SVG- oder WEBP-Dateien"
                  />
                </div>
                <TextInput name="url" label="URL" placeholder="Bsp. https://dein-logo.de"/>
                <ButtonBar align="left">
                  <ButtonLink
                    to={`/logo-galerie/${logoGallery.id}/details`} color="gray" icon={<ChevronLeftIcon/>}
                    iconPosition="left" text="Zurück"
                  />
                  <Button
                    icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                    text="Speichern" type="submit" disabled={isSubmitting}
                  />
                </ButtonBar>
                <FormErrors touched={touched} errors={errors}/>
                <Modal
                  title="Der Eintrag wurde erfolgreich erstellt" show={showSuccess}
                  onDismiss={() => navigate(`/galerie/${logoGallery.id}/details`)}
                >
                  <ButtonBar>
                    <Button type="reset" color="gray" text="Weiteren Eintrag erstellen"/>
                    <ButtonLink to={`/logo-galerie/${logoGallery.id}/details`} text="Zurück zur Logo-Galerie"/>
                  </ButtonBar>
                </Modal>
              </Form>
            )}
          </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
