import ButtonBar from "../../components/ButtonBar";
import StandaloneModal from "../../components/StandaloneModal";
import PartyPopper from "../../images/PartyPopper";
import ButtonLink from "../../components/ButtonLink";

export default function Finish({registrationData}) {

  let tenantUrl = tenant => {
    let parts = window.location.hostname.split('.');
    parts.splice(0, 1, tenant);
    return 'https://' + parts.join('.');
  };

  return (
    <StandaloneModal closer={false}>
      <PartyPopper/>
      <h1>Herzlichen Glückwunsch</h1>
      <p>Du wirst die besten Angebote schreiben und deinen Wettbewerbern kräftig in den Hintern treten.</p>
      <p>Starte direkt durch und richte deine Seite weiter ein:</p>
      <ButtonBar align="center">
        <ButtonLink
          to={tenantUrl(registrationData.tenant) + '/registrieren/fertig/' + registrationData.tokenReference}
          text="Durchstarten!" target="_self"
        />
      </ButtonBar>
    </StandaloneModal>
  );

}
