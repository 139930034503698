import './CheckboxInput.scss';
import {useField, useFormikContext} from "formik";

export default function CheckboxInput({name, label, className}) {

  const [field] = useField(name);
  const {setFieldValue} = useFormikContext();

  return (
    <div className={'CheckboxInput' + (className ? ` ${className}` : '')}>
      <label>
        <input type="checkbox" checked={field.value} onChange={() => setFieldValue(name, !field.value)}/>
        {label}
      </label>
    </div>
  );

}
