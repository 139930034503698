import {useParams} from "react-router-dom";
import Stage from "../../components/Stage";
import TitleHeading from "../../components/TitleHeading";
import Title from "../../components/Title";
import Row from "../../components/Row";
import ButtonBar from "../../components/ButtonBar";
import ButtonLink from "../../components/ButtonLink";
import {ChevronLeftIcon} from "../../components/Icons";
import DonutChart from "../../components/DonutChart";
import EventsWidget from "../../components/EventsWidget";
import FactWidget from "../../components/FactWidget";
import DeviceCount from "../../components/DeviceCount";
import EventWidget from "../../components/EventWidget";

export default function Statistics() {

  const params = useParams();

  return (
    <main>
      <Title>
        <TitleHeading>Angebote</TitleHeading>
      </Title>
      <Stage>
        <Row>
          <EventsWidget
            color="purple" description="Link geöffnet" offer_id={params.id} type="impression" target="page"
          />
          <EventsWidget
            color="purple" description="Angebot runtergeladen" offer_id={params.id} type="download" target="offer"
          />
          <FactWidget
            number={<DeviceCount offer_id={params.id}/>} color="turquoise" description="verschiedene Browser"
          />
        </Row>
        <Row>
          <DonutChart title="Klicks" type="click" offerId={params.id}/>
          <Row>
            <EventWidget
              color="yellow" text="Zuletzt geöffnet" offerId={params.id} type="impression" target="page" order="desc"
            />
            <EventWidget
              color="blue" text="Zuerst geöffnet" offerId={params.id} type="impression" target="page" order="asc"
            />
          </Row>
        </Row>
        <Row>
          <DonutChart title="Dokumente Downloads" getDownloads offerId={params.id}/>
        </Row>
        <ButtonBar align="left">
          <ButtonLink to="/angebote" text="Zurück" icon={<ChevronLeftIcon/>} iconPosition="left" color="gray"/>
        </ButtonBar>
      </Stage>
    </main>
  );

}
