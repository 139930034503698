import './ButtonWithTextInput.scss';
import TextInput from "./TextInput";

export default function ButtonWithTextInput({
  label, name,  readOnly, InputIcon, onInputIconClick, lableInputTooltip, className, children
}) {
  return (
    <div className={'ButtonWithTextInput' + (className ? (' ' + className) : '')}>
      <TextInput label={label} name={name} readOnly={readOnly} iconClick={onInputIconClick} icon={InputIcon}
        labelTooltip={lableInputTooltip}/>
      <div className="buttons">
        {children}
      </div>
    </div>
  );
}
