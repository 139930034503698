import './Button.scss';

export default function Button({text, color, icon, iconPosition, ...props}) {
  return (
    <button className={'Button ' + color} {...props}>
      {icon && iconPosition === 'left' && <span className="icon">{icon}</span>}
      <span className="text">{text}</span>
      {icon && iconPosition === 'right' && <span className="icon">{icon}</span>}
    </button>
  );
}

Button.defaultProps = {
  color: 'turquoise',
  iconPosition: 'right',
  type: 'button'
};
