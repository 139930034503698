import Title from "../../components/Title";
import TitleHeading from "../../components/TitleHeading";
import Stage from "../../components/Stage";
import {Form, Formik} from "formik";
import TextInput from "../../components/TextInput";
import TextareaInput from "../../components/TextareaInput";
import ButtonBar from "../../components/ButtonBar";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import Button from "../../components/Button";
import useCRUD from "../../hooks/useCRUD";
import {useState} from "react";
import * as Yup from "yup";
import ErrorModal from "../../components/ErrorModal";
import FormErrors from "../../components/FormErrors";
import Modal from "../../components/Modal";
import {NavLink, useNavigate} from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";

export default function Neu() {

  const crud = useCRUD();
  const navigate = useNavigate();

  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const validationSchema = Yup.object({
    headline: Yup.string().required('Bitte eine Überschrift eingeben'),
    text: Yup.string().required('Bitte einen Text eingeben').max(1000, 'Der Text für die Call-To-Action ist zu lang'),
    url: Yup.string().required('Bitte eine URL eingeben'),
    button_text: Yup.string().required('Bitte einen Text für den Button eingeben'),
  });

  function onSubmit(values, {setSubmitting}) {
    crud.data.create({entity: 'cta', item: {...values}})
      .then(() => setShowSuccess(true))
      .finally(() => setSubmitting(false))
      .catch(errors => setErrors(errors));
  }

  return (
    <main>
      <Title>
        <TitleHeading>Call-To-Action erstellen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/cta">Call-To-Actions</NavLink>
          Neu
        </Breadcrumb>
      </Title>
      <Stage>
        <Formik
          initialValues={{headline: '', text: '', url: '', button_text: ''}}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          onReset={() => setShowSuccess(false)}
        >
          {({isSubmitting, touched, errors}) => (
            <Form className="mt-1-75">
              <TextInput
                name="headline" label="Überschrift*" placeholder="Bsp. Bitte bewerte uns"
                className="mb-1"
              />
              <TextareaInput
                className="mb-1" name="text" label="Text*"
                placeholder={
                  "Bsp. Wenn Ihnen dieses Angebot gefallen hat, würden wir uns über Feedback freuen," +
                  " bitte klicken Sie dazu auf folgenden Button"
                }
              />
              <TextInput name="url" label="URL" placeholder="Bsp. https://example.com/sdfsdf" className="mb-1"/>
              <TextInput
                name="button_text" label="Buttonbeschriftung" placeholder="Jetzt bewerten"
              />
              <ButtonBar>
                <Button
                  color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück" onClick={() => navigate(-1)}
                />
                <Button icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                  text="Speichern" type="submit" disabled={isSubmitting}/>
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal
                title="Die Call-ToAction wurde erfolgreich erstellt" show={showSuccess}
                onDismiss={() => navigate('/cta')}
              >
                <ButtonBar>
                  <Button type="reset" color="gray" text="Weitere Call-To-Action erstellen"/>
                  <Button text="Zurück zur Übersicht" onClick={() => navigate(-1)}/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
