import './Row.scss';

export default function Row({className, lgWidth, lgMaxItems, style = {}, ...props}) {
  if (lgWidth) {
    style['--lg-width'] = `calc(${lgWidth} - 1.75rem / 2)`;
  }
  if (lgMaxItems) {
    style['--lg-max-items'] = `0 0 calc(${99.9 / lgMaxItems}% - ${lgMaxItems - 1} * 1.75rem / ${lgMaxItems})`;
  }
  return (
    <div className={'Row' + (className ? ` ${className}`  : '')} style={Object.keys(style).length > 0 ? style : null}>
      {props.children}
    </div>
  );
}
