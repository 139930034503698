import config from "../config.json";
import useCRUD from "./../hooks/useCRUD";
import {useMemo} from "react";

export default function useFiles() {

  const crud = useCRUD();
  
  return useMemo(() => ({
    upload: files => new Promise((resolve, reject) => {
      let formData = new FormData();
      if (Array.isArray(files)) {
        files.forEach((file, index) => formData.append('file' + index, file));
      } else {
        formData.append('file', files);
      }
      fetch(
        config.FILES_URL,
        {
          method: 'POST',
          body: formData
        })
        .then(response => {
          if (response.status !== 200) {
            reject([{message: 'Interner Fehler im Files-System.'}]);
          } else {
            response.json()
              .then(result => {
                if ('errors' in result && result.errors.length > 0) {
                  reject(result.errors);
                } else {
                  const items = Object.entries(result.result).map(entry => entry[1]);
                  crud.data.bulk.create({entity: 'file', items})
                    .then(result => Array.isArray(files) ? resolve(result) : resolve({...items[0],...result[0]}))
                    .catch(errors => reject(errors));
                }
              })
              .catch(() => {
                return [{message: 'Fehler beim Verarbeiten der Antwort aus dem Files-System.'}];
              });
          }
        })
        .catch(error => {
          console.error(error);
          reject([{
            message: 'Die Verbindung zum Files-System kann nicht hergestellt werden.'
          }]);
        });
    })
  }), [crud.data.bulk]);

}
