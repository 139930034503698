export {MemoImage};

function MemoImage(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1080px" height="720px" viewBox="0 70 210 130">
      <g className="left-wheel">
        <animateTransform attributeName="transform" attributeType="XML"
          dur={props.playAnimation ? "3s" : "0s"} from="0 62 127.5" repeatCount="indefinite"
          to="360 62 127.5" type="rotate"/>
        <circle className="tape-color" r="17.5" cx="62" cy="127.5" strokeWidth="14" fill="none"/>
        <circle className="accent-color" r="9.8889723" cx="62" cy="127.5" strokeDasharray="6.2" strokeWidth="2"
          fill="none"/>
        <circle className="accent-color" r="25" cx="62" cy="127.5" strokeWidth="1" fill="none"/>
      </g>
      <g className="right-wheel">
        <animateTransform attributeName="transform" attributeType="XML"
          dur={props.playAnimation ? "3s" : "0s"} from="0 150 127.5" repeatCount="indefinite"
          to="360 150 127.5" type="rotate"/>
        <circle className="tape-color" r="17.5" cx="150" cy="127.5" strokeWidth="14" fill="none"/>
        <circle className="accent-color" r="9.8889723" cx="150" cy="127.5" strokeDasharray="6.2" strokeDashoffset="6.2"
          strokeWidth="2" fill={"none"}/>
        <circle className="accent-color" r="25" cx="150" cy="127.5" strokeWidth="1" fill="none"/>
      </g>
      <g className="case">
        <path
          className="outline-color first-color" transform="scale(0.26458333)" strokeWidth="7.5"
          d="m 44.642578,274.01953 c -8.705722,0 -15.714844,7.00912 -15.714844,15.71485 v 427.70703 c 0,8.70572
            7.009122,15.71289 15.714844,15.71289 H 752.5 c 8.70572,0 15.71484,-7.00717 15.71484,-15.71289 V 289.73438 c
            0,-8.70573 -7.00912,-15.71485 -15.71484,-15.71485 z M 185.13672,418.26758 h 426.97266 c 8.70571,0
            15.71484,7.00717 15.71484,15.71289 v 99.2207 c 0,8.70572 -7.00913,15.71485 -15.71484,15.71485 H 185.13672 c
            -8.70572,0 -15.71484,-7.00913 -15.71484,-15.71485 v -99.2207 c 0,-8.70572 7.00912,-15.71289
            15.71484,-15.71289 z"
        />
      </g>
      <g className="upper-decoration">
        <animateTransform attributeName="transform" attributeType="XML" dur="3s" repeatCount="indefinite"/>
        <rect className="second-color" ry="4.8593636" x="23.325853" y="77.961723" height="28.493513" width="163.9166"/>
        <rect className="accent-color" rx="4.4330897" ry="1" x="27.537189" y="83.554153" height="1.56" width="155"/>
        <rect className="accent-color" rx="4.4330897" ry="1" x="27.348202" y="90.543762" width="155" height="1.5"/>
        <rect className="accent-color" rx="4.4330897" ry="1" x="27.159214" y="97.533371" height="1.5" width="155"/>
      </g>
      <g className="lower-decoration">
        <path className="second-color" d="M 49.514883,170.0593 H 159.50597 l 14.55208,21.29637 H 33.261906 Z"
          strokeWidth="0"/>
        <ellipse className="accent-color" ry="2.3623512" rx="2.2678571" cy="187.4814" cx="48.191967"/>
        <ellipse className="accent-color" cx="61.984024" cy="179.16592" rx="2.0076311" ry="2.3623598"/>
        <ellipse className="accent-color" transform="rotate(-81.878133)" cx="-163.12906" cy="183.16606" rx="2.2678571"
          ry="2.3623512"/>
        <ellipse className="accent-color" transform="matrix(0.1253384,-0.99211405,0.98725374,0.15915415,0,0)"
          ry="2.0970359" rx="2.2629635" cy="167.91661" cx="-153.38391"/>
      </g>
    </svg>
  );
}
