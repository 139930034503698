import TitleHeading from "../components/TitleHeading";
import Breadcrumb from "../components/Breadcrumb";
import {NavLink} from "react-router-dom";
import Title from "../components/Title";
import Stage from "../components/Stage";
import ButtonLink from "../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../components/Icons";
import Button from "../components/Button";
import ButtonBar from "../components/ButtonBar";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../components/TextInput";
import FormErrors from "../components/FormErrors";
import DropzoneInput from "../components/DropzoneInput";
import ErrorModal from "../components/ErrorModal";
import {useEffect, useState} from "react";
import Row from "../components/Row";
import Modal from "../components/Modal";
import {useNavigate} from "react-router-dom";
import useCRUD from "../hooks/useCRUD";
import useFiles from "../hooks/useFiles";
import useBL from "../hooks/useBL";
import TextareaInput from "../components/TextareaInput";

export default function CreateContactPerson() {

  const crud = useCRUD();
  const files = useFiles();
  const navigate = useNavigate();
  const bl = useBL();
  const [errors, setErrors] = useState([]);
  const [limitError, setLimitError] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const initialValues = {
    first_name: '',
    last_name: '',
    position: '',
    e_mail: '',
    phone: '',
    mobile: '',
    calendar_url: '',
    whatsapp: '',
    portrait: null
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required('Bitte einen Vornamen eingeben'),
    last_name: Yup.string().required('Bitte einen Nachnamen eingeben'),
    e_mail: Yup.string().required('Bitte eine E-Mail-Adresse eingeben')
      .email('Bitte eine gültige E-Mail-Adresse eingeben')
  });

  const submit = (values, {setSubmitting}) => {
    let item = {...values};
    delete item.portrait;
    const save = () => {
      bl.create_contact_person({item, url: window.location.origin + '/passwort-zuruecksetzen/TOKEN'})
        .then(() => setShowSuccess(true))
        .catch(errors => setErrors(errors))
        .finally(() => setSubmitting(false));
    };
    if (values.portrait) {
      files.upload(values.portrait)
        .then(result => {
          item.portrait_id = result.id;
          save();
        })
        .catch(errors => {
          setErrors(errors);
          setSubmitting(false);
        });
    } else {
      save();
    }
  };

  useEffect(() => {
    if (!showSuccess) {
      Promise.all([
        crud.data.read({entity: 'settings', id: 1}),
        crud.data.bulk.read({entity: 'contact_person', page_size: 0})
      ])
        .then(([settings, contact_person]) => {
          if (contact_person.total >= settings.allowed_users) {
            setLimitError([{
              message: "Anzahl der möglichen Ansprechpartner überschritten. Erlaubte Ansprechpartner: " +
                settings.allowed_users
            }]);
          }
        })
        .catch(errors => setErrors(errors));
    }
  }, [crud.data, showSuccess]);

  return (
    <main>
      <Title>
        <TitleHeading>Ansprechpartner erstellen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/ansprechpartner">Ansprechpartner</NavLink>
          Neu
        </Breadcrumb>
      </Title>
      <Stage>
        <Formik
          initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}
          onReset={() => setShowSuccess(false)}
        >
          {({isSubmitting, touched, errors}) => (
            <Form>
              <Row>
                <TextInput name="first_name" label="Vorname*" placeholder="Bsp. Sascha"/>
                <TextInput name="last_name" label="Nachname*" placeholder="Bsp. Weinrich"/>
              </Row>
              <Row>
                <TextareaInput name="position" label="Position" placeholder="Bsp. Geschäftsführer"/>
                <TextInput name="e_mail" label="E-Mail*" placeholder="Bsp. info@vyn.de"/>
              </Row>
              <Row>
                <TextInput name="phone" label="Telefon" placeholder="Bsp. 0271 38 79 79 90"/>
                <TextInput name="mobile" label="Mobil" placeholder="Bsp. 0167 11122233"/>
              </Row>
              <Row>
                <TextInput name="calendar_url" label="Link zum persönlichen Kalender"
                  placeholder="Bsp. https://example.com/my-calendar"/>
                <TextInput name="whatsapp" label="WhatsApp-Telefonnummer" placeholder="Bsp. 01711234567"/>
              </Row>
              <DropzoneInput name="portrait" label="Foto" className="mt-1-75"/>
              <ButtonBar>
                <ButtonLink
                  to="/ansprechpartner" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                />
                <Button
                  icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                  text="Speichern" type="submit" disabled={isSubmitting}
                />
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal
                title="Der Ansprechpartner wurde erfolgreich erstellt" show={showSuccess}
                onDismiss={() => navigate('/ansprechpartner')}
              >
                <ButtonBar>
                  <Button type="reset" color="gray" text="Weiteren Ansprechpartner erstellen"/>
                  <ButtonLink to="/ansprechpartner" text="Zurück zur Übersicht"/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        <ErrorModal errors={limitError} onDismiss={() => navigate(-1)}/>
      </Stage>
    </main>
  );
}
