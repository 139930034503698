import "./FactWidget.scss";
import Widget from "./Widget";

export default function FactWidget({number, description, details, color = 'turquoise'}) {
  return (
    <Widget className={'FactWidget' + (details ? ' has-details' : '')}>
      <p className={'number ' + color}>{number}</p>
      <p title={description} className="description">{description}</p>
      {details && <p title={details} className="details">{details}</p>}
    </Widget>
  );
}
