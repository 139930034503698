import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import Stage from "../../components/Stage";
import Title from "../../components/Title";
import TitleHeading from "../../components/TitleHeading";
import Template from "./create/template";
import General from "./create/general";
import Introduction from "./create/introduction";
import Explanation from "./create/explanation";
import Elements from "./create/elements";
import {useContext, useEffect, useState} from "react";
import ErrorModal from "../../components/ErrorModal";
import {OfferContext} from "../../contexts/OfferContext";

export default function Create() {

  const navigate = useNavigate();
  const offerContext = useContext(OfferContext);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (Object.keys(offerContext.offer).length === 0) {
      navigate('/angebote');
    }
  }, [navigate, offerContext.offer]);

  return (
    <main>
      <Title>
        <TitleHeading>Angebote</TitleHeading>
      </Title>
      <Stage>
        <Routes>
          <Route path="" element={<Navigate to="vorlage" replace={true}/>}/>
          <Route path="vorlage" element={<Template/>}/>
          <Route path="allgemeines" element={<General/>}/>
          <Route path="einleitung" element={<Introduction/>}/>
          <Route path="angebots-erlaeuterung" element={<Explanation/>}/>
          <Route path="elemente" element={<Elements/>}/>
          <Route path="*" element={<Navigate to="vorlage" replace={true}/>}/>
        </Routes>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
