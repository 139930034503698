export default function PartyPopper() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 164">
      <defs>
        <linearGradient id="a" x1=".5" x2=".5" y2="1">
          <stop offset="0" stopColor="#9a56ff"/>
          <stop offset="1" stopColor="#d78afd"/>
        </linearGradient>
      </defs>
      <g fill="url(#a)">
        <path
          d="M49.8 61.4a5.1 5.1 0 0 1 7.4 7.2H57c-1.7 1.8-2 4.5-1.7 7.4.8 6.2 4.8 13.5 11.1 19.8a36.6 36.6 0 0 0 20
            11.2c3 .4 5.6 0 7.4-1.7a5.1 5.1 0 0 1 7.2 7.3l-.2.2C77.4 136 39.5 149 23.7 151.6a26.9 26.9 0 0 1-7.5.4 6.8
            6.8 0 0 1-4-1.9 6.8 6.8 0 0 1-1.8-4 27 27 0 0 1 .4-7.4c2.6-16 15.6-53.8 39-77.2Zm-3.7 20.3a166 166 0 0
            0-23.8 52.4 67.8 67.8 0 0 0-1.4 7.3 66.8 66.8 0 0 0 7.4-1.3 166 166 0 0 0
            52.3-23.9c-7-2-14.6-6.4-21.3-13.1S48 88.7 46.2 81.7m2.6-61.2a5.1 5.1 0 1 0-5.1-5 5.1 5.1 0 0 0 5.1 5m59.1
            0a5.1 5.1 0 1 0-5.2-5 5.1 5.1 0 0 0 5.2 5m38.5 79.7a5.1 5.1 0 1 0-5.1-5.2 5.1 5.1 0 0 0 5.1 5.2"
        />
        <path
          d="M79.8 83.4C99.8 79 114 81 130 86a5.1 5.1 0 0 1-3.1 9.8c-15.8-5-30-6.5-51-.7a5.1 5.1 0 0 1-6.5-5.2C70.7
            67.4 63.9 49 55 33.3a5.1 5.1 0 1 1 8.9-5 112 112 0 0 1 15.8 55.1"
        />
        <path
          d="M68 67.6c-4.4-1.4-8.4-1.4-10.9 1-1.7 1.7-2 4.4-1.7 7.4.8 6.2 4.8 13.5 11.1 19.8s13.7 10.4 19.9 11.1c3 .4
            5.6 0 7.4-1.7a5.1 5.1 0 1 1 7.2 7.3 18.6 18.6 0 0 1-15.9
            4.6c-8-1-17.6-5.8-25.8-14s-13-17.8-14-25.9c-.9-6.5.8-12 4.6-15.9C55.2 56 64.5 55 74.4 59a5.1 5.1 0 0 1 3.1
            3.7 101.8 101.8 0 0 1 2.3 20.8A100.5 100.5 0 0 1 96.6 81a5.1 5.1 0 1 1 .5 10.2A98.9 98.9 0 0 0 75.8 95a5.1
            5.1 0 0 1-6.4-5.2A92 92 0 0 0 68 67.6"
        />
        <path
          d="M78.4 93.4c18.1-20.8 31.1-37 34-56.7a5.1 5.1 0 0 0-10.1-1.5c-2.7 18-15.1 32.5-31.7 51.5a5.1 5.1 0 1 0 7.8
            6.7m67.4-64.9-8.8 3a11.3 11.3 0 0 0-4.9 18.2 1 1 0 0 1-.4 1.6l-8.8 3a5.1 5.1 0 1 0 3.3 9.7l8.8-3a11.3 11.3 0
            0 0 4.8-18.1 1 1 0 0 1 .5-1.7l8.8-3a5.1 5.1 0 0 0-3.3-9.7m-17.1 86.1-6.2 3a5.1 5.1 0 0 1-4.6-9.2l6.6-3.2a10
            10 0 0 1 14.4 8l6.2-3.1a5.1 5.1 0 1 1 4.6 9.2l-6.6 3.2a10 10 0 0 1-14.4-8M84 22l-2.2-4.3A5.1 5.1 0 0 1 91
            13s1.4 2.8 2.8 5.6A9.3 9.3 0 0 1 87.5 32l2.1 4.4a5.1 5.1 0 0 1-9.2 4.5l-2.7-5.6A9.3 9.3 0 0 1 84 22M32.8
            36.7a9.3 9.3 0 0 1-9.5-11l1.2-6.2a5.1 5.1 0 1 1 10 2l-.9 4.8a9.3 9.3 0 0 1 9.5 11.1L42 43.5a5.1 5.1 0 0
            1-10-2Z"
        />
      </g>
    </svg>

  );
}
