import "./Pagination.scss";
import {ChevronRightIcon, ChevronLeftIcon} from "./Icons";

export default function Pagination({total, page, pageSize, setPage, className, onDragOver = (e, index) => {}}) {

  const totalPages = Math.ceil(total / pageSize);

  let showPages = [];
  for (let i = Math.max(page - 3, 0); i <= Math.min(page + 3, totalPages - 1); i++) {
    showPages.push(i);
  }

  return (
    <div className={'Pagination' + (className ? ` ${className}` : '')}>
      <div
        className={['arrow-left'].concat(page === 0 ? 'inactive' : []).join(' ')}
        onClick={() => setPage(Math.max(page - 1, 0))}
        onDragOver={e => onDragOver(e, Math.max(page - 1, 0))}
      >
        <ChevronLeftIcon/>
      </div>
      <div className="pages">
        {
          showPages.map(showPage => (
            <button
              key={showPage} className={showPage === page ? 'active' : null} onClick={() => setPage(showPage)}
              onDragOver={e => onDragOver(e, showPage)}
            >
              <span>{showPage + 1 < 10 ? '0' + (showPage + 1) : showPage + 1}</span>
            </button>
          ))
        }
      </div>
      <div
        className={['arrow-right'].concat(page >= totalPages - 1 ? 'inactive' : []).join(' ')}
        onClick={() => setPage(Math.min(page + 1, totalPages - 1))}
        onDragOver={e => onDragOver(e, Math.min(page + 1, totalPages - 1))}
      >
        <ChevronRightIcon/>
      </div>
    </div>
  );

}
