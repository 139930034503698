import Title from '../components/Title';
import TitleHeading from '../components/TitleHeading';
import Stage from "../components/Stage";
import ButtonBar from "../components/ButtonBar";
import ButtonLink from "../components/ButtonLink";
import {EditIcon, PlusCircleIcon, TrashIcon} from "../components/Icons";
import {useState} from "react";
import ErrorModal from "../components/ErrorModal";
import useCRUD from "../hooks/useCRUD";
import Table from "../components/Table";
import Column from "../components/Column";
import RowActionLink from "../components/RowActionLink";
import RowActionButton from "../components/RowActionButton";
import Modal from "../components/Modal";
import Button from "../components/Button";

export default function Texte() {

  const crud = useCRUD();

  const [errors, setErrors] = useState([]);
  const [tableKey, setTableKey] = useState(1);
  const [deleteTranslation, setDeleteTranslation] = useState(null);

  const doDelete = () => {
    crud.data.delete({'entity': 'translation', id: deleteTranslation.id})
      .then(() => {
        setDeleteTranslation(null);
        setTableKey(tableKey + 1);
      })
      .catch(errors => setErrors(errors));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Texte</TitleHeading>
      </Title>
      <Stage>
        <Table name="translation" entity="translation" defaultOrderBy="name" key={tableKey}>
          <Column title="Name" property="name"/>
          <Column type="actions">
            <RowActionLink to={(row) => row.id + '/bearbeiten'} color="blue" title="Bearbeiten">
              <EditIcon/>
            </RowActionLink>
            <RowActionButton color="red" title="Löschen" onClick={setDeleteTranslation}><TrashIcon/></RowActionButton>
          </Column>
        </Table>
        <ButtonBar>
          <ButtonLink to="/texte/neu" text="Hinzufügen" icon={<PlusCircleIcon/>}/>
        </ButtonBar>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        {
          deleteTranslation &&
          <Modal title="Löschen bestätigen" onDismiss={() => setDeleteTranslation(null)}>
            <p>Möchten Sie die Texte „{deleteTranslation.name}“ wirklich löschen?</p>
            <ButtonBar>
              <Button text="Behalten" onClick={() => setDeleteTranslation(null)}/>
              <Button color="red" text="Löschen" icon={<TrashIcon/>} onClick={doDelete}/>
            </ButtonBar>
          </Modal>
        }
      </Stage>
    </main>
  );
}
