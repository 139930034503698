import Title from '../components/Title';
import TitleHeading from '../components/TitleHeading';
import Stage from "../components/Stage";
import Table from "../components/Table";
import RowActionButton from "../components/RowActionButton";
import {DuplicateIcon, EditIcon, LoaderIcon, PlusCircleIcon, TrashIcon} from "../components/Icons";
import Button from "../components/Button";
import Column from "../components/Column";
import {useEffect, useState} from "react";
import ErrorModal from "../components/ErrorModal";
import ButtonBar from "../components/ButtonBar";
import ButtonLink from "../components/ButtonLink";
import Modal from "../components/Modal";
import {NavLink} from "react-router-dom";
import RowActionLink from "../components/RowActionLink";
import useCRUD from "../hooks/useCRUD";
import TextTeaser from "../components/TextTeaser";

export default function Cta() {
  const crud = useCRUD();
  const [errors, setErrors] = useState([]);

  const [duplicating, setDuplicating] = useState(false);
  const [duplicateRow, setDuplicateRow] = useState(null);
  const doDuplicate = () => {
    setDuplicating(true);
    crud.data.read({entity: 'cta', id: duplicateRow.id})
      .then(ctaResult => {
        const {id, revision, created_at, updated_at, deleted_at, ...newCta} = {
          ...ctaResult,
          headline: `${ctaResult.headline} (Kopie)`
        };
        crud.data.create({entity: 'cta', item: newCta})
          .catch(errors => setErrors(errors))
          .finally(() => {
            setDuplicateRow(null);
            setTableKey(tableKey => tableKey + 1);
            setDuplicating(false);
          });
      })
      .catch(errors => setErrors(errors));
  };

  const [deleteRow, setDeleteRow] = useState(null);
  const doDelete = () => {
    crud.data.delete({entity: 'cta', id: deleteRow.id})
      .then(() => {
        setDeleteRow(null);
        setTableKey(tableKey + 1);
      })
      .catch(errors => setErrors(errors));
  };

  const [teaserWidth, setTeaserWidth] = useState();
  useEffect(() => {
    const handleResize = () => setTeaserWidth(window.innerWidth / 3);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [tableKey, setTableKey] = useState(1);
  const transformHeadline = (headline, cta) => <NavLink to={cta.id + '/'}>{headline}</NavLink>;
  const transformContent = content => <TextTeaser width={teaserWidth}>{content}</TextTeaser>;

  return (
    <main>
      <Title>
        <TitleHeading>Call-To-Actions</TitleHeading>
      </Title>

      <Stage>
        <Table name="cta" entity="cta" defaultOrderBy="headline" key={tableKey}>
          <Column title="Überschrift" property="headline" transform={transformHeadline}/>
          <Column title="Text" property="text" transform={transformContent}/>
          <Column type="actions">
            <RowActionLink to={(row) => row.id + '/bearbeiten'} color="blue" title="Bearbeiten">
              <EditIcon/>
            </RowActionLink>
            <RowActionButton color="turquoise" title="Duplizieren" onClick={setDuplicateRow}>
              <DuplicateIcon/>
            </RowActionButton>
            <RowActionButton color="red" title="Löschen" onClick={setDeleteRow}><TrashIcon/></RowActionButton>
          </Column>
        </Table>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        {
          duplicateRow &&
          <Modal title="Duplizieren bestätigen" onDismiss={() => setDuplicateRow(null)}>
            <p>Möchten Sie die Call-To-Action „{duplicateRow.headline}“ wirklich duplizieren?</p>
            <ButtonBar>
              <Button color="grey" text="Abbrechen" disabled={duplicating} onClick={() => setDuplicateRow(null)}/>
              <Button
                color="turquoise" text="Duplizieren" icon={duplicating ? <LoaderIcon/> : <DuplicateIcon/>}
                disabled={duplicating} onClick={doDuplicate}
              />
            </ButtonBar>
          </Modal>
        }
        {
          deleteRow &&
          <Modal title="Löschen bestätigen" onDismiss={() => setDeleteRow(null)}>
            <p>Möchten Sie die Call-To-Action „{deleteRow.name}“ wirklich löschen?</p>
            <ButtonBar>
              <Button text="Behalten" onClick={() => setDeleteRow(null)}/>
              <Button color="red" text="Löschen" icon={<TrashIcon/>} onClick={doDelete}/>
            </ButtonBar>
          </Modal>
        }
        <ButtonBar>
          <ButtonLink to="/cta/neu" text="Neue Call-To-Action anlegen" icon={<PlusCircleIcon/>}/>
        </ButtonBar>
      </Stage>
    </main>
  );

}
