import React, {useContext, useEffect, useState} from "react";
import useCRUD from "../hooks/useCRUD";
import useTemplates from "../hooks/useTemplates";
import ErrorModal from "../components/ErrorModal";
import {AuthContext} from "./AuthContext";

export const TemplateContext = React.createContext({template: null, setTemplate: template => {}});

export function TemplateContextProvider({children}) {

  const [template, setTemplate] = useState(null);
  const [errors, setErrors] = useState([]);
  const crud = useCRUD();
  const authContext = useContext(AuthContext);
  const templates = useTemplates();

  useEffect(() => {
    if (authContext.authToken) {
      crud.data.read({entity: 'design', id: 1})
        .then(design => setTemplate(templates.find(template => template.id === parseInt(design.template))))
        .catch(errors => setErrors(errors));
    }
  }, [authContext.authToken, crud, templates]);

  return (
    <TemplateContext.Provider value={{template, setTemplate}}>
      {children}
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </TemplateContext.Provider>
  );

}
