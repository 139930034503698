import Title from '../components/Title';
import TitleHeading from '../components/TitleHeading';
import Stage from "../components/Stage";
import StageTitle from "../components/StageTitle";
import ColorInput from "../components/ColorInput";
import {Form, Formik} from "formik";
import Row from "../components/Row";
import ButtonBar from "../components/ButtonBar";
import Button from "../components/Button";
import FormErrors from "../components/FormErrors";
import DropzoneInput from "../components/DropzoneInput";
import DesignTemplate from "../components/DesignTemplate";
import {useContext, useEffect, useState} from "react";
import IconWithText from "../components/IconWithText";
import {ChevronRightIcon, LoaderIcon, TrashIcon} from "../components/Icons";
import ImageInput from "../components/ImageInput";
import RowActionButton from "../components/RowActionButton";
import ErrorModal from "../components/ErrorModal";
import Modal from "../components/Modal";
import useCRUD from "../hooks/useCRUD";
import useFiles from "../hooks/useFiles";
import Flex from "../components/Flex";
import Switch from "../components/Switch";
import useTemplates from "../hooks/useTemplates";
import {TemplateContext} from "../contexts/TemplateContext";

export default function Design() {

  const crud = useCRUD();
  const files = useFiles();
  const templates = useTemplates();
  const templateContext = useContext(TemplateContext);
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [activeTemplateId, setActiveTemplateId] = useState(null);
  const [lightMode, setLightMode] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [showBranding, setShowBranding] = useState(true);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      try {
        let resultRead = await crud.data.read({entity: 'design', id: 1});
        for (let file_id of [resultRead.logo_id, resultRead.header_image_id]) {
          if (file_id) {
            let index = [resultRead.logo_id, resultRead.header_image_id].indexOf(file_id);
            let resultFile = await crud.data.read({entity: 'file', id: parseInt(file_id)});
            resultRead[`${index === 0 ? 'logo' : 'header_image'}`] = {...resultFile};
          }
        }
        setItem({...resultRead});
        setActiveTemplateId(resultRead.template);
        setLightMode(resultRead.light_mode === '1');
        setDarkMode(resultRead.dark_mode === '1');
        setShowBranding(resultRead.show_branding === '1');
      } catch (errors) {
        setErrors(errors);
      }
    }
    fetchData().finally(() => setLoading(false));
  }, [crud.data]);

  const submit = (values, {setSubmitting}) => {
    let newItem = {...values};
    let logo = item.logo;
    let header_image = item.header_image;
    delete newItem.logo;
    delete newItem.header_image;
    async function save() {
      try {
        for (let file of [values.logo, values.header_image]) {
          if (file) {
            let index = [values.logo, values.header_image].indexOf(file);
            let resultUpload = await files.upload(file);
            if (index === 0) {
              newItem.logo_id = resultUpload.id;
              logo = {...resultUpload};
            } else {
              newItem.header_image_id = resultUpload.id;
              header_image = {...resultUpload};
            }

          }
        }
        newItem.light_mode = lightMode ? 1 : 0;
        newItem.dark_mode = darkMode ? 1 : 0;
        newItem.show_branding = showBranding ? 1 : 0;
        await crud.data.update({entity: 'design', id: 1, update: newItem});
        if (logo) {
          newItem.logo = logo;
        }
        if (header_image) {
          newItem.header_image = header_image;
        }
        setItem({...newItem});
        templateContext.setTemplate(get_active_template_data());
        setShowSuccess(true);
      } catch (errors) {
        setErrors(errors);
      }
    }
    save().finally(() => setSubmitting(false));
  };

  function get_active_template_data () {
    let result = null;
    templates.forEach(template => {
      if (parseInt(template.id) === parseInt(activeTemplateId)) {
        result = template;
      }
    });
    return result;
  }

  return (
    <main>
      <Title>
        <TitleHeading>Design</TitleHeading>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
          <Formik
            initialValues={{
              template: item.template,
              accent_color: item.accent_color,
              logo_id: item.logo_id,
              header_image_id: item.header_image_id
            }}
            onSubmit={submit}
          >
            {({values, isSubmitting, touched, errors, setFieldValue}) => (
              <Form>
                <StageTitle>Vorlagen</StageTitle>
                <hr/>
                <Flex>
                  {templates.map(template => {
                    return <DesignTemplate
                      key={template.id} src={`/${template.previewImage}`}
                      setActive={() => {
                        setActiveTemplateId(template.id);
                        values.template = template.id;
                        item.template = template.id;
                        setItem({...item});
                      }}
                      active={parseInt(activeTemplateId) === template.id}></DesignTemplate>;
                  })}
                </Flex>
                <hr/>
                <StageTitle>Logo</StageTitle>
                {!item.logo && <DropzoneInput name="logo" text="Logo hochladen"/>}
                {
                  item.logo &&
                  <Row lgWidth="25%">
                    <ImageInput lable="Logo">
                      <RowActionButton title="Entfernen" color="red" type="button" onClick={() => {
                        const newItem = {...item};
                        delete newItem.logo;
                        newItem.logo_id = null;
                        setItem(newItem);
                        setFieldValue('logo_id', null);
                      }}><TrashIcon/></RowActionButton>
                      <img src={item.logo.url} alt="Logo"/>
                    </ImageInput>
                  </Row>
                }
                <hr/>
                <StageTitle>Farben</StageTitle>
                <Row lgWidth="37.5% - .5rem">
                  <ColorInput label="Akzentfarbe" name="accent_color"></ColorInput>
                </Row>
                {
                  get_active_template_data().options.includes('light-mode') ?
                    <div style={{marginTop: '1.75rem', display: 'flex', alignItems: 'center', gap: '.5rem'}}>
                      <span style={{cursor: 'pointer'}} onClick={() => setLightMode(!lightMode)}>Heller Modus</span>
                      <Switch onClick={() => setLightMode(!lightMode)} active={lightMode}/>
                    </div> : null
                }
                {
                  get_active_template_data().options.includes('dark-mode') ?
                    <div style={{marginTop: '1.75rem', display: 'flex', alignItems: 'center', gap: '.5rem'}}>
                      <span style={{cursor: 'pointer'}} onClick={() => setDarkMode(!darkMode)}>Dunkler Modus</span>
                      <Switch onClick={() => setDarkMode(!darkMode)} active={darkMode}/>
                    </div> : null
                }
                <hr/>
                <StageTitle>Branding</StageTitle>
                <Row style={{rowGap: '.75rem'}}>
                  <label onClick={() => setShowBranding(x => !x)} style={{flex: '0 auto', width: 'auto'}}>
                    „Powered by Sellestar“ - Branding anzeigen
                  </label>
                  <span>
                    <Switch
                      name="show_branding"
                      onClick={() => setShowBranding(x => !x)}
                      active={showBranding}
                    />
                  </span>
                </Row>
                <hr/>
                <StageTitle>Headerbild</StageTitle>
                {!item.header_image && <DropzoneInput name="header_image" text="Headerbild hochladen"/>}
                {
                  item.header_image &&
                  <Row lgWidth="25%">
                    <ImageInput lable="Headerbild">
                      <RowActionButton title="Entfernen" color="red" type="button" onClick={() => {
                        const newItem = {...item};
                        delete newItem.header_image;
                        newItem.header_image_id = null;
                        setItem(newItem);
                        setFieldValue('header_image_id', null);
                      }}><TrashIcon/></RowActionButton>
                      <img src={item.header_image.url} alt="Headerbild"/>
                    </ImageInput>
                  </Row>
                }
                <hr/>
                <ButtonBar>
                  <Button
                    icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>} text="Speichern" type="submit"
                    disabled={isSubmitting}
                  />
                </ButtonBar>
                <FormErrors touched={touched} errors={errors}/>
                <Modal
                  title="Ihre Daten wurden erfolgreich gespeichert"
                  show={showSuccess}
                  onDismiss={() => setShowSuccess(false)}
                >
                  <ButtonBar>
                    <Button type="button" text="Ok" onClick={() => setShowSuccess(false)}/>
                  </ButtonBar>
                </Modal>
              </Form>
            )}
          </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
