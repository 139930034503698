import TitleHeading from "../components/TitleHeading";
import {useParams} from "react-router-dom";
import Title from "../components/Title";
import Stage from "../components/Stage";
import ButtonLink from "../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon, TrashIcon} from "../components/Icons";
import Button from "../components/Button";
import ButtonBar from "../components/ButtonBar";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../components/TextInput";
import FormErrors from "../components/FormErrors";
import ErrorModal from "../components/ErrorModal";
import {useState} from "react";
import Row from "../components/Row";
import Modal from "../components/Modal";
import {useEffect} from "react";
import IconWithText from "../components/IconWithText";
import DropzoneInput from "../components/DropzoneInput";
import ImageInput from "../components/ImageInput";
import RowActionButton from "../components/RowActionButton";
import useCRUD from "../hooks/useCRUD";
import useFiles from "../hooks/useFiles";
import useBL from "../hooks/useBL";
import TextareaInput from "../components/TextareaInput";

export default function EditContactPerson() {

  const crud = useCRUD();
  const bl = useBL();
  const files = useFiles();
  const params = useParams();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    setLoading(true);
    crud.data.read({entity: 'contact_person', id: parseInt(params.id)})
      .then(contactPerson => {
        if (contactPerson.portrait_id) {
          crud.data.read({entity: 'file', id: parseInt(contactPerson.portrait_id)})
            .then(portrait => {
              contactPerson.portrait = {...portrait};
              setItem({...contactPerson});
            })
            .catch(errors => setErrors(errors))
            .finally(() => setLoading(false));
        } else {
          setItem({...contactPerson});
          setLoading(false);
        }
      })
      .catch(errors => {
        setErrors(errors);
        setLoading(false);
      });
  }, [crud.data, params.id]);

  const validationSchema = Yup.object({
    first_name: Yup.string().required('Bitte geben Sie einen Vornamen ein'),
    last_name: Yup.string().required('Bitte geben Sie einen Nachnamen ein'),
    e_mail: Yup.string().required('Bitte geben Sie eine E-Mail-Adresse ein')
      .email('Bitte geben Sie eine gültige E-Mail-Adresse ein')
  });

  const submit = (values, {setSubmitting}) => {
    setSubmitting(true);
    let item = {...values};
    delete item.portrait;
    const save = () => {
      bl.update_contact_person({item, id: params.id})
        .then(() => setShowSuccess(true))
        .catch(errors => setErrors(errors))
        .finally(() => setSubmitting(false));
    };
    if (values.portrait) {
      files.upload(values.portrait)
        .then(result => {
          item.portrait_id = result.id;
          save();
          item.portrait = {...result};
          setItem({...item});
        })
        .catch(errors => {
          setErrors(errors);
          setSubmitting(false);
        });
    } else {
      save();
    }
  };

  return (
    <main>
      <Title>
        <TitleHeading>Ansprechpartner bearbeiten</TitleHeading>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
          <Formik
            initialValues={{
              first_name: item.first_name || '',
              last_name: item.last_name || '',
              position: item.position || '',
              e_mail: item.e_mail || '',
              phone: item.phone || '',
              mobile: item.mobile || '',
              calendar_url: item.calendar_url || '',
              whatsapp: item.whatsapp || '',
              portrait_id: item.portrait_id
            }}
            validationSchema={validationSchema} onSubmit={submit} onReset={() => setShowSuccess(false)}
          >
            {({isSubmitting, touched, errors, setFieldValue}) => (
              <Form>
                <Row>
                  <TextInput name="first_name" label="Vorname*" placeholder="Bsp. Sascha"/>
                  <TextInput name="last_name" label="Nachname*" placeholder="Bsp. Weinrich"/>
                </Row>
                <Row>
                  <TextareaInput name="position" label="Position" placeholder="Bsp. Geschäftsführer"/>
                  <TextInput name="e_mail" label="E-Mail*" placeholder="Bsp. info@vyn.de"/>
                </Row>
                <Row>
                  <TextInput name="phone" label="Telefon" placeholder="Bsp. 0271 38 79 79 90"/>
                  <TextInput name="mobile" label="Mobil" placeholder="Bsp. 0167 11122233"/>
                </Row>
                <Row>
                  <TextInput name="calendar_url" label="Link zum persönlichen Kalender"
                    placeholder="Bsp. https://example.com/my-calendar"/>
                  <TextInput name="whatsapp" label="WhatsApp-Telefonnummer" placeholder="Bsp. 01711234567"/>
                </Row>
                {
                  item.portrait &&
                  <Row lgWidth="50%">
                    <ImageInput label="Foto">
                      <RowActionButton title="Entfernen" color="red" onClick={() => {
                        const newItem = {...item};
                        delete newItem.portrait;
                        newItem.portrait_id = null;
                        setItem(newItem);
                        setFieldValue('portrait_id', null);
                      }}><TrashIcon/></RowActionButton>
                      <img src={item.portrait.url} alt={`Portrait von ${item.first_name} ${item.last_name}`}/>
                    </ImageInput>
                  </Row>
                }
                {!item.portrait && <DropzoneInput name="portrait" label="Foto" className="mt-1-75"/>}
                <ButtonBar>
                  <ButtonLink
                    to={`/ansprechpartner`} color="gray" icon={<ChevronLeftIcon/>} iconPosition="left"
                    text="Zurück"
                  />
                  <Button
                    icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                    text="Speichern" type="submit" disabled={isSubmitting}
                  />
                </ButtonBar>
                <FormErrors touched={touched} errors={errors}/>
                <Modal title="Der Ansprechpartner wurde erfolgreich gespeichert" show={showSuccess}>
                  <ButtonBar>
                    <ButtonLink color="gray" to={`/ansprechpartner/`} text="Zurück"/>
                    <Button type="button" text="Ok" onClick={() => setShowSuccess(false)}/>
                  </ButtonBar>
                </Modal>
              </Form>
            )}
          </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
