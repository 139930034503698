export default function Icons() {
  return [
    'fa-solid fa-house', 'fa-solid fa-user', 'fa-solid fa-check', 'fa-solid fa-star', 'fa-solid fa-location-dot',
    'fa-solid fa-heart', 'fa-solid fa-truck-fast', 'fa-solid fa-face-smile', 'fa-solid fa-calendar-days',
    'fa-solid fa-bolt', 'fa-solid fa-circle-user', 'fa-solid fa-gear', 'fa-solid fa-circle-up', 'fa-solid fa-thumbs-up',
    'fa-solid fa-thumbs-down', 'fa-solid fa-comments', 'fa-solid fa-gears', 'fa-solid fa-globe', 'fa-solid fa-truck',
    'fa-solid fa-city', 'fa-solid fa-briefcase', 'fa-solid fa-person', 'fa-solid fa-person-dress',
    'fa-solid fa-handshake', 'fa-solid fa-users', 'fa-solid fa-shop', 'fa-solid fa-chart-simple',
    'fa-solid fa-bag-shopping', 'fa-solid fa-hand-holding-heart', 'fa-solid fa-calendar', 'fa-solid fa-clock',
    'fa-solid fa-rocket', 'fa-solid fa-laptop', 'fa-solid fa-wrench', 'fa-solid fa-trophy', 'fa-solid fa-crown',
    'fa-solid fa-user-plus', 'fa-solid fa-circle-check', 'fa-solid fa-baby', 'fa-solid fa-lightbulb',
    'fa-solid fa-user-shield', 'fa-solid fa-user-lock', 'fa-solid fa-user-gear', 'fa-solid fa-user-check',
    'fa-solid fa-user-doctor', 'fa-solid fa-tooth', 'fa-solid fa-toolbox', 'fa-solid fa-tractor', 'fa-solid fa-taxi',
    'fa-solid fa-tablet-screen-button', 'fa-solid fa-suitcase', 'fa-solid fa-shield-heart', 'fa-solid fa-shield-dog',
    'fa-solid fa-shop-lock', 'fa-solid fa-hand-pointer', 'fa-solid fa-hand-holding', 'fa-solid fa-hand-peace',
    'fa-solid fa-graduation-cap', 'fa-solid fa-gifts', 'fa-solid fa-file-signature', 'fa-solid fa-fan',
    'fa-solid fa-smile-beam', 'fa-solid fa-face-grin', 'fa-solid fa-envelope-open-text', 'fa-solid fa-earth-europe',
    'fa-solid fa-earth-asia', 'fa-solid fa-earth-africa', 'fa-solid fa-dolly', 'fa-solid fa-computer',
    'fa-solid fa-comment-dots', 'fa-solid fa-clipboard-list', 'fa-solid fa-children', 'fa-solid fa-carrot',
    'fa-solid fa-calendar-plus', 'fa-solid fa-bus', 'fa-solid fa-burger', 'fa-solid fa-bullhorn',
    'fa-solid fa-building-shield', 'fa-solid fa-building-user', 'fa-solid fa-0', 'fa-solid fa-1', 'fa-solid fa-2',
    'fa-solid fa-3', 'fa-solid fa-4', 'fa-solid fa-5', 'fa-solid fa-6', 'fa-solid fa-7', 'fa-solid fa-8',
    'fa-solid fa-9', 'fa-brands fa-xing', 'fa-brands fa-linkedin-in', 'fa-brands fa-facebook-f', 'fa-brands fa-twitter',
    'fa-solid fa-map', 'fa-brands fa-instagram-square', 'fa-solid fa-sack-dollar', 'fa-solid fa-hand-holding-dollar',
    'fa-solid fa-dollar-sign', 'fa-solid fa-euro-sign', 'fa-solid fa-address-book', 'fa-regular fa-user',
    'fa-regular fa-star', 'fa-regular fa-heart', 'fa-regular fa-face-smile', 'fa-regular fa-calendar-days',
    'fa-regular fa-circle-user', 'fa-regular fa-circle-up', 'fa-solid fa-fire', 'fa-regular fa-plus',
    'fa-regular fa-thumbs-up', 'fa-regular fa-thumbs-down', 'fa-regular fa-comments', 'fa-regular fa-handshake',
    'fa-regular fa-calendar', 'fa-regular fa-clock', 'fa-regular fa-circle-check', 'fa-regular fa-lightbulb',
    'fa-regular fa-address-book',
    'fa-solid fa-piggy-bank', 'fa-solid fa-phone-arrow-down-left', 'fa-solid fa-person-walking',
    'fa-solid fa-paw-simple', 'fa-solid fa-mug', 'fa-solid fa-message-bot', 'fa-solid fa-loader',
    'fa-solid fa-lightbulb-on', 'fa-solid fa-light-emergency-on', 'fa-solid fa-house-signal',
    'fa-solid fa-hourglass-half', 'fa-solid fa-highlighter', 'fa-solid fa-heart-pulse', 'fa-solid fa-handshake-simple',
    'fa-solid fa-hand-holding-seedling', 'fa-solid fa-gift-card', 'fa-solid fa-forklift', 'fa-solid fa-garage-car',
    'fa-solid fa-flower-daffodil', 'fa-solid fa-fire-flame-curved', 'fa-solid fa-family', 'fa-solid fa-face-smile-wink',
    'fa-solid fa-dog', 'fa-solid fa-comment-check', 'fa-solid fa-chart-mixed-up-circle-dollar',
    'fa-solid fa-camera-security', 'fa-solid fa-calendar-lines-pen', 'fa-solid fa-box-open', 'fa-solid fa-book-user',
    'fa-solid fa-battery-full', 'fa-solid fa-bags-shopping', 'fa-solid fa-calendar-xmark',
    'fa-solid fa-calculator-simple', 'fa-solid fa-calendar-circle-plus', 'fa-solid fa-bullseye-arrow',
    'fa-solid fa-chart-user'
  ];
}
