import './BarChart.scss';
import React, {useEffect, useLayoutEffect, useState} from "react";
import {ChevronLeftIcon, ChevronRightIcon, EmptyPackageIcon, LoaderIcon} from "./Icons";
import useBL from "../hooks/useBL";
import Widget from "./Widget";
import IconWithText from "./IconWithText";

export default function BarChart({year, ...props}) {

  const bl = useBL();

  const monthLetters = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];

  const defaultOffers = () => {
    let offerDataOfYear = [];
    for (let i = 1; i <= 12; i++) {
      offerDataOfYear[i] = {
        month: i,
        profit: 0,
        created: 0,
        won: 0,
        lost: 0,
        open: 0
      };
    }
    return offerDataOfYear;
  };
  const [offers, setOffers] = useState(defaultOffers());
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(false);
  const [currentYear] = useState(new Date().getFullYear());
  const [selectedYear, setSelectedYear] = useState(year ? year : currentYear);
  const [maxProfit, setMaxProfit] = useState(0);
  const [yAxisProfitSections, setYAxisProfitSections] = useState([]);
  const [yAxisMaxProfitSections, setYAxisMaxProfitSections] = useState(0);
  const [hoverBar, setHoverBar] = useState({bar: -1, clicked: false, hover: false});
  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
    let maxProfitTemp = 0;
    offers.forEach(offer => {
      if (offer.profit > maxProfitTemp) {
        maxProfitTemp = offer.profit;
      }
    });
    setMaxProfit(maxProfitTemp);

    let ps = [0,1,2,3,4,5,6,7,8,9,10];
    let multiplier = 0;
    while (multiplier < maxProfit)
    {
      multiplier += 250;
    }
    for (let i = 0; i <= 10; i++) {
      if (multiplier > 0) {
        ps[i] = (multiplier / 10 * i).toLocaleString('de-DE') + ' €';
      } else {
        ps[i] = '';
      }
    }
    setYAxisMaxProfitSections(multiplier);
    setYAxisProfitSections(ps);
  }, [offers, maxProfit]);

  useEffect(() => {
    setIsLoading(true);
    setNoData(false);

    bl.offer_statistics({year: selectedYear})
      .then(
        data => {
          let monthsWithoutData = 12;
          let defaultData = defaultOffers();
          data.forEach((dataMonth) => {
            defaultData[dataMonth.month] = dataMonth;
            monthsWithoutData--;
          });
          data = defaultData;
          if (monthsWithoutData  >= 12) {
            setNoData(true);
          }
          setOffers(data);
        }
      )
      .catch()
      .finally(() => setIsLoading(false));

  }, [bl, selectedYear]);

  useLayoutEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
    setWindowWidth(window.innerWidth);
  }, []);

  function toDeString(float) {
    return float.toLocaleString(
      'de-DE',
      {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }
    );
  }

  return (
    <Widget className="BarChart">
      <h1 className="title">Angebote</h1>
      <div className="board">
        <div className="y-axis">
          {
            yAxisProfitSections.map((section, index) => {
              return (
                <div key={index} className="y-axis-section">
                  <p>
                    {section}
                  </p>
                </div>
              );
            })
          }
        </div>
        <div className="content">
          {
            offers.map((offer, index) => {
              return (
                <div
                  key={index}
                  className="column"
                  onMouseEnter={() => {
                    if (!hoverBar.clicked) {
                      setHoverBar({bar: index, clicked: hoverBar.clicked, hover: true});
                    }
                  }}
                  onMouseLeave={() => {
                    if (!hoverBar.clicked) {
                      setHoverBar({bar: -1, clicked: hoverBar.clicked, hover: false});
                    }
                  }}
                  onClick={() => {
                    if (!noData) {
                      if (hoverBar.bar === index && hoverBar.clicked) {
                        if (hoverBar.hover) {
                          setHoverBar({bar: index, clicked: false, hover: hoverBar.hover});
                        } else {
                          setHoverBar({bar: -1, clicked: false, hover: hoverBar.hover});
                        }
                      } else {
                        setHoverBar({bar: index, clicked: true, hover: hoverBar.hover});
                      }
                    }
                  }}
                >
                  <div className="background-bar">
                    {
                      !isLoading &&
                      !noData &&
                      <div
                        style={
                          windowWidth > 992 ?
                            {height: (12 / yAxisMaxProfitSections * offer.profit).toString().concat('em')} :
                            {width: (12 / yAxisMaxProfitSections * offer.profit).toString().concat('em')}
                        }

                        className={['bar'].concat(hoverBar.bar === index ? ['hover'] : []).join(' ')}
                      >
                        <div
                          className={['hover-info'].concat(hoverBar.bar === index ? ['shown'] : []).join(' ')}
                        >
                          <h4>{toDeString(parseFloat(offer.profit)) + ' €'}</h4>
                          <p>{offer.created + (parseInt(offer.created) === 1 ? ' Angebot' : ' Angebote')}</p>
                          <p>{offer.won + ' Gewonnen'}</p>
                          <p>{offer.lost + ' Verloren'}</p>
                          <p>{offer.open + ' Offen'}</p>
                        </div>
                      </div>
                    }
                  </div>
                  <div className='x-axis-month'>
                    {monthLetters[parseInt(offers[index].month) - 1]}
                  </div>
                </div>
              );
            })
          }
          {
            isLoading &&
            <div className="loading">
              <LoaderIcon/>
            </div>
          }
          {
            noData &&
            <div className="no-data">
              <IconWithText>
                <EmptyPackageIcon/> Keine Angebotsdaten vorhanden
              </IconWithText>
            </div>
          }
        </div>
      </div>
      <div className="navigation">
        <div
          className={isLoading ? ['disabled-navigation'] : []}
          onClick={() => {
            if (!isLoading) {
              setSelectedYear(selectedYear - 1);
              setHoverBar({bar: -1, clicked: false, hover: false});
            }
          }}
        >
          <ChevronLeftIcon/>
        </div>
        <p>{selectedYear}</p>
        <div
          className={isLoading || selectedYear + 1 > currentYear ? ['disabled-navigation'] : []}
          onClick={() => {
            if (!isLoading && selectedYear + 1 <= currentYear) {
              setSelectedYear(selectedYear + 1);
              setHoverBar({bar: -1, clicked: false, hover: false});
            }
          }}
        >
          <ChevronRightIcon/>
        </div>
      </div>
    </Widget>
  );
}
