import StageTitle from "./StageTitle";
import React, {useEffect, useState} from "react";
import IconWithText from "./IconWithText";
import {EmptyPackageIcon, LoaderIcon} from "./Icons";
import Row from "./Row";
import Video from "./Video";
import useCRUD from "../hooks/useCRUD";
import ErrorModal from "./ErrorModal";

export default function VideoTiles(
  {recordType = 'video', title, tilesPerRow = 3, filter, isLoading = false, ...props}
) {

  const crud = useCRUD();

  const [loading, setLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [videos, setVideos] = useState([]);
  const [stageKey, setStageKey] = useState(1);

  const [sudoLoading, setSudoLoading] = useState(isLoading);
  useEffect(() => {
    setSudoLoading(isLoading);
  }, [isLoading, props.loading]);

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);
    let allFilters = {property: "type", operator: "equals", value: recordType};
    if (filter && filter.length > 0) {
      allFilters = {group: 'and', components: [allFilters, ...filter]};
    }
    crud.data.bulk.read({
      entity: 'video', page_size: 100, filter: allFilters
    })
      .then(videoBulkResult => {
        Promise.all(
          videoBulkResult.items
            .filter(item => item.file_id).map(item => crud.data.read({entity: 'file', id: item.file_id}))
        )
          .then(fileResults => {
            setVideos(videoBulkResult.items.map((item, index) => ({...item, file: fileResults[index]})));
          })
          .catch(errors => setErrors(errors))
          .finally(() => {
            clearTimeout(timeout);
            setShowLoading(false);
            setLoading(false);
          });
      })
      .catch(errors => {
        clearTimeout(timeout);
        setErrors(errors);
        setLoading(false);
        setShowLoading(false);
      });
  }, [crud, stageKey, recordType, filter]);

  return (
    <>
      <StageTitle>{title}</StageTitle>
      {
        (showLoading || sudoLoading || videos.length === 0) &&
        <>{
          (loading && showLoading) || sudoLoading
            ? <IconWithText align="left"><LoaderIcon/>Laden…</IconWithText>
            : <IconWithText align="left"><EmptyPackageIcon/>Keine Einträge vorhanden.</IconWithText>
        }</>
      }
      {!loading && !sudoLoading && videos.length > 0 &&
        <Row lgMaxItems={tilesPerRow}>
          {videos.map((video, index) =>
            <Video
              key={index}
              videoProps={video}
              deleteFunction={() => {setStageKey(stageKey + 1);}}
              {...props}
            />
          )}
        </Row>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );
}
