import TitleHeading from "../components/TitleHeading";
import Title from "../components/Title";
import Stage from "../components/Stage";
import ButtonLink from "../components/ButtonLink";
import {DuplicateIcon, EditIcon, LoaderIcon, PlusCircleIcon, TrashIcon} from "../components/Icons";
import ButtonBar from "../components/ButtonBar";
import Column from "../components/Column";
import RowActionLink from "../components/RowActionLink";
import RowActionButton from "../components/RowActionButton";
import Table from "../components/Table";
import {NavLink} from "react-router-dom";
import {useState} from "react";
import useCRUD from "../hooks/useCRUD";
import ErrorModal from "../components/ErrorModal";
import Modal from "../components/Modal";
import Button from "../components/Button";

export default function Galerien() {

  const crud = useCRUD();
  const [errors, setErrors] = useState([]);
  const [duplicating, setDuplicating] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const [itemCount, setItemCount] = useState({});
  const [tableKey, setTableKey] = useState(1);

  const [pendingReadItemCounts, setPendingReadItemCounts] = useState([]);
  const readItemCount = pictureGallery => {
    if (!pendingReadItemCounts.includes(pictureGallery.id)) {
      setPendingReadItemCounts(x => {x.push(pictureGallery.id); return x;});
      crud.data.bulk.read({
        entity: 'picture_gallery_item',
        page_size: 0,
        filter: crud.filter.equals('picture_gallery_id', pictureGallery.id)
      })
        .then(
          result => {
            itemCount[pictureGallery.id] = result.total;
            setItemCount({...itemCount});
          }
        )
        .catch(errors => setErrors(errors))
        .finally(() => setPendingReadItemCounts(x => x.filter(i => i !== pictureGallery.id)));
    }
  };

  const [duplicateRow, setDuplicateRow] = useState(null);
  const doDuplicate = () => {
    setDuplicating(true);
    crud.data.read({entity: 'picture_gallery', id: duplicateRow.id})
      .then(gallery => {
        crud.data.create({entity: 'picture_gallery', item: {headline: `${gallery.headline} (Kopie)`}})
          .then(async newGallery => {
            const galleryItems = await crud.data.bulk.read({
              entity: 'picture_gallery_item',
              filter: crud.filter.equals('picture_gallery_id', gallery.id),
              page_size: 1000
            });
            if (galleryItems.items.length > 0) {
              await crud.data.bulk.create({
                entity: 'picture_gallery_item',
                items: galleryItems.items.map(galleryItem => ({
                  picture_gallery_id: newGallery.id,
                  image_id: galleryItem.image_id,
                  video_id: galleryItem.video_id,
                  text: galleryItem.text,
                }))
              });
            }
          })
          .catch(errors => setErrors(errors))
          .finally(() => {
            setDuplicateRow(null);
            setTableKey(tableKey => tableKey + 1);
            setDuplicating(false);
          });
      })
      .catch(errors => setErrors(errors));
  };

  const doDelete = () => {
    crud.data.delete({'entity': 'picture_gallery', id: deleteRow.id})
      .then(() => {
        setDeleteRow(null);
        setTableKey(tableKey => tableKey + 1);
      })
      .catch(errors => setErrors(errors));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Galerien</TitleHeading>
      </Title>
      <Stage>
        <Table name="picture_gallery" entity="picture_gallery" defaultOrderBy="headline" key={tableKey}>
          <Column
            title="Überschrift" property="headline"
            transform={
              (headline, pictureGallery) =>
                <NavLink to={`/galerie/${pictureGallery.id}/details`}>{headline}</NavLink>
            }
          />
          <Column title="Anzahl Einträge" values={itemCount} populate={readItemCount} type="number"/>
          <Column type="actions">
            <RowActionLink to={row => `/galerie/${row.id}/details`} color="blue" title="Bearbeiten">
              <EditIcon/>
            </RowActionLink>
            <RowActionButton color="turquoise" title="Galerie duplizieren" onClick={setDuplicateRow}>
              <DuplicateIcon/>
            </RowActionButton>
            <RowActionButton color="red" title="Löschen" onClick={setDeleteRow}><TrashIcon/></RowActionButton>
          </Column>
        </Table>
        <ButtonBar>
          <ButtonLink to="/galerie/neu" text="Hinzufügen" icon={<PlusCircleIcon/>}/>
        </ButtonBar>
      </Stage>
      {
        duplicateRow &&
        <Modal title="Duplizieren bestätigen" onDismiss={() => setDuplicateRow(null)}>
          <p>Möchten Sie die Galerie „{duplicateRow.headline}“ wirklich duplizieren?</p>
          <ButtonBar>
            <Button color="grey" text="Abbrechen" disabled={duplicating} onClick={() => setDuplicateRow(null)}/>
            <Button
              color="turquoise" text="Duplizieren" icon={duplicating ? <LoaderIcon/> : <DuplicateIcon/>}
              disabled={duplicating} onClick={doDuplicate}
            />
          </ButtonBar>
        </Modal>
      }
      {
        deleteRow &&
        <Modal title="Löschen bestätigen" onDismiss={() => setDeleteRow(null)}>
          <p>Möchten Sie die Galerie „{deleteRow.headline}“ wirklich löschen?</p>
          <ButtonBar>
            <Button text="Behalten" onClick={() => setDeleteRow(null)}/>
            <Button color="red" text="Löschen" icon={<TrashIcon/>} onClick={doDelete}/>
          </ButtonBar>
        </Modal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </main>
  );

}
