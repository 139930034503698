import PublicModal from "../components/PublicModal";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../components/TextInput";
import {LoaderIcon} from "../components/Icons";
import Button from "../components/Button";
import FormErrors from "../components/FormErrors";
import PasswordInput from "../components/PasswordInput";
import CheckboxInput from "../components/CheckboxInput";
import Row from "../components/Row";
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import {useContext, useState} from "react";
import {AuthContext} from "../contexts/AuthContext";
import {localStorage, sessionStorage} from "../storage";
import useBL from "../hooks/useBL";

export default function Login() {

  const bl = useBL();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [remoteErrors, setRemoteErrors] = useState([]);

  const authContext = useContext(AuthContext);

  const validationSchema = Yup.object({
    email: Yup.string()
      .required('Bitte geben Sie eine E-Mail-Adresse ein')
      .email('Bitte geben Sie eine gültige E-Mail-Adresse ein'),
    password: Yup.string()
      .required('Bitte geben Sie ein Passwort ein')
  });

  const submit = (values, {setSubmitting}) => {
    bl.login({email: values.email, password: values.password})
      .then((authToken) => {
        authContext.setAuthToken(authToken);
        if (values.keepLogin) {
          localStorage.set('authToken', authToken);
        } else {
          sessionStorage.set('authToken', authToken);
        }
        navigate(location.state?.from?.pathname || '/dashboard', {replace: true});
      })
      .catch(remoteErrors => setRemoteErrors(remoteErrors))
      .finally(() => setSubmitting(false));
  };

  return (
    <PublicModal>
      <h1>Anmeldung</h1>
      <Formik
        initialValues={{email: params.email ?? '', password: '', keepLogin: false}} validationSchema={validationSchema}
        onSubmit={submit} validate={() => setRemoteErrors([])}
      >
        {({isSubmitting, touched, errors}) => (
          <Form>
            <TextInput name="email" placeholder="E-Mail"/>
            <PasswordInput name="password" placeholder="Passwort"/>
            <Row>
              <CheckboxInput name="keepLogin" label="Angemeldet bleiben" className="nowrap"/>
              <NavLink to="/passwort-vergessen">Passwort vergessen</NavLink>
            </Row>
            <div>
              <Button
                color="gray" text="Anmelden" type="submit" icon={isSubmitting ? <LoaderIcon/> : null}
                disabled={isSubmitting}
              />
            </div>
            <FormErrors touched={touched} errors={errors} remoteErrors={remoteErrors}/>
          </Form>
        )}
      </Formik>
    </PublicModal>
  );
}
