import {NavLink} from "react-router-dom";
import {useEffect, useState} from "react";
import useCRUD from "../hooks/useCRUD";

export default function MenuItem({label, to, icon, liteRestrictions}) {

  const crud = useCRUD();
  const [isLiteRestricted, setIsLiteRestricted] = useState(true);

  useEffect(() => {
    if (liteRestrictions) {
      crud.data.read({entity: "settings", id: 1})
        .then(settings => setIsLiteRestricted(settings.subscription_plan === "lite"));
    } else {
      setIsLiteRestricted(false);
    }
  }, [crud.data, liteRestrictions]);

  return (
    <li className={isLiteRestricted ? "restricted" : ""}>
      <NavLink to={to}>{icon || null}
        <span>{label}</span>
      </NavLink>
    </li>
  );

}
