import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

export const RecordingContext = React.createContext({
  intention: {},
  setIntention: (intention) => {},
  type: {},
  setType: (type) => {},
  navigateBack: () => {}
});

export function RecordingContextProvider({children}) {

  const [intention, setIntention] = useState();
  const [type, setType] = useState();

  const navigate = useNavigate();

  return (
    <RecordingContext.Provider value={{
      intention,
      setIntention,
      type,
      setType,
      navigateBack: () => {
        if (intention === 'introduction_video' || intention === 'offer_video') {
          navigate('/angebot/neu/angebots-erlaeuterung');
        } else if (intention === 'general') {
          navigate('/videos-und-memos');
        } else {
          navigate('/');
        }
      }
    }}>
      {children}
    </RecordingContext.Provider>
  );

}
