import {useParams} from "react-router-dom";
import PublicModal from "../components/PublicModal";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import useBL from "../hooks/useBL";
import PasswordInput from "../components/PasswordInput";
import Button from "../components/Button";
import FormErrors from "../components/FormErrors";
import {ChevronRightIcon, LoaderIcon} from "../components/Icons";
import ButtonBar from "../components/ButtonBar";
import ButtonLink from "../components/ButtonLink";

export default function ResetPassword() {

  const params = useParams();
  const bl = useBL();

  const [remoteErrors, setRemoteErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Bitte ein Passwort eingeben'),
    passwordRepetition: Yup.string()
      .required('Bitte das Passwort wiederholen')
      .oneOf([Yup.ref('password'), null], 'Passwörter müssen übereinstimmen')
  });

  const submit = (values, {setSubmitting}) => {
    bl.reset_password({token: params.token, password: values.password})
      .then(() => setShowSuccess(true))
      .catch(remoteErrors => setRemoteErrors(remoteErrors))
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    bl.validate_password_reset_token({token: params.token}).catch(remoteErrors => setRemoteErrors(remoteErrors));
  }, [bl, params.token]);

  return (
    <PublicModal>
      <h1>Passwort zurücksetzen</h1>
      {
        !showSuccess &&
        <>
          <p>
            Bitte ein neues Passwort eingeben. Das Passwort muss mindestens acht Zeichen lang sein, einen Groß-, einen
            Kleinbuchstaben und eine Ziffer beinhalten.
          </p>
          <Formik
            initialValues={{password: '', passwordRepetition: ''}} validationSchema={validationSchema}
            onSubmit={submit} validate={() => setRemoteErrors([])}
          >
            {({isSubmitting, touched, errors}) => (
              <Form>
                <PasswordInput name="password" placeholder="Passwort"/>
                <PasswordInput name="passwordRepetition" placeholder="Passwort wiederholen"/>
                <ButtonBar align="left">
                  <ButtonLink color="gray" text="Abbrechen" to="/anmeldung"/>
                  <Button
                    color="gray" text="Absenden" type="submit" icon={isSubmitting ? <LoaderIcon/> : null}
                    disabled={isSubmitting}
                  />
                </ButtonBar>
                <FormErrors touched={touched} errors={errors} remoteErrors={remoteErrors}/>
              </Form>
            )}
          </Formik>
        </>
      }
      {
        showSuccess &&
        <>
          <p>
            Das neue Passwort kann nun für die Anmeldung verwendet werden.
          </p>
          <ButtonBar align="left">
            <ButtonLink color="gray" text="Zur Anmeldung" to="/anmeldung" icon={<ChevronRightIcon/>}/>
          </ButtonBar>
        </>
      }
    </PublicModal>
  );
}
