import {useLocation} from "react-router-dom";
import {useLayoutEffect} from "react";

export default function ScrollToTop (props) {

  const location = useLocation();

  useLayoutEffect(
    () => {
      document.documentElement.scrollTo(0, 0);
    },
    [location.pathname]
  );

  return props.children;

}
