import {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";
import {Navigate, useLocation} from "react-router-dom";
import config from "../config.json";

export default function RequireAuth({children}) {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  return (!config.USE_AUTH || authContext.authToken) ? children : <Navigate to="/anmeldung" state={{from: location}}/>;
}
