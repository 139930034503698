import TitleHeading from "../components/TitleHeading";
import Breadcrumb from "../components/Breadcrumb";
import {NavLink, useParams} from "react-router-dom";
import Title from "../components/Title";
import Stage from "../components/Stage";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../components/Icons";
import Button from "../components/Button";
import ButtonBar from "../components/ButtonBar";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../components/TextInput";
import FormErrors from "../components/FormErrors";
import {useEffect, useState} from "react";
import Row from "../components/Row";
import Modal from "../components/Modal";
import {useNavigate} from "react-router-dom";
import IconInput from "../components/IconInput";
import icons from '../resources/icons';
import IconWithText from "../components/IconWithText";
import ErrorModal from "../components/ErrorModal";
import useCRUD from "../hooks/useCRUD";
import useFiles from "../hooks/useFiles";

export default function CreateUsp() {

  const crud = useCRUD();
  const files = useFiles();
  const navigate = useNavigate();
  const [uspGroup, setUspGroup] = useState(null);
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderIndex, setOrderIndex] = useState(null);
  const params = useParams();

  useEffect(() => {
    Promise.all([
      crud.data.read({entity: 'usp_group', id: parseInt(params.id)}),
      crud.data.bulk.read({
        entity: 'usp', page_size: 9999, filter: crud.filter.equals('usp_group_id', parseInt(params.id))
      })
    ])
      .then(([uspGroupResult, uspResult]) => {
        setUspGroup({...uspGroupResult});
        setOrderIndex(
          uspResult.items.map(usp => parseInt(usp.order ?? '-1')).reduce((a, b) => Math.max(a, b), - 1) + 1
        );
      })
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud, params.id]);

  const initialValues = {
    headline: '',
    sub_headline: '',
    icon: ''
  };

  const validationSchema = Yup.object({
    headline: Yup.string().required('Bitte geben Sie eine Überschrift ein'),
    icon: Yup.mixed().oneOf(icons(), 'Bitte wählen Sie ein Icons aus')
  });

  const submit = (values, {setSubmitting}) => {
    let item = {...values, order: orderIndex};
    item.usp_group_id = parseInt(params.id);
    const save = () => {
      crud.data.create({entity: 'usp', item})
        .then(() => setShowSuccess(true))
        .catch(errors => setErrors(errors))
        .finally(() => setSubmitting(false));
    };
    if (values.portrait) {
      files.upload(values.portrait)
        .then(result => {
          item.portrait_id = result.id;
          save();
        })
        .catch(errors => {
          setErrors(errors);
          setSubmitting(false);
        });
    } else {
      save();
    }
  };

  return (
    <main>
      <Title>
        <TitleHeading>Vorteil erstellen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/usp-gruppen">Unternehmensvorteile</NavLink>
          {uspGroup && <NavLink to={`/usp-gruppen/${uspGroup.id}/`}>{uspGroup.name}</NavLink>}
          Neu
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
          <Formik
            initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}
            onReset={() => setShowSuccess(false)}
          >
            {({isSubmitting, touched, errors, setFieldValue}) => (
              <Form>
                <Row>
                  <TextInput name="headline" label="Überschrift*" placeholder="Bsp. Wir sind die Besten"/>
                  <TextInput name="sub_headline" label="Unterüberschrift" placeholder="Bsp. In Allem"/>
                </Row>
                <Row>
                  <IconInput setFieldValue={setFieldValue}/>
                </Row>
                <ButtonBar>
                  <Button
                    color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                    onClick={() => navigate(-1)}
                  />
                  <Button icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                    text="Speichern" type="submit" disabled={isSubmitting}/>
                </ButtonBar>
                <FormErrors touched={touched} errors={errors}/>
                <Modal
                  title="Der Vorteil wurde erfolgreich erstellt" show={showSuccess}
                  onDismiss={() => navigate('/usp-gruppen')}
                >
                  <ButtonBar>
                    <Button type="reset" color="gray" text="Weiteren Vorteil hinzufügen"/>
                    <Button text="Zurück zur Übersicht" onClick={() => navigate(-1)}/>
                  </ButtonBar>
                </Modal>
              </Form>
            )}
          </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );
}
