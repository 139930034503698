import './DesignTemplate.scss';
import Button from "./Button.js";
import Modal from "./Modal";
import ButtonBar from "./ButtonBar";
import {useState} from "react";

export default function DesignTemplate({src, active, setActive}) {

  const [showNotice, setShowNotice] = useState(false);

  const setTemplate = function () {
    setShowNotice(true);
    setActive();
  };

  return (
    <div className={'DesignTemplate' + (active ? ' active-design' : '')}>
      <img src={process.env.PUBLIC_URL + src} alt="Vorschau der Vorlage" onClick={() => !active && setTemplate()}/>
      <div>
        <Button text="Diese Vorlage verwenden" onClick={setTemplate}/>
      </div>
      <Modal
        title="Änderung des Templates"
        show={showNotice}
        onDismiss={() => setShowNotice(false)}
      >
        <div>
          <p>Wenn Du die Vorlage wechselst, wird diese Änderung für alle neu versendeten Angebote aktiv. Die bereits
            versendeten Angebote bleiben im vorherigen Design.</p>
          <ButtonBar>
            <Button type="button" text="Ok" onClick={() => setShowNotice(false)}/>
          </ButtonBar>
        </div>
      </Modal>
    </div>
  );
}
