import './ImageInput.scss';

export default function ImageInput({label, ...props}) {
  return (
    <div className="ImageInput">
      {label && <label>{label}</label>}
      <div className="frame">{props.children}</div>
    </div>
  );
}
