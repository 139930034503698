import TrackStation from "../../../components/TrackStation";
import Track from "../../../components/Track";
import {Form, Formik} from "formik";
import {ChevronLeftIcon, ChevronRightIcon, EditIcon, EyeIcon, TrashIcon} from "../../../components/Icons";
import Button from "../../../components/Button";
import {useNavigate} from "react-router-dom";
import ButtonBar from "../../../components/ButtonBar";
import ButtonLink from "../../../components/ButtonLink";
import StageTitle from "../../../components/StageTitle";
import Switch from "../../../components/Switch";
import React, {useContext, useMemo, useState} from "react";
import SelectInput from "../../../components/SelectInput";
import Row from "../../../components/Row";
import {OfferContext} from "../../../contexts/OfferContext";
import Table from "../../../components/Table";
import Column from "../../../components/Column";
import RowActionButton from "../../../components/RowActionButton";
import RowActionLink from "../../../components/RowActionLink";
import Modal from "../../../components/Modal";
import useCRUD from "../../../hooks/useCRUD";
import {default as VideoComponent} from "../../../components/Video";
import {RecordingContext} from "../../../contexts/RecordingContext";
import * as Yup from "yup";
import FormErrors from "../../../components/FormErrors";

export default function Explanation() {

  const navigate = useNavigate();
  const crud = useCRUD();
  const offerContext = useContext(OfferContext);
  const recordingContext = useContext(RecordingContext);

  const [selectIntroductionVideo, setSelectIntroductionVideo] = useState(false);
  const [selectOfferVideo, setSelectOfferVideo] = useState(false);
  const [video, setVideo] = useState(null);

  const onSubmit = () => {
    navigate('../elemente');
  };

  const view = id => {
    crud.data.read({entity: 'video', id})
      .then(video => {
        if (video.file_id) {
          crud.data.read({entity: 'file', id: video.file_id})
            .then(file => {
              setVideo({...video, file: {...file}});
            });
        } else {
          setVideo(video);
        }
      });
  };

  const date = date => (new Date(date)).toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});

  const introductionVideoFilter = useMemo(
    () => ({property: 'id', operator: 'equals', value: offerContext.offer.introduction_video_id}),
    [offerContext.offer.introduction_video_id]
  );

  const offerVideoFilter = useMemo(
    () => ({property: 'id', operator: 'equals', value: offerContext.offer.offer_video_id}),
    [offerContext.offer.offer_video_id]
  );

  const validationSchema = Yup.object({
    introduction_video_id: Yup.number().nullable().when('use_introduction_video', {
      is: true,
      then: Yup.number().nullable().required('Begrüßungsvideo deaktivieren oder auswählen')
    }),
    offer_video_id: Yup.number().nullable().when('use_offer_video', {
      is: true,
      then: Yup.number().nullable().required('Angebotsvideo deaktivieren oder auswählen')
    })
  });

  return (
    <>
      <Track>
        <TrackStation link="/angebot/neu/allgemeines">Allgemeine Angaben</TrackStation>
        <TrackStation link="/angebot/neu/einleitung">Einleitung</TrackStation>
        <TrackStation active={true}>Angebots-Erläuterung</TrackStation>
        <TrackStation link="/angebot/neu/elemente">Elemente</TrackStation>
      </Track>
      <Formik
        initialValues={{
          introduction_video_id: offerContext.offer.introduction_video_id,
          use_introduction_video: !!offerContext.offer.introduction_video_id,
          offer_video_id: offerContext.offer.offer_video_id,
          use_offer_video: !!offerContext.offer.offer_video_id
        }}
        onSubmit={onSubmit} validationSchema={validationSchema}
        validate={values => {
          setSelectIntroductionVideo(false);
          setSelectOfferVideo(false);
          offerContext.setOffer({
            ...offerContext.offer,
            introduction_video_id: values.introduction_video_id,
            offer_video_id: values.offer_video_id
          });
        }}
      >
        {({values, setFieldValue, touched, errors}) => (
          <Form>
            <StageTitle className="mt-1-75">Begrüßungsvideo</StageTitle>
            <p>
              Stelle dich deinem Ansprechpartner kurz vor, gib einen Einblick in dein Unternehmen oder fasse das
              Meeting kurz zusammen.
            </p>
            <Switch
              active={values.use_introduction_video}
              onClick={() => {
                setFieldValue('introduction_video_id', null, false);
                setFieldValue('use_introduction_video', !values.use_introduction_video, true);
              }}
            />
            {
              values.use_introduction_video &&
              <ButtonBar align="left">
                <ButtonLink
                  to="/video/aufnehmen/konfiguration" text="Video / Memo aufnehmen"
                  onClick={() => recordingContext.setIntention('introduction_video')}
                />
                {
                  !selectIntroductionVideo &&
                  <Button text="Video / Memo auswählen" onClick={() => setSelectIntroductionVideo(true)}/>
                }
              </ButtonBar>
            }
            {
              values.use_introduction_video && selectIntroductionVideo &&
              <Row className="mt-1" lgWidth="50%">
                <SelectInput name="introduction_video_id" entity="video" getOptionLabel={item => item.name}/>
              </Row>
            }
            {
              values.use_introduction_video && values.introduction_video_id &&
              <Table className="mt-1" entity="video" noSort filter={introductionVideoFilter}>
                <Column title="Datum" property="created_at" transform={date}/>
                <Column title="Name" property="name"/>
                <Column type="actions">
                  <RowActionButton type="button" color="green" title="Ansehen" onClick={row => view(row.id)}>
                    <EyeIcon/>
                  </RowActionButton>
                  <RowActionLink
                    to={row => `/video/${row.id}/bearbeiten`} type="button" color="blue" title="Bearbeiten"
                  >
                    <EditIcon/>
                  </RowActionLink>
                  <RowActionButton
                    type="button" color="red" title="Entfernen"
                    onClick={() => setFieldValue('introduction_video_id', null)}
                  >
                    <TrashIcon/>
                  </RowActionButton>
                </Column>
              </Table>
            }
            <hr/>
            <StageTitle>Angebotsvideo</StageTitle>
            <p>
              Hier hast du die Möglichkeit, die verschiedenen Positionen deines Angebots genauer zu erklären. Dabei
              kannst du durch das Angebot scrollen und bestimmte Aspekte hervorheben.
            </p>
            <Switch
              active={values.use_offer_video}
              onClick={() => {
                setFieldValue('offer_video_id', null, false);
                setFieldValue('use_offer_video', !values.use_offer_video);
              }}
            />
            {
              values.use_offer_video &&
              <ButtonBar align="left">
                <ButtonLink
                  to="/video/aufnehmen/konfiguration/screencast" text="Bildschirm aufnehmen"
                  onClick={() => {
                    recordingContext.setIntention('offer_video');
                    recordingContext.setType('screencast');
                  }}
                />
                {
                  !selectOfferVideo &&
                  <Button text="Bildschirmaufnahme auswählen" onClick={() => setSelectOfferVideo(true)}/>
                }
              </ButtonBar>
            }
            {
              values.use_offer_video && selectOfferVideo &&
              <Row className="mt-1" lgWidth="50%">
                <SelectInput name="offer_video_id" entity="video" getOptionLabel={item => item.name}/>
              </Row>
            }
            {
              values.use_offer_video && values.offer_video_id &&
              <Table className="mt-1" entity="video" noSort filter={offerVideoFilter}>
                <Column title="Datum" property="created_at" transform={date}/>
                <Column title="Name" property="name"/>
                <Column type="actions">
                  <RowActionButton type="button" color="green" title="Ansehen" onClick={row => view(row.id)}>
                    <EyeIcon/>
                  </RowActionButton>
                  <RowActionLink
                    to={row => `/video/${row.id}/bearbeiten`} type="button" color="blue" title="Bearbeiten"
                  >
                    <EditIcon/>
                  </RowActionLink>
                  <RowActionButton
                    type="button" color="red" title="Entfernen"
                    onClick={() => setFieldValue('offer_video_id', null)}
                  >
                    <TrashIcon/>
                  </RowActionButton>
                </Column>
              </Table>
            }
            <ButtonBar>
              <ButtonLink to="../einleitung" text="Zurück" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left"/>
              <Button icon={<ChevronRightIcon/>} text="Weiter" type="submit"/>
            </ButtonBar>
            <FormErrors touched={touched} errors={errors}/>
          </Form>
        )}
      </Formik>
      {
        video &&
        <Modal title={video.name} onDismiss={() => setVideo(null)}>
          <VideoComponent videoProps={video} />
          <ButtonBar>
            <Button onClick={() => setVideo(null)} text="Schließen"/>
          </ButtonBar>
        </Modal>
      }
    </>
  );

}
