import './EventsWidget.scss';
import Widget from "./Widget";
import {useEffect, useMemo, useState} from "react";
import useCRUD from "../hooks/useCRUD";
import {LoaderIcon} from "./Icons";

export default function EventsWidget({description, color, offer_id, type, target}) {

  const crud = useCRUD();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    setLoading(true);
    let filterComponents = [{property: 'offer_id', operator: 'equals', value: offer_id}];
    if (type) {
      filterComponents.push({property: 'type', operator: 'equals', value: type});
    }
    if (target) {
      filterComponents.push({property: 'target', operator: 'equals', value: target});
    }
    crud.data.bulk.read({
      entity: 'event', page_size: '99', order_by: 'created_at', order: 'desc',
      filter: {group: 'and', components: filterComponents}
    })
      .then(eventsBulkReadResult => setEvents(eventsBulkReadResult.items))
      .catch()
      .finally(() => setLoading(false));
  }, [crud, offer_id, target, type]);

  let maxDigits = useMemo(() => Math.ceil(Math.log10(events.length)), [events]);

  const formatDate = input => {
    let date = new Date(input);
    return (
      <>
        {date.toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}<br/>
        {date.toLocaleString('de-DE', {hour: '2-digit', minute: '2-digit'})} Uhr
      </>
    );
  };

  return (
    <Widget className="EventsWidget">
      <div className="text">
        <p className={`number ${color}`}>{loading && <LoaderIcon/>}{!loading && events.length}</p>
        <p className="description" title={description}>{description}</p>
      </div>
      <div className={`list ${color}`}>
        <ol>
          {
            !loading && events.map(event => event.created_at).map((dateTime, index) => (
              <li key={index}>
                <div className="marker"><span>{(index + 1).toString().padStart(maxDigits, '0')}</span></div>
                <div className="content">{formatDate(dateTime)}</div>
              </li>
            ))
          }
        </ol>
      </div>
    </Widget>
  );
}
