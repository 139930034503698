import {useEffect, useState} from "react";
import useBL from "../hooks/useBL";
import {LoaderIcon} from "./Icons";

export default function CustomerCount({status}) {

  const bl = useBL();

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState('?');

  useEffect(() => {
    setLoading(true);
    bl.count_customers({status})
      .then(result => setCount(result))
      .finally(() => setLoading(false));
  }, [bl, status]);

  return loading ? <LoaderIcon/> : count;

}
