import Title from '../components/Title';
import TitleHeading from '../components/TitleHeading';
import Stage from "../components/Stage";
import ButtonBar from "../components/ButtonBar";
import ButtonLink from "../components/ButtonLink";
import {ChevronLeftIcon, LoaderIcon, TrashIcon} from "../components/Icons";
import {useEffect, useState} from "react";
import ErrorModal from "../components/ErrorModal";
import IconWithText from "../components/IconWithText";
import File from "../components/File";
import {useNavigate, useParams} from "react-router-dom";
import Modal from "../components/Modal";
import Button from "../components/Button";
import useCRUD from "../hooks/useCRUD";

export default function DocumentDetails() {

  const crud = useCRUD();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [document, setDocument] = useState(null);
  const [file, setFile] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const doDelete = () => {
    setDeleting(true);
    crud.data.delete({'entity': 'document', id: document.id})
      .then(async () => {
        let offerHasFileIds = [...new Set((await crud.data.bulk.read({
          entity: 'offer_has_file',
          page_size: 1000,
          filter: {group: 'and', components: [
            {property: 'file_id', operator: 'equals', value: document.file_id},
            {property: 'type', operator: 'equals', value: 'other'}
          ]}
        })).items.map(offerHasFile => offerHasFile.id))];
        offerHasFileIds.length > 0 && await crud.data.bulk.delete({
          entity: 'offer_has_file', filter: crud.filter.or(...offerHasFileIds.map(id => crud.filter.equals('id', id)))
        });
        navigate('/dokumente');
      })
      .catch(errors => setErrors(errors));
  };

  useEffect(() => {
    crud.data.read({entity: 'document', id: params.id})
      .then(document => {
        setDocument(document);
        crud.data.read({entity: 'file', id: document.file_id})
          .then(file => setFile(file))
          .catch(errors => setErrors(errors))
          .finally(() => setLoading(false));
      })
      .catch(errors => {
        setErrors(errors);
        setLoading(false);
      });
  }, [crud.data, params.id]);

  return (
    <main>
      <Title>
        <TitleHeading>Dokumente</TitleHeading>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {
          !loading &&
          <File details={true} href={file.url} onDelete={() => setShowDelete(true)} name={document.name}>
            {file}
          </File>
        }
        <ButtonBar align="left">
          <ButtonLink
            to="/dokumente" text="Zurück" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left"
          />
        </ButtonBar>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        {
          showDelete &&
          <Modal title="Löschen bestätigen" onDismiss={() => setShowDelete(false)}>
            <p>Möchten Sie die Datei „{document.name}“ wirklich löschen?</p>
            <ButtonBar>
              <Button text="Behalten" onClick={() => setShowDelete(false)}/>
              <Button
                color="red" text="Löschen" icon={deleting ? <LoaderIcon/> : <TrashIcon/>}
                onClick={doDelete}
              />
            </ButtonBar>
          </Modal>
        }
      </Stage>
    </main>
  );
}
