export default function LightBulbIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 160">
      <defs>
        <linearGradient id="a" x1=".5" x2=".5" y2="1">
          <stop offset="0" stopColor="#9a56ff"/>
          <stop offset="1" stopColor="#d78afd"/>
        </linearGradient>
      </defs>
      <path fill="url(#a)" d="M10 26.7H3.3a3.3 3.3 0 0 0 0 6.6H10a3.3 3.3 0 0 0 0-6.6" transform="translate(0 40)"/>
      <path fill="url(#a)" d="M60.7 26.7H54a3.3 3.3 0 0 0 0 6.6h6.7a3.3 3.3 0 0 0 0-6.6" transform="translate(76 40)"/>
      <path
        fill="url(#a)" d="M13.5 18.2a3.2 3.2 0 0 0 4.7 0 3.4 3.4 0 0 0 0-4.7l-4.7-4.7a3.3 3.3 0 0 0-4.7 4.7Z"
        transform="translate(11.7 11.7)"
      />
      <path
        fill="url(#a)" d="M49.3 44.6a3.3 3.3 0 0 0-4.7 4.7l4.7 4.8a3.3 3.3 0 1 0 4.8-4.8Z"
        transform="translate(65.4 65.5)"
      />
      <path
        fill="url(#a)" d="M30 13.3a3.3 3.3 0 0 0 3.3-3.3V3.3a3.3 3.3 0 0 0-6.6 0V10a3.3 3.3 0 0 0 3.3 3.3"
        transform="translate(40)"
      />
      <path
        fill="url(#a)" d="m49.3 8.8-4.7 4.7a3.4 3.4 0 0 0 0 4.7 3.3 3.3 0 0 0 4.7 0l4.8-4.7a3.3 3.3 0 1 0-4.8-4.7"
        transform="translate(65.4 11.7)"
      />
      <path
        fill="url(#a)" d="m13.5 44.6-4.7 4.7a3.4 3.4 0 0 0 0 4.8 3.4 3.4 0 0 0 4.7 0l4.7-4.8a3.3 3.3 0 0 0-4.7-4.7"
        transform="translate(11.7 65.5)"
      />
      <path
        fill="url(#a)" transform="translate(12 12)"
        d="M86.2 99.3a50 50 0 1 0-56.4 0 18.7 18.7 0 0 1 7.9 12.2 11.6 11.6 0 0 0 .3 23v3.5a10 10 0 0 0 10 10h20a10 10 0
          0 0 10-10v-3.5a11.6 11.6 0 0 0 .3-23 18.8 18.8 0 0 1 7.9-12.2M71.3 138a3.3 3.3 0 0 1-3.3 3.3H48a3.3 3.3 0 0
          1-3.3-3.3v-3.3h26.7v3.3Zm10-15a5 5 0 0 1-5 5H39.7a5 5 0 0 1 0-10h36.7a5 5 0 0 1 5 5m-9.8-11.7H44.5a25.4 25.4 0
          0 0-11-17.6 43.3 43.3 0 1 1 49 0 25.4 25.4 0 0 0-11 17.6"
      />
      <path
        fill="url(#a)" transform="translate(20 20)"
        d="M50 16.7a3.3 3.3 0 0 0-3.3-3.4 33.3 33.3 0 0 0-32 42.6 3.3 3.3 0 0 0 6.4 0 26.8 26.8 0 0 1 25.6-19.2 3.3 3.3
          0 0 0 3.3-3.4V16.7m-6.7 13.5a33.6 33.6 0 0 0-23 12.9 26.7 26.7 0 0 1 23-23Z"
      />
      <path
        fill="url(#a)" transform="translate(42 35)"
        d="M63.3 25.7a3.3 3.3 0 0 0-6.4 0A26.8 26.8 0 0 1 31.3 45a3.3 3.3 0 0 0-3.3 3.3V65a3.3 3.3 0 0 0 3.3 3.3A33.4
          33.4 0 0 0 64.6 35a32.5 32.5 0 0 0-1.3-9.3M34.7 61.4v-10a33.6 33.6 0 0 0 23-12.8 26.7 26.7 0 0 1-23 22.8"
      />
    </svg>
  );
}
