import TrackStation from "../../../components/TrackStation";
import Track from "../../../components/Track";
import {Form, Formik} from "formik";
import {ChevronLeftIcon, ChevronRightIcon} from "../../../components/Icons";
import Button from "../../../components/Button";
import {useNavigate} from "react-router-dom";
import ButtonBar from "../../../components/ButtonBar";
import ButtonLink from "../../../components/ButtonLink";
import TextInput from "../../../components/TextInput";
import Row from "../../../components/Row";
import TextareaInput from "../../../components/TextareaInput";
import {useContext, useState} from "react";
import {OfferContext} from "../../../contexts/OfferContext";
import SelectInput from "../../../components/SelectInput";
import ErrorModal from "../../../components/ErrorModal";

export default function Introduction() {

  const navigate = useNavigate();
  const offerContext = useContext(OfferContext);
  const [errors, setErrors] = useState([]);
  const [selectLetter, setSelectLetter] = useState(false);

  const onSubmit = () => {
    navigate('../angebots-erlaeuterung');
  };

  return (
    <>
      <Track>
        <TrackStation link="/angebot/neu/allgemeines">Allgemeine Angaben</TrackStation>
        <TrackStation active={true}>Einleitung</TrackStation>
        <TrackStation link="/angebot/neu/angebots-erlaeuterung">Angebots-Erläuterung</TrackStation>
        <TrackStation link="/angebot/neu/elemente">Elemente</TrackStation>
      </Track>
      <Formik
        initialValues={{
          title: offerContext.offer.title,
          opening: offerContext.offer.opening,
          letter: offerContext.offer.letter
        }}
        onSubmit={onSubmit}
        validate={values => {
          offerContext.setOffer({...offerContext.offer, ...values});
        }}
      >
        {({setFieldValue}) => (
          <Form className="mt-1-75">
            <Row>
              <TextInput name="title" label="Titel des Angebots" placeholder="Bsp. Werden Sie unser starker Partner"/>
              <TextInput
                name="opening" label="Persönliche Anrede" placeholder="Bsp. Sehr geehrter Herr Schmidt"
              />
            </Row>
            <Row lgWidth="50%">
              {
                !selectLetter &&
                <ButtonBar align="left">
                  <Button text="Anschreiben auswählen" onClick={() => setSelectLetter(true)}/>
                </ButtonBar>
              }
              {
                selectLetter &&
                <SelectInput onChange={(value) => {
                  setFieldValue('letter', value.content);
                  setSelectLetter(false);
                }} className="mt-1-75" name="letterSelection" entity="letter" getOptionLabel={item => item.title}/>
              }
            </Row>
            <TextareaInput
              className="mt-1-75" name="letter" label="Anschreiben"
              placeholder={
                "Bsp. Vielen Dank, dass ich mich Ihrem Unternehmen vorstellen durfte. " +
                "Folgend möchte ich Ihnen unser Unternehmen kurz vorstellen und unser Angebot erläutern."
              }
            />
            <ButtonBar>
              <ButtonLink to="../allgemeines" text="Zurück" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left"/>
              <Button icon={<ChevronRightIcon/>} text="Weiter" type="submit"/>
            </ButtonBar>
          </Form>
        )}
      </Formik>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );
}
