import TitleHeading from "../components/TitleHeading";
import Breadcrumb from "../components/Breadcrumb";
import {NavLink} from "react-router-dom";
import Title from "../components/Title";
import Stage from "../components/Stage";
import ButtonLink from "../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../components/Icons";
import Button from "../components/Button";
import ButtonBar from "../components/ButtonBar";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../components/TextInput";
import FormErrors from "../components/FormErrors";
import ErrorModal from "../components/ErrorModal";
import {useState} from "react";
import Row from "../components/Row";
import Modal from "../components/Modal";
import {useNavigate} from "react-router-dom";
import useCRUD from "../hooks/useCRUD";
import useFiles from "../hooks/useFiles";

export default function CreateUspGroup() {

  const crud = useCRUD();
  const files = useFiles();
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [createdGroupID, setCreatedGroupID] = useState();

  const validationSchema = Yup.object({
    name: Yup.string().required('Bitte geben Sie einen Namen ein'),
  });

  const submit = (values, {setSubmitting}) => {
    let item = {...values};
    const save = () => {
      crud.data.create({entity: 'usp_group', item})
        .then((createResult) => {
          setShowSuccess(true);
          setCreatedGroupID(createResult.id);
        })
        .catch(errors => setErrors(errors))
        .finally(() => setSubmitting(false));
    };
    if (values.portrait) {
      files.upload(values.portrait)
        .then(result => {
          item.portrait_id = result.id;
          save();
        })
        .catch(errors => {
          setErrors(errors);
          setSubmitting(false);
        });
    } else {
      save();
    }
  };

  return (
    <main>
      <Title>
        <TitleHeading>Gruppe erstellen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/usp-gruppen">Unternehmensvorteile</NavLink>
          Neu
        </Breadcrumb>
      </Title>
      <Stage>
        <Formik
          initialValues={{name: '', headline: ''}} validationSchema={validationSchema} onSubmit={submit}
          onReset={() => setShowSuccess(false)}
        >
          {({isSubmitting, touched, errors}) => (
            <Form>
              <Row>
                <TextInput name="name" label="Name*" placeholder="Bsp. Widerrufsrecht"/>
              </Row>
              <Row>
                <TextInput
                  name="headline" label="Überschrift auf der späteren Angebotsseite" placeholder="Bsp. Für Sie erstellt"
                />
              </Row>
              <ButtonBar>
                <ButtonLink
                  to="/usp-gruppen" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left" text="Zurück"
                />
                <Button
                  icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                  text="Speichern" type="submit" disabled={isSubmitting}
                />
              </ButtonBar>
              <FormErrors touched={touched} errors={errors}/>
              <Modal
                title="Die Gruppe wurde erfolgreich erstellt" show={showSuccess}
                onDismiss={() => navigate('/usp-gruppen')}
              >
                <ButtonBar>
                  <ButtonLink color="gray" to={`/usp-gruppen/${createdGroupID}`} text="Jetzt Vorteile hinzufügen"/>
                  <ButtonLink to="/usp-gruppen" text="Zurück zur Übersicht"/>
                </ButtonBar>
              </Modal>
            </Form>
          )}
        </Formik>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
