import TitleHeading from "../components/TitleHeading";
import Title from "../components/Title";
import Stage from "../components/Stage";
import ButtonLink from "../components/ButtonLink";
import {DuplicateIcon, EditIcon, LoaderIcon, PlusCircleIcon, TrashIcon} from "../components/Icons";
import ButtonBar from "../components/ButtonBar";
import Column from "../components/Column";
import RowActionLink from "../components/RowActionLink";
import RowActionButton from "../components/RowActionButton";
import Table from "../components/Table";
import {NavLink} from "react-router-dom";
import {useState} from "react";
import useCRUD from "../hooks/useCRUD";
import ErrorModal from "../components/ErrorModal";
import Modal from "../components/Modal";
import Button from "../components/Button";

export default function LogoGalerien() {

  const crud = useCRUD();
  const [errors, setErrors] = useState([]);
  const [duplicating, setDuplicating] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);
  const [itemCount, setItemCount] = useState({});
  const [tableKey, setTableKey] = useState(1);

  const pendingReadItemCounts = [];
  const readItemCount = logoGallery => {
    if (!pendingReadItemCounts.includes(logoGallery.id)) {
      pendingReadItemCounts.push(logoGallery.id);
      crud.data.bulk.read({
        entity: 'logo_gallery_item',
        page_size: 0,
        filter: crud.filter.equals('logo_gallery_id', logoGallery.id)
      })
        .then(
          result => {
            itemCount[logoGallery.id] = result.total;
            setItemCount({...itemCount});
          }
        )
        .catch(errors => setErrors(errors))
        .finally(() => pendingReadItemCounts.filter(i => i !== logoGallery.id));
    }
  };

  const [duplicateRow, setDuplicateRow] = useState(null);
  const doDuplicate = () => {
    setDuplicating(true);
    crud.data.read({entity: 'logo_gallery', id: duplicateRow.id})
      .then(logoGallery => {
        crud.data.create({entity: 'logo_gallery', item: {headline: `${logoGallery.headline} (Kopie)`}})
          .then(async newGallery => {
            const logos = await crud.data.bulk.read({
              entity: 'logo_gallery_item',
              filter: crud.filter.equals('logo_gallery_id', logoGallery.id),
              page_size: 1000
            });
            if (logos.items.length > 0) {
              await crud.data.bulk.create({
                entity: 'logo_gallery_item',
                items: logos.items.map(logo => ({
                  logo_gallery_id: newGallery.id,
                  image_id: logo.image_id,
                  url: logo.url
                }))
              });
            }
          })
          .catch(errors => setErrors(errors))
          .finally(() => {
            setDuplicateRow(null);
            setTableKey(tableKey => tableKey + 1);
            setDuplicating(false);
          });
      })
      .catch(errors => setErrors(errors));
  };

  const doDelete = () => {
    crud.data.delete({'entity': 'logo_gallery', id: deleteRow.id})
      .then(() => {
        setDeleteRow(null);
        setTableKey(tableKey => tableKey + 1);
      })
      .catch(errors => setErrors(errors));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Logo-Galerien</TitleHeading>
      </Title>
      <Stage>
        <Table name="logo_gallery" entity="logo_gallery" defaultOrderBy="headline" key={tableKey}>
          <Column
            title="Überschrift" property="headline"
            transform={
              (headline, logoGallery) =>
                <NavLink to={`/logo-galerie/${logoGallery.id}/details`}>{headline}</NavLink>
            }
          />
          <Column title="Anzahl Einträge" values={itemCount} populate={readItemCount} type="number"/>
          <Column type="actions">
            <RowActionLink to={row => `/logo-galerie/${row.id}/details`} color="blue" title="Bearbeiten">
              <EditIcon/>
            </RowActionLink>
            <RowActionButton color="turquoise" title="Logo-Galerie duplizieren" onClick={setDuplicateRow}>
              <DuplicateIcon/>
            </RowActionButton>
            <RowActionButton color="red" title="Löschen" onClick={setDeleteRow}><TrashIcon/></RowActionButton>
          </Column>
        </Table>
        <ButtonBar>
          <ButtonLink to="/logo-galerie/neu" text="Hinzufügen" icon={<PlusCircleIcon/>}/>
        </ButtonBar>
      </Stage>
      {
        duplicateRow &&
        <Modal title="Duplizieren bestätigen" onDismiss={() => setDuplicateRow(null)}>
          <p>Möchten Sie die Logo-Galerie „{duplicateRow.headline}“ wirklich duplizieren?</p>
          <ButtonBar>
            <Button color="grey" text="Abbrechen" disabled={duplicating} onClick={() => setDuplicateRow(null)}/>
            <Button
              color="turquoise" text="Duplizieren" icon={duplicating ? <LoaderIcon/> : <DuplicateIcon/>}
              disabled={duplicating} onClick={doDuplicate}
            />
          </ButtonBar>
        </Modal>
      }
      {
        deleteRow &&
        <Modal title="Löschen bestätigen" onDismiss={() => setDeleteRow(null)}>
          <p>Möchten Sie die Logo-Galerie „{deleteRow.headline}“ wirklich löschen?</p>
          <ButtonBar>
            <Button text="Behalten" onClick={() => setDeleteRow(null)}/>
            <Button color="red" text="Löschen" icon={<TrashIcon/>} onClick={doDelete}/>
          </ButtonBar>
        </Modal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </main>
  );

}
