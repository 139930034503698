import './FormErrors.scss';

export default function FormErrors({errors, touched, remoteErrors = []}) {

  let messages = [];

  Object.entries(errors).forEach(([key, message]) => {
    if (!touched || touched[key]) {
      messages.push(message);
    }
  });

  remoteErrors.forEach(remoteError => messages.push(remoteError.message));

  return messages.length > 0 ? (
    <div className="FormErrors">
      <p>
        {messages.length === 1 && 'Bei der Verarbeitung des Formulars ist folgender Fehler aufgetreten:'}
        {messages.length !== 1 && 'Bei der Verarbeitung des Formulars sind folgende Fehler aufgetreten:'}
      </p>
      <ul>{messages.map((message, index) => <li key={index}>{message}</li>)}</ul>
    </div>
  ) : null;

}
