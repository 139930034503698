import Title from "../components/Title";
import TitleHeading from "../components/TitleHeading";
import Stage from "../components/Stage";
import Table from "../components/Table";
import RowActionButton from "../components/RowActionButton";
import {DuplicateIcon, EditIcon, LoaderIcon, PlusCircleIcon, TrashIcon} from "../components/Icons";
import Button from "../components/Button";
import Column from "../components/Column";
import {useEffect, useState} from "react";
import ErrorModal from "../components/ErrorModal";
import ButtonBar from "../components/ButtonBar";
import ButtonLink from "../components/ButtonLink";
import RowActionLink from "../components/RowActionLink";
import {NavLink} from "react-router-dom";
import Modal from "../components/Modal";
import useCRUD from "../hooks/useCRUD";
import TextTeaser from "../components/TextTeaser";

export default function Bewertungsgruppen() {

  const crud = useCRUD();
  const [errors, setErrors] = useState([]);

  const [testimonialCount, setTestimonialCount] = useState({});
  const [pendingReadTestimonialCounts, setPendingReadTestimonialCounts] = useState([]);
  const readTestimonialCount = (testimonial_group) => {
    if (!pendingReadTestimonialCounts.includes(testimonial_group.id)) {
      setPendingReadTestimonialCounts(x => {x.push(testimonial_group.id); return x;});
      crud.data.bulk.read({
        entity: 'testimonial',
        page_size: 0,
        filter: {property: 'testimonial_group_id', operator: 'equals', value: testimonial_group.id}
      })
        .then(
          result => {
            testimonialCount[testimonial_group.id] = result.total;
            setTestimonialCount({...testimonialCount});
          }
        )
        .catch(errors => setErrors(errors))
        .finally(() => setPendingReadTestimonialCounts(x => x.filter(i => i !== testimonial_group.id)));
    }
  };

  const [deleteRow, setDeleteRow] = useState(null);
  const doDelete = () => {
    crud.data.delete({'entity': 'testimonial_group', id: deleteRow.id})
      .then(() => {
        setDeleteRow(null);
        setTableKey(tableKey + 1);
      })
      .catch(errors => setErrors(errors));
  };

  const [duplicating, setDuplicating] = useState(false);
  const [duplicateRow, setDuplicateRow] = useState(null);
  const doDuplicate = () => {
    setDuplicating(true);
    crud.data.read({entity: 'testimonial_group', id: duplicateRow.id})
      .then(testimonialGroupResult => {
        const {id, revision, created_at, updated_at, deleted_at, ...newTestimonialGroup} = {
          ...testimonialGroupResult,
          name: `${testimonialGroupResult.name} (Kopie)`
        };
        crud.data.create({entity: 'testimonial_group', item: newTestimonialGroup})
          .then(async newTestimonialGroupResult => {
            const testimonials = await crud.data.bulk.read({
              entity: 'testimonial',
              filter: crud.filter.equals('testimonial_group_id', testimonialGroupResult.id),
              page_size: 1000
            }).then(bulkReadResult => bulkReadResult.items);
            if (testimonials.length > 0) {
              await crud.data.bulk.create({
                entity: 'testimonial',
                items: testimonials.map(testimonial => {
                  const {id, revision, created_at, updated_at, deleted_at, ...newTestimonialItem} = {
                    ...testimonial,
                    testimonial_group_id: newTestimonialGroupResult.id
                  };
                  return newTestimonialItem;
                })
              });
            }
          })
          .catch(errors => setErrors(errors))
          .finally(() => {
            setDuplicateRow(null);
            setTableKey(tableKey => tableKey + 1);
            setDuplicating(false);
          });
      })
      .catch(errors => setErrors(errors));
  };

  const [teaserWidth, setTeaserWidth] = useState();
  useEffect(() => {
    const handleResize = () => setTeaserWidth(window.innerWidth / 3);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [tableKey, setTableKey] = useState(1);
  const transformName = (name, testimonial_group) => <NavLink to={testimonial_group.id + '/details'}>{name}</NavLink>;
  const transformContent = content => <TextTeaser width={teaserWidth}>{content}</TextTeaser>;

  return (
    <main>
      <Title>
        <TitleHeading>Bewertungsgruppen</TitleHeading>
      </Title>
      <Stage>
        <Table name="testimonial_groups" entity="testimonial_group" defaultOrderBy="name" key={tableKey}>
          <Column title="Gruppe" property="name" transform={transformName}/>
          <Column title="Überschrift auf der späteren Angebotsseite" property="headline" transform={transformContent}/>
          <Column title="Anzahl Bewertungen" values={testimonialCount} populate={readTestimonialCount} type="number"/>
          <Column type="actions">
            <RowActionLink to={(row) => row.id + '/details'} color="blue" title="Bearbeiten">
              <EditIcon/>
            </RowActionLink>
            <RowActionButton color="turquoise" title="Duplizieren" onClick={setDuplicateRow}>
              <DuplicateIcon/>
            </RowActionButton>
            <RowActionButton color="red" title="Löschen" onClick={setDeleteRow}><TrashIcon/></RowActionButton>
          </Column>
        </Table>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        {
          duplicateRow &&
          <Modal title="Duplizieren bestätigen" onDismiss={() => setDuplicateRow(null)}>
            <p>Möchten Sie die Bewertungsgruppe „{duplicateRow.name}“ wirklich duplizieren?</p>
            <ButtonBar>
              <Button color="grey" text="Abbrechen" disabled={duplicating} onClick={() => setDuplicateRow(null)}/>
              <Button
                color="turquoise" text="Duplizieren" icon={duplicating ? <LoaderIcon/> : <DuplicateIcon/>}
                disabled={duplicating} onClick={doDuplicate}
              />
            </ButtonBar>
          </Modal>
        }
        {
          deleteRow &&
          <Modal onDismiss={() => setDeleteRow(null)}>
            <p>Möchten Sie die Bewertungsgruppe „{deleteRow.name}“ wirklich löschen?</p>
            <ButtonBar>
              <Button text="Behalten" onClick={() => setDeleteRow(null)}/>
              <Button color="red" text="Löschen" icon={<TrashIcon/>} onClick={doDelete}/>
            </ButtonBar>
          </Modal>
        }
        <ButtonBar>
          <ButtonLink to="neu" text="Hinzufügen" icon={<PlusCircleIcon/>}/>
        </ButtonBar>
      </Stage>
    </main>
  );

}
