import Title from '../../components/Title';
import TitleHeading from '../../components/TitleHeading';
import Stage from "../../components/Stage";
import ButtonBar from "../../components/ButtonBar";
import ButtonLink from "../../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import {useEffect, useState} from "react";
import ErrorModal from "../../components/ErrorModal";
import IconWithText from "../../components/IconWithText";
import {NavLink, useParams} from "react-router-dom";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import useCRUD from "../../hooks/useCRUD";
import * as Yup from "yup";
import FormErrors from "../../components/FormErrors";
import {Form, Formik} from "formik";
import TextInput from "../../components/TextInput";
import Breadcrumb from "../../components/Breadcrumb";
import Row from "../../components/Row";
import useTemplates from "../../hooks/useTemplates";

export default function Bearbeiten() {

  const crud = useCRUD();
  const params = useParams();
  const templates = useTemplates();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [name, setName] = useState('');
  const [content, setContent] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    setLoading(true);
    crud.data.read({entity: 'translation', id: parseInt(params.id)})
      .then(translation => {
        setName(translation.name);
        const content = Object.fromEntries(templates[0].texts.map(key => [key, '']));
        for (const [key, value] of Object.entries(JSON.parse(translation.content))) {
          if (key in content && typeof value === 'string') {
            content[key] = value;
          }
        }
        setContent({...content});
      })
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud.data, params.id, templates]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Bitte einen Namen eingeben')
  });

  const submit = (values, {setSubmitting}) => {
    crud.data.update({
      entity: 'translation',
      id: parseInt(params.id),
      update: {name: values.name, content: JSON.stringify(values.content)}
    })
      .then(() => setShowSuccess(true))
      .catch(errors => setErrors(errors))
      .finally(() => setSubmitting(false));
  };

  return (
    <main>
      <Title>
        <TitleHeading>Texte bearbeiten</TitleHeading>
        <Breadcrumb>
          <NavLink to="/texte">Texte</NavLink>
          Bearbeiten
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {
          !loading &&
            <Formik
              initialValues={{name, content}}
              validationSchema={validationSchema} onSubmit={submit} onReset={() => setShowSuccess(false)}
            >
              {({isSubmitting, touched, errors}) => (
                <Form>
                  <div className="translationDetails">
                    <Row>
                      <TextInput name='name' label="Name der Textänderungen"/>
                    </Row>
                    {
                      Object.entries(content).map(([key, value], index) => (
                        <Row key={index} style={{alignItems: 'center', rowGap: '5px'}}>
                          <label htmlFor={'content.[\'' + key + '\']'}>{key}</label>
                          <TextInput name={'content.[\'' + key + '\']'}/>
                        </Row>
                      ))
                    }
                  </div>
                  <FormErrors touched={touched} errors={errors}/>
                  <ButtonBar>
                    <ButtonLink
                      to="/texte" text="Zurück" color="gray" icon={<ChevronLeftIcon/>} iconPosition="left"
                    />
                    <Button
                      icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                      text="Speichern" type="submit" disabled={isSubmitting}
                    />
                  </ButtonBar>
                  <Modal title="Die Texte wurden erfolgreich gespeichert" show={showSuccess}
                    onDismiss={() => setShowSuccess(false)}
                  >
                    <ButtonBar>
                      <ButtonLink to="/texte" color="gray" text="Zurück zur Übersicht"/>
                      <Button onClick={() => setShowSuccess(false)} text="Ok"/>
                    </ButtonBar>
                  </Modal>
                </Form>
              )}
            </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
