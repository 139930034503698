import './RadioButton.scss';
import {Field} from "formik";

export default function RadioButton({text, name, value, onClick = () => {}, ...props}) {
  return (
    <label className={'RadioButton'} {...props} onClick={onClick}>
      <Field name={name} type="radio" value={value}/>
      <span>{text}</span>
    </label>
  );
}
