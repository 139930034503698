import TitleHeading from "../../components/TitleHeading";
import Breadcrumb from "../../components/Breadcrumb";
import {NavLink, useParams} from "react-router-dom";
import Title from "../../components/Title";
import Stage from "../../components/Stage";
import ButtonLink from "../../components/ButtonLink";
import {ChevronLeftIcon, ChevronRightIcon, LoaderIcon} from "../../components/Icons";
import Button from "../../components/Button";
import ButtonBar from "../../components/ButtonBar";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import TextInput from "../../components/TextInput";
import FormErrors from "../../components/FormErrors";
import ErrorModal from "../../components/ErrorModal";
import React, {useState} from "react";
import Modal from "../../components/Modal";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import IconWithText from "../../components/IconWithText";
import useCRUD from "../../hooks/useCRUD";
import DropzoneInput from "../../components/DropzoneInput";
import useFiles from "../../hooks/useFiles";
import Row from "../../components/Row";
import SelectInput from "../../components/SelectInput";

export default function Neu() {

  const crud = useCRUD();
  const files = useFiles();
  const params = useParams();
  const [pictureGallery, setPictureGallery] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [orderIndex, setOrderIndex] = useState(null);

  useEffect(() => {
    Promise.all([
      crud.data.read({entity: 'picture_gallery', id: parseInt(params.id)}),
      crud.data.bulk.read({
        entity: 'picture_gallery_item',
        page_size: 9999,
        filter: crud.filter.equals('picture_gallery_id', parseInt(params.id))
      })
    ])
      .then(([pictureGalleryResult, pictureGalleryItemResult]) => {
        setPictureGallery(pictureGalleryResult);
        setOrderIndex(
          pictureGalleryItemResult.items.map(usp => parseInt(usp.order ?? '-1')).reduce((a, b) => Math.max(a, b), - 1)
          + 1
        );
      })
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [crud, params.id]);

  const initialValues = {
    text: '',
    image: null,
    video: null
  };

  const validationSchema = Yup.object({
    image: Yup.mixed().nullable(),
    video: Yup.string().nullable()
  }).test(
    'image or video',
    'Bitte entweder ein Bild oder ein Video auswählen',
    function(value) {
      if (value.video !== null && value.image !== null) {
        return this.createError({
          path: 'video'
        });
      } else if (value.video === null && value.image === null) {
        return this.createError({
          path: 'image'
        });
      }
    });

  const submit = (values, {setSubmitting}) => {
    if (values.image) {
      files.upload(values.image)
        .then(file => {
          crud.data.create({
            entity: 'picture_gallery_item',
            item: {
              picture_gallery_id: pictureGallery.id,
              text: values.text,
              image_id: file.id,
              video_id: values.video,
              order: orderIndex
            }
          })
            .then(() => setShowSuccess(true))
            .catch(errors => setErrors(errors))
            .finally(() => setSubmitting(false));
        })
        .catch(errors => setErrors(errors) || setSubmitting(false));
    } else {
      crud.data.create({
        entity: 'picture_gallery_item',
        item: {
          picture_gallery_id: pictureGallery.id,
          text: values.text,
          video_id: values.video
        }
      })
        .then(() => setShowSuccess(true))
        .catch(errors => setErrors(errors))
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <main>
      <Title>
        <TitleHeading>Galerieeintrag erstellen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/galerien">Galerien</NavLink>
          {
            pictureGallery &&
            <NavLink to={`/galerie/${pictureGallery.id}/details`}>{pictureGallery.headline}</NavLink>
          }
          Neuer Eintrag
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {!loading &&
          <Formik
            initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}
            onReset={() => setShowSuccess(false)}
          >
            {({isSubmitting, touched, errors}) => (
              <Form>
                <TextInput name="text" label="Text" placeholder="Bsp. Unser Bürogebäude"/>
                <div className="mt-1">
                  <DropzoneInput
                    className="mt-1-75" name="image" label="Bild hochladen"
                    infoText="Nur PNG-, JPG-, GIF-, SVG- oder WEBP-Dateien"
                  />
                  <Row className="mt-1" lgWidth="50%">
                    <SelectInput name="video" entity="video"
                      getOptionLabel={item => item.name}
                      label="oder Video auswählen"/>
                  </Row>
                </div>
                <ButtonBar align="left">
                  <ButtonLink
                    to={`/galerie/${pictureGallery.id}/details`} color="gray" icon={<ChevronLeftIcon/>}
                    iconPosition="left" text="Zurück"
                  />
                  <Button
                    icon={isSubmitting ? <LoaderIcon/> : <ChevronRightIcon/>}
                    text="Speichern" type="submit" disabled={isSubmitting}
                  />
                </ButtonBar>
                <FormErrors touched={touched} errors={errors}/>
                <Modal
                  title="Der Galerieeintrag wurde erfolgreich erstellt" show={showSuccess}
                  onDismiss={() => navigate(`/galerie/${pictureGallery.id}/details`)}
                >
                  <ButtonBar>
                    <Button type="reset" color="gray" text="Weiteren Eintrag erstellen"/>
                    <ButtonLink to={`/galerie/${pictureGallery.id}/details`} text="Zurück zur Galerie"/>
                  </ButtonBar>
                </Modal>
              </Form>
            )}
          </Formik>
        }
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
      </Stage>
    </main>
  );

}
