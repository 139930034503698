import './Footer.scss';

export default function Footer() {
  return (
    <footer>
      <div>
        {(new Date()).getFullYear()} Copyrights ©
        <div>
          <a href="https://sellestar.de" target="_blank" rel="noreferrer">Sellestar</a>
          <a href="https://sellestar.de/impressum" target="_blank" rel="noreferrer">Impressum</a>
          <a href="https://sellestar.de/datenschutz" target="_blank" rel="noreferrer">Datenschutz</a>
        </div>
      </div>
    </footer>
  );
}
