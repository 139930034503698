import Title from '../components/Title';
import TitleHeading from '../components/TitleHeading';
import Column from "../components/Column";
import Stage from "../components/Stage";
import Table from "../components/Table";
import {EditIcon, PlusCircleIcon} from "../components/Icons";
import {useState} from "react";
import ErrorModal from "../components/ErrorModal";
import ButtonLink from "../components/ButtonLink";
import ButtonBar from "../components/ButtonBar";
import RowActionLink from "../components/RowActionLink";
import {NavLink} from "react-router-dom";
import useCRUD from "../hooks/useCRUD";

export default function ContactPersons() {

  const crud = useCRUD();
  const [errors, setErrors] = useState([]);
  const mailto_link = e_mail => e_mail ? <a href={'mailto:' + e_mail}>{e_mail}</a> : null;
  const tel_link = tel => tel ? <a href={'tel:' + tel.replace(/[^\d+]/g, '')}>{tel}</a> : null;
  const image = (url, contact_person) => (
    url ?
      <NavLink to={contact_person.id + '/bearbeiten'}>
        <img className="round" src={url}
          alt={`Portrait von ${contact_person.first_name} ${contact_person.last_name}`}/>
      </NavLink>
      : null
  );
  const transformFirstName = (first_name, contact_person) =>
    <NavLink to={contact_person.id + '/bearbeiten'}>{first_name}</NavLink>;
  const transformLastName = (last_name, contact_person) =>
    <NavLink to={contact_person.id + '/bearbeiten'}>{last_name}</NavLink>;

  const [imageUrls, setImageUrls] = useState({});
  const pendingImageUrls = [];
  const readImageUrls = (contact_person) => {
    if (!contact_person.portrait_id) {
      imageUrls[contact_person.id] = null;
    } else {
      if (!pendingImageUrls.includes(contact_person.id)) {
        pendingImageUrls.push(contact_person.id);
        crud.data.read({
          entity: 'file',
          id: contact_person.portrait_id
        })
          .then(
            result => {
              imageUrls[contact_person.id] = result.url;
              setImageUrls({...imageUrls});
            }
          )
          .catch(errors => setErrors(errors))
          .finally(() => pendingImageUrls.filter(i => i !== contact_person.id));
      }
    }
  };

  return (
    <main>
      <Title>
        <TitleHeading>Ansprechpartner</TitleHeading>
      </Title>
      <Stage>
        <Table name="contact_persons" entity="contact_person" defaultOrderBy="last_name">
          <Column title="Foto" type="image" values={imageUrls} populate={readImageUrls} transform={image}/>
          <Column title="Vorname" property="first_name" transform={transformFirstName}/>
          <Column title="Nachname" property="last_name" transform={transformLastName}/>
          <Column
            title="Position" property="position" transform={position => position?.split('\n').join(', ')}
            maxWidth="250px"
          />
          <Column title="E-Mail" property="e_mail" transform={mailto_link}/>
          <Column title="Telefon" property="phone" transform={tel_link}/>
          <Column title="Mobil" property="mobile" transform={tel_link}/>
          <Column type="actions">
            <RowActionLink to={row => row.id + '/bearbeiten'} color="blue" title="Bearbeiten">
              <EditIcon/>
            </RowActionLink>
          </Column>
        </Table>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        <ButtonBar>
          <ButtonLink to="neu" text="Hinzufügen" icon={<PlusCircleIcon/>}/>
        </ButtonBar>
      </Stage>
    </main>
  );

}
