import "./Image.scss";
import {EditIcon, TrashIcon} from "./Icons";
import Button from "./Button";
import {Link, NavLink, useParams} from "react-router-dom";

export default function Image(props) {

  const params = useParams();
  const media_type = media_type => {

    let result = media_type;
    const map = {
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'image/gif': 'gif',
      'image/svg+xml': 'svg',
    };
    if (media_type in map) {
      result = map[media_type];
    }
    return result;
  };
  const size = bytes => {

    if (parseInt(bytes) === 0) { return "0.00 B"; }
    let e = Math.floor(Math.log(parseFloat(bytes)) / Math.log(1024));
    return (bytes / Math.pow(1024, e)).toLocaleString('de-DE', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }) + ' ' + ' kMGTP'.charAt(e) + 'B';
  };

  return (
    <div className="Image">
      {
        props.to &&
        <Link to={props.to} className="basics">
          <img src={props.children.url} alt={props.name}/>
          <span>{props.name}</span>
        </Link>}
      {
        props.href &&
        <a href={props.href} className="basics" target="_blank" rel="noreferrer">
          <img src={props.href} alt={props.name}/>
          <span>{props.name}</span>
        </a>
      }
      {
        props.details &&
        <div className="details">
          <p>
            Anzeigename:
            <span className="name">
              {props.name}
              <NavLink to={'/titelbilder/' + params.id + '/bearbeiten'}><EditIcon/></NavLink>
            </span>
          </p>
          <p>Dateiname: {props.children.filename}</p>
          <p>Dateityp: {media_type(props.children.media_type)}</p>
          <p>Hochgeladen am: <span className="date">{
            (new Date(props.children.created_at))
              .toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})
          }</span></p>
          <p>Dateigröße: {size(props.children.size)}</p>
          {
            props.onDelete &&
            <p>
              <Button text="Löschen" icon={<TrashIcon/>} color="red" onClick={props.onDelete}/>
            </p>
          }
        </div>
      }
    </div>
  );
}

File.defaultProps = {
  details: false
};
