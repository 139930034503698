import Title from '../components/Title';
import TitleHeading from '../components/TitleHeading';
import Stage from "../components/Stage";
import Table from "../components/Table";
import RowActionButton from "../components/RowActionButton";
import {DuplicateIcon, EditIcon, LoaderIcon, PlusCircleIcon, TrashIcon} from "../components/Icons";
import Button from "../components/Button";
import Column from "../components/Column";
import {useEffect, useState} from "react";
import ErrorModal from "../components/ErrorModal";
import ButtonBar from "../components/ButtonBar";
import ButtonLink from "../components/ButtonLink";
import Modal from "../components/Modal";
import {NavLink} from "react-router-dom";
import RowActionLink from "../components/RowActionLink";
import useCRUD from "../hooks/useCRUD";
import TextTeaser from "../components/TextTeaser";

export default function Usp() {
  const crud = useCRUD();
  const [errors, setErrors] = useState([]);
  const [uspCount, setUspCount] = useState({});
  const [pendingReadUspCounts, setPendingReadUspCounts] = useState([]);
  const readUspCount = (usp_group) => {
    if (!pendingReadUspCounts.includes(usp_group.id)) {
      setPendingReadUspCounts(x => {x.push(usp_group.id); return x;});
      crud.data.bulk.read({
        entity: 'usp',
        page_size: 0,
        filter: {property: 'usp_group_id', operator: 'equals', value: usp_group.id}
      })
        .then(
          result => {
            uspCount[usp_group.id] = result.total;
            setUspCount({...uspCount});
          }
        )
        .catch(errors => setErrors(errors))
        .finally(() => setPendingReadUspCounts(x => x.filter(i => i !== usp_group.id)));
    }
  };

  const [duplicating, setDuplicating] = useState(false);
  const [duplicateRow, setDuplicateRow] = useState(null);
  const doDuplicate = () => {
    setDuplicating(true);
    crud.data.read({entity: 'usp_group', id: duplicateRow.id})
      .then(uspGroupResult => {
        const {id, revision, created_at, updated_at, deleted_at, ...newUspGroup} = {
          ...uspGroupResult,
          name: `${uspGroupResult.name} (Kopie)`
        };
        crud.data.create({entity: 'usp_group', item: newUspGroup})
          .then(async newUspGroupResult => {
            const uspItems = await crud.data.bulk.read({
              entity: 'usp',
              filter: crud.filter.equals('usp_group_id', uspGroupResult.id),
              page_size: 1000
            });
            if (uspItems.items.length > 0) {
              await crud.data.bulk.create({
                entity: 'usp',
                items: uspItems.items.map(uspItem => {
                  const {id, revision, created_at, updated_at, deleted_at, ...newUspItem} = {
                    ...uspItem,
                    usp_group_id: newUspGroupResult.id
                  };
                  return newUspItem;
                })
              });
            }
          })
          .catch(errors => setErrors(errors))
          .finally(() => {
            setDuplicateRow(null);
            setTableKey(tableKey => tableKey + 1);
            setDuplicating(false);
          });
      })
      .catch(errors => setErrors(errors));
  };

  const [deleteRow, setDeleteRow] = useState(null);
  const doDelete = () => {
    crud.data.delete({'entity': 'usp_group', id: deleteRow.id})
      .then(() => {
        setDeleteRow(null);
        setTableKey(tableKey + 1);
      })
      .catch(errors => setErrors(errors));
  };

  const [teaserWidth, setTeaserWidth] = useState();
  useEffect(() => {
    const handleResize = () => setTeaserWidth(window.innerWidth / 3);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [tableKey, setTableKey] = useState(1);
  const transformName = (name, usp_group) => <NavLink to={usp_group.id + '/'}>{name}</NavLink>;
  const transformContent = content => <TextTeaser width={teaserWidth}>{content}</TextTeaser>;

  return (
    <main>
      <Title>
        <TitleHeading>Unternehmensvorteile</TitleHeading>
      </Title>

      <Stage>
        <Table name="usp_groups" entity="usp_group" defaultOrderBy="name" key={tableKey}>
          <Column title="Gruppe" property="name" transform={transformName}/>
          <Column title="Überschrift auf der späteren Angebotsseite" property="headline" transform={transformContent}/>
          <Column title="Anzahl Vorteile" values={uspCount} populate={readUspCount} type="number"/>
          <Column type="actions">
            <RowActionLink to={(row) => row.id + '/'} color="blue" title="Bearbeiten">
              <EditIcon/>
            </RowActionLink>
            <RowActionButton color="turquoise" title="Duplizieren" onClick={setDuplicateRow}>
              <DuplicateIcon/>
            </RowActionButton>
            <RowActionButton color="red" title="Löschen" onClick={setDeleteRow}><TrashIcon/></RowActionButton>
          </Column>
        </Table>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        {
          duplicateRow &&
          <Modal title="Duplizieren bestätigen" onDismiss={() => setDuplicateRow(null)}>
            <p>Möchten Sie die Unternehmensvorteil Gruppe „{duplicateRow.name}“ wirklich duplizieren?</p>
            <ButtonBar>
              <Button color="grey" text="Abbrechen" disabled={duplicating} onClick={() => setDuplicateRow(null)}/>
              <Button
                color="turquoise" text="Duplizieren" icon={duplicating ? <LoaderIcon/> : <DuplicateIcon/>}
                disabled={duplicating} onClick={doDuplicate}
              />
            </ButtonBar>
          </Modal>
        }
        {
          deleteRow &&
          <Modal title="Löschen bestätigen" onDismiss={() => setDeleteRow(null)}>
            <p>Möchten Sie die Gruppe „{deleteRow.name}“ wirklich löschen?</p>
            <ButtonBar>
              <Button text="Behalten" onClick={() => setDeleteRow(null)}/>
              <Button color="red" text="Löschen" icon={<TrashIcon/>} onClick={doDelete}/>
            </ButtonBar>
          </Modal>
        }
        <ButtonBar>
          <ButtonLink to="/usp-gruppen/neu" text="Neue Gruppe anlegen" icon={<PlusCircleIcon/>}/>
        </ButtonBar>
      </Stage>
    </main>
  );

}
