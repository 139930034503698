import Title from "../../components/Title";
import TitleHeading from "../../components/TitleHeading";
import Stage from "../../components/Stage";
import Table from "../../components/Table";
import Column from "../../components/Column";
import ButtonBar from "../../components/ButtonBar";
import ButtonLink from "../../components/ButtonLink";
import {ChevronLeftIcon, EditIcon, LoaderIcon, PlusCircleIcon, TrashIcon} from "../../components/Icons";
import {useEffect, useMemo, useState} from "react";
import useCRUD from "../../hooks/useCRUD";
import ErrorModal from "../../components/ErrorModal";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import RowActionLink from "../../components/RowActionLink";
import RowActionButton from "../../components/RowActionButton";
import TextTeaser from "../../components/TextTeaser";
import {NavLink, useParams} from "react-router-dom";
import StageTitle from "../../components/StageTitle";
import IconWithText from "../../components/IconWithText";
import Breadcrumb from "../../components/Breadcrumb";

export default function BewertungsgruppenDetails () {

  const crud = useCRUD();
  const params = useParams();

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableKey, setTableKey] = useState(1);
  const [deleteTestimonial, setDeleteTestimonial] = useState(null);
  const [teaserWidth, setTeaserWidth] = useState();
  const [testimonialGroup, setTestimonialGroup] = useState(null);

  const doDelete = () => {
    crud.data.delete({'entity': 'testimonial', id: deleteTestimonial.id})
      .then(() => {
        setDeleteTestimonial(null);
        setTableKey(tableKey + 1);
      })
      .catch(errors => setErrors(errors));
  };

  useEffect(() => {
    setLoading(true);
    crud.data.read({entity: 'testimonial_group', id: params.id})
      .then(result => setTestimonialGroup(result))
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
    const handleResize = () => setTeaserWidth(window.innerWidth / 4);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [crud, params.id]);

  const transformContent = content => <TextTeaser width={teaserWidth}>{content}</TextTeaser>;

  return (
    <main>
      <Title>
        <TitleHeading>Bewertungen</TitleHeading>
        <Breadcrumb>
          <NavLink to="/bewertungsgruppen">Bewertungsgruppen</NavLink>
          {testimonialGroup && testimonialGroup.name}
        </Breadcrumb>
      </Title>
      <Stage>
        {loading && <IconWithText><LoaderIcon/> Laden…</IconWithText>}
        {
          !loading &&
          <>
            <StageTitle>
              {testimonialGroup?.name}
              <NavLink to={'/bewertungsgruppen/' + testimonialGroup?.id + '/bearbeiten'}><EditIcon/></NavLink>
            </StageTitle>
            <StageTitle className="subtitle disabled">
              {testimonialGroup?.headline ? testimonialGroup?.headline : "Keine Überschrift"}
              <NavLink to={'/bewertungsgruppen/' + testimonialGroup?.id + '/bearbeiten'}><EditIcon/></NavLink>
            </StageTitle>
          </>
        }
        <Table
          name="testimonial" entity="testimonial" defaultOrderBy="name" key={tableKey} draggable
          filter={useMemo(() => crud.filter.equals('testimonial_group_id', params.id), [crud.filter, params.id])}
        >
          <Column title="Name" property="name"/>
          <Column title="Titel" property="title"/>
          <Column title="Bewertung" property="content" transform={transformContent}/>
          <Column type="actions">
            <RowActionLink
              to={(row) => '/bewertungsgruppen/' + testimonialGroup?.id + '/' + row.id + '/bearbeiten'}
              color="blue" title="Bearbeiten"
            >
              <EditIcon/>
            </RowActionLink>
            <RowActionButton color="red" title="Löschen" onClick={setDeleteTestimonial}><TrashIcon/></RowActionButton>
          </Column>
        </Table>
        <ButtonBar>
          <ButtonLink to="/bewertungsgruppen" text="Zurück" icon={<ChevronLeftIcon/>} color="gray" iconPosition="left"/>
          <ButtonLink to={`/bewertungsgruppen/${params.id}/neu`} text="Hinzufügen" icon={<PlusCircleIcon/>}/>
        </ButtonBar>
        <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        {
          deleteTestimonial &&
          <Modal title="Löschen bestätigen" onDismiss={() => setDeleteTestimonial(null)}>
            <p>Möchten Sie die Bewertung von „{deleteTestimonial.name}“ wirklich löschen?</p>
            <ButtonBar>
              <Button text="Behalten" onClick={() => setDeleteTestimonial(null)}/>
              <Button color="red" text="Löschen" icon={<TrashIcon/>} onClick={doDelete}/>
            </ButtonBar>
          </Modal>
        }
      </Stage>
    </main>
  );

}
