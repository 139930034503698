import './EventWidget.scss';
import Widget from "./Widget";
import {useEffect, useState} from "react";
import useCRUD from "../hooks/useCRUD";
import {LoaderIcon} from "./Icons";

export default function EventWidget({color, text, offerId, type, target, order}) {

  const crud = useCRUD();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [event, setEvent] = useState();

  useEffect(() => {
    setLoading(true);
    let filter = {group: 'and', components: [{property: 'offer_id', operator: 'equals', value: offerId}]};
    if (type) {
      filter.components.push({property: 'type', operator: 'equals', value: type});
    }
    if (target) {
      filter.components.push({property: 'target', operator: 'equals', value: target});
    }
    crud.data.bulk.read({entity: 'event', page_size: 1, filter, order_by: 'created_at', order})
      .then(result => {
        if (result.items.length > 0) {
          setEvent(result.items[0]);
        }
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [crud, offerId, order, target, type]);

  return (
    <Widget className="EventWidget">
      {loading && <p className={'date ' + color}><LoaderIcon/></p>}
      {!loading && error && <p className={'date ' + color}>?</p>}
      {!loading && !error && !event && <p className={'date ' + color}>–</p>}
      {!loading && !error && event &&
        <>
          <p className={'date ' + color}>
            {new Date(event.created_at).toLocaleString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'})}
          </p>
          <p className={'time ' + color}>
            {new Date(event.created_at).toLocaleString('de-DE', {hour: '2-digit', minute: '2-digit'})} Uhr
          </p>
        </>
      }
      <p className="text" title={text}>{text}</p>
    </Widget>
  );

}
