import './ColorInput.scss';
import {useField, useFormikContext} from "formik";
import {useEffect, useState} from "react";

export default function ColorInput({id, name, label}) {

  const [field] = useField(name);
  const {setFieldValue} = useFormikContext();
  const [textValue, setTextValue] = useState(field.value);

  useEffect(() => {
    setTextValue(field.value);
  }, [field.value]);

  const transferTextValue = textValue => {
    textValue = textValue.toLowerCase();
    if (textValue.match(/^#[a-f\d]{6}$/)) {
      setFieldValue(name, textValue);
    }
    setTextValue(textValue);
  };

  return (
    <div className="ColorInput">
      {label && <label htmlFor={id || name}>{label}</label>}
      <div className="input-wrapper">
        <input type="text" value={textValue} onChange={event => transferTextValue(event.target.value)}/>
        <input type="color" id={id || name} name={name} {...field}/>
      </div>
    </div>
  );

}
