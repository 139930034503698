import './create.scss';

import {Navigate, Route, Routes} from "react-router-dom";
import Setup from "./record/setup";
import Record from "./record/record";
import Preview from "./record/preview";
import StandaloneModal from "../../components/StandaloneModal";
import {useContext, useState} from "react";
import {RecordingContext} from "../../contexts/RecordingContext";

export default function Create() {

  const [settings, setSettings] = useState({
    video: {width: 1280, height: 720},
    audio: true,
    screencast: false
  });

  const [videoBlob, setVideoBlob] = useState();

  const recordingContext = useContext(RecordingContext);

  return (
    <div className="VideoRecording">
      <StandaloneModal onDismiss={recordingContext.navigateBack}>
        <div className="videoRecordingContainer">
          <Routes>
            <Route path="" element={<Navigate to="konfiguration" replace={true}/>}/>
            <Route path="konfiguration" element={<Setup setSettings={setSettings}/>}/>
            <Route path="konfiguration/:preset" element={<Setup setSettings={setSettings}/>}/>
            <Route path="neu" element={<Record setVideoBlob={setVideoBlob} settings={settings}/>}/>
            <Route path="vorschau" element={<Preview videoBlob={videoBlob} setVideoBlob={setVideoBlob}/>}/>
            <Route path="*" element={<Navigate to="konfiguration" replace={true}/>}/>
          </Routes>
        </div>
      </StandaloneModal>
    </div>
  );
}
